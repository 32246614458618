import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';

/**
 * 操作選択モーダル
 * モーダル内にラジオボタンを用意し、ユーザーに選択を促す
 */
class ModalSoundTest extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} props.startTestPlay - フロアテスト再生開始
     * @param {function} props.stopTestPlay - フロアテスト再生終了
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} フロア名設定モーダルの表示有無
             */
            modal: false,

            /**
             * @type {boolean} テストサウンド再生
             */
            testSound: false,
        };

        this.parser = require('html-react-parser');
    }

    /**
     * フロア名設定モーダル表示有無の設定
     * @param {boolean} flag -フロア名設定モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    setTestSound = (flag) => {
        this.setState({ testSound: flag });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        this.setTestSound(false);
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = async () => {
        if (this.state.testSound) {
            await this.props.stopTestPlay();
        }
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = async () => {
        this.setTestSound(true);

        await this.props.startTestPlay();
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const { testSound } = this.state;
        return (
            <>
                <Modal
                    className={`modal_sound_test ${
                        testSound ? 'sound_testing' : ''
                    }`}
                    isOpen={this.state.modal}
                    centered
                    backdrop="static"
                >
                    {/* モーダルヘッダー タイトル表示欄 */}
                    <ModalHeader toggle={this.close}>
                        {DISP_RESOURCE.SOUND_TEST_TITLE}
                    </ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        {!testSound && (
                            <Label for="modal_sound_test_content">
                                {this.parser(DISP_RESOURCE.SOUND_TEST_CONTENT1)}
                            </Label>
                        )}
                        {testSound && (
                            <Label for="modal_sound_test_content">
                                <img
                                    alt="testSound"
                                    src={
                                        require('../../../img/music_note.svg')
                                            .default
                                    }
                                    width="100"
                                />
                                {this.parser(DISP_RESOURCE.SOUND_TEST_CONTENT2)}
                            </Label>
                        )}
                    </ModalBody>
                    {/* モーダルフッター */}
                    {!testSound && (
                        <ModalFooter className="with_cancel">
                            <Button
                                outline
                                color="success"
                                onClick={this.close}
                            >
                                {DISP_RESOURCE.CANCEL}
                            </Button>
                            <Button
                                color="success"
                                onClick={this.onClickOkHandler}
                            >
                                {DISP_RESOURCE.OK}
                            </Button>
                        </ModalFooter>
                    )}
                    {testSound && (
                        <ModalFooter className="without_cancel">
                            <Button
                                outline
                                color="success"
                                onClick={this.close}
                            >
                                {DISP_RESOURCE.STOP}
                            </Button>
                        </ModalFooter>
                    )}
                </Modal>
            </>
        );
    }
}

export default ModalSoundTest;
