import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Table,
    Input,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import { getSpeakerSoundSources } from '../../../api/userApi';
import {
    CREATE_LOG_IDENTIFIER,
    EXTERNAL_FILE_CONTENTS as EX_FILE,
    MODAL,
    OFFICE_USER_TYPE,
} from '../../../common/constants';
import { RESPONSE_STATUS } from '../../../api/apiContants';

/**
 * 音源選択モーダル
 */
class ModalSelectSoundSource extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} props.okProc - OKボタン押下時の処理
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} 音源選択モーダル表示有無
             */
            modal: false,
            /**
             * @type {number} 選択中の音源
             */
            soundSource: 0,
            /**
             * @type {Array} 音源リスト
             */
            soundSourceList: [],
        };
    }

    /**
     * 音源選択モーダル表示有無の設定
     * @param {boolean} flag -音源選択モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * 選択した音源を設定
     * @param {number} soundSource - 選択した音源
     */
    setSoundSource = (soundSource) => {
        this.setState({ soundSource: Number(soundSource) });
    };

    /**
     * モーダル初期化
     * @param {number} soundSource -停止時間
     */
    init = async (soundSource) => {
        this.setState({ soundSource: soundSource });

        const response = await getSpeakerSoundSources(
            this.props.speakerId,
            OFFICE_USER_TYPE.USER
        );

        if (
            response === null ||
            !response.data ||
            response.status === RESPONSE_STATUS.INTERNAL_SERVER_ERROR
        ) {
            this.props.showFailedSoundSource();
            this.props.setLoading(false);
            return;
        } else if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
            this.props.modalOpen(MODAL.SESSION_EXPIRED);
            this.props.setLoading(false);
            return;
        }

        const soundSourceList = [...response.data.soundSources];

        // テスト音源と音源が設定されていないパターンを除外したリストを作成
        let displaySoundSourceList = [];
        for (let s of soundSourceList) {
            if (s.soundSourceNo === EX_FILE.TEST_SOUND_SOURCE_ID || s.soundSource === '') continue;
            displaySoundSourceList.push(s);
        }

        this.setState({ soundSourceList: displaySoundSourceList });

        this.setModal(true);
        this.props.setLoading(false);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * 設定ボタン押下時処理
     */
    onClickSettingHandler = () => {
        const soundSorceName = this.state.soundSourceList.filter(
            (v) => v.soundSourceNo === this.state.soundSource
        )[0].soundSourceName;

        this.close();
        this.props.okProc(this.state.soundSource, soundSorceName);

        this.props.createLog(
            CREATE_LOG_IDENTIFIER.SOUND_SOURCE_SETTING,
            true,
            soundSorceName
        );
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        return (
            <div className="div_modal_commons">
                <Modal
                    className="modal_commons modal_select_sound_source"
                    isOpen={this.state.modal}
                    centered
                >
                    {/* モーダルヘッダー タイトル表示欄*/}
                    <ModalHeader>
                        {DISP_RESOURCE.SELECT_SOUND_SOURCE_TITLE}
                    </ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Table className="mt-3">
                            <tbody>
                                {this.state.soundSourceList.map(
                                    (item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <Label
                                                        for={
                                                            'soundSourceRadio' +
                                                            index.toString()
                                                        }
                                                    >
                                                        {item.soundSourceName}
                                                    </Label>
                                                </td>
                                                <td>
                                                    <Input
                                                        id={
                                                            'soundSourceRadio' +
                                                            index.toString()
                                                        }
                                                        type="radio"
                                                        name="soundSourceRadio"
                                                        value={
                                                            item.soundSourceNo
                                                        }
                                                        onClick={(e) => {
                                                            this.setSoundSource(
                                                                e.target.value
                                                            );
                                                        }}
                                                        defaultChecked={
                                                            item.soundSourceNo ===
                                                            this.state
                                                                .soundSource
                                                        }
                                                    ></Input>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </Table>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="with_cancel">
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button
                            color="success"
                            onClick={this.onClickSettingHandler}
                        >
                            {DISP_RESOURCE.SETTING}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalSelectSoundSource;
