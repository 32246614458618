import React, { createRef } from 'react';
import { Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import logo from '../img/logo.svg';
import {
    MODE,
    MODAL,
    STOP_TIME,
    CREATE_LOG_IDENTIFIER,
    OFFICE_USER_TYPE,
} from '../common/constants';

import SpeakerOperation from './user/SpeakerOperation';
import ModalSessionExpired from './user/modal/ModalSessionExpired';
import ModalSelectStopTime from './user/modal/ModalSelectStopTime';
import ModalSelectSoundSource from './user/modal/ModalSelectSoundSource';
import ModalPlayFailure from './user/modal/ModalPlayFailure';
import ModalSurveyConfirm from './user/modal/ModalSurveyConfirm';
import Survey from './user/Survey';
import Loading from './user/Loading';
import {
    postMuteSpeaker,
    getSurveyInfo,
    postChangeVolumeSpeaker,
    postPlaySpeaker,
} from '../api/userApi';
import { postCreateUserLog } from '../api/logApi';
import DISP_RESOURCE from '../common/dispResource';
import ModalSoundSourceFailure from './user/modal/ModalSoundSourceFailure';
import { RESPONSE_STATUS } from '../api/apiContants';

/**
 * オフィス利用者画面
 */
class User extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * 画面の状態
             * @type {string}
             */
            mode: MODE.SPEAKER_OPERATION,
            /**
             * 停止時間
             * @type {number}
             */
            stopTime: STOP_TIME.DEFAULT,
            /**
             * 音源
             * @type {number}
             */
            soundSource: 0,
            /**
             * 音源名称
             * @type {string}
             */
            soundSourceName: '',
            /**
             * 音量
             * @type {number}
             */
            volume: 0,
            /**
             * アンケートモーダル表示数
             * @type {number}
             */
            count: 0,
            /**
             * テスト再生中の状態
             * @type {boolean}
             */
            testPlay: false,
            /**
             * スピーカーID
             * @type {number}
             */
            speakerId: 0,
            /**
             * 拠点ID
             * @type {number}
             */
            baseId: 0,
        };

        // モーダル参照
        this[MODAL.SESSION_EXPIRED] = createRef();
        this[MODAL.SELECT_STOP_TIME] = createRef();
        this[MODAL.SELECT_SOUND_SOURCE] = createRef();
        this[MODAL.USER_CANNOT_PLAY] = createRef();
        this[MODAL.USER_FAILED_PLAY] = createRef();
        this[MODAL.SURVEY_CONFIRM] = createRef();
        this[MODAL.FAILED_GET_SOUND_SOURCE] = createRef();
        this[MODAL.SYSTEM_ERROR] = createRef();

        this[MODE.LOADING] = createRef();
    }

    /**
     * スピーカーID、拠点IDを設定
     * @param {*} params URLパラメータ
     */
    setParam = (params) => {
        this.setState({ speakerId: params.speakerId, baseId: params.baseId });
    };

    /**
     * モーダル表示
     * @param {string} modalName - モーダルに紐づけた名前(refで指定)
     */
    modalOpen = (modalName) => {
        if (modalName === MODAL.SELECT_STOP_TIME) {
            // 停止時間選択モーダル
            this[modalName].current.init(this.state.stopTime);
        } else if (modalName === MODAL.SELECT_SOUND_SOURCE) {
            // 音源選択モーダル
            this[modalName].current.init(this.state.soundSource);
        } else {
            this[modalName].current.init();
        }
    };

    /**
     * 画面状態切り替え
     * @param {string} mode
     */
    setMode = (mode) => {
        this.setState({ mode: mode });
    };

    /**
     * 音声再生
     * @param {number} soundSourceNo 再生する音源
     */
    audioPlay = async (soundSourceNo) => {
        this[MODE.LOADING].current.show();

        const response = await postPlaySpeaker(
            this.state.speakerId,
            soundSourceNo,
            OFFICE_USER_TYPE.USER
        );

        this[MODE.LOADING].current.close();

        const status = response.status;
        if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
            this[MODAL.SESSION_EXPIRED].current.init();
            return;
        }

        const result = status === 200 ? true : false;
        this.createLog(CREATE_LOG_IDENTIFIER.PLAY_SPEAKER, result, '-');

        if (response.status === RESPONSE_STATUS.OK && !response.data.result) {
            this.modalOpen(MODAL.USER_CANNOT_PLAY);
            return;
        } else if (response.status !== RESPONSE_STATUS.OK) {
            this.modalOpen(MODAL.USER_FAILED_PLAY);
            return;
        }
        this.surveyShouldShow();
    };

    /**
     * 音声停止
     * @param {number} stopTime 停止時間
     */
    audioStop = async (stopTime) => {
        this[MODE.LOADING].current.show();

        const response = await postMuteSpeaker(
            this.state.speakerId,
            stopTime,
            OFFICE_USER_TYPE.USER
        );

        this.setState({ stopTime: stopTime });
        this[MODE.LOADING].current.close();

        const status = response.status;
        if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
            this[MODAL.SESSION_EXPIRED].current.init();
            return;
        }

        const result = status === 200 ? true : false;
        this.createLog(CREATE_LOG_IDENTIFIER.STOP_SPEAKER, result, '-');
        if (!result) {
            this[MODAL.SYSTEM_ERROR].current.init();
            return;
        }

        this.surveyShouldShow();
    };

    /**
     * 音量設定
     * @param {number} volume 音量
     */
    soundSetting = async (volume) => {
        this[MODE.LOADING].current.show();

        const response = await postChangeVolumeSpeaker(
            this.state.speakerId,
            volume,
            OFFICE_USER_TYPE.USER
        );
        this.setState({ volume: volume });
        this[MODE.LOADING].current.close();

        const result = response.status === 200 ? true : false;
        this.createLog(CREATE_LOG_IDENTIFIER.SOUND_SETTING, result, volume);

        if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
            this.modalOpen(MODAL.SESSION_EXPIRED);
        }
    };

    /**
     * 音源設定
     * @param {number} soundSource 音源No
     * @param {string} soundSourceName 音源名
     */
    setSoundSource = (soundSource, soundSourceName) => {
        this.setState({ soundSource: soundSource });
        this.setState({ soundSourceName: soundSourceName });
    };

    /**
     * アンケート表示
     */
    surveyShouldShow = async () => {
        if (this.state.count === 0) {
            const response = await getSurveyInfo(
                this.state.baseId,
                this.state.speakerId
            );
            if (response && response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                this.modalOpen(MODAL.SESSION_EXPIRED);
            } else if (response && response.data.url) {
                this.modalOpen(MODAL.SURVEY_CONFIRM);
                this.setState({ count: 1 });
            }
        }
    };

    /**
     * ログ登録
     * @param {string} identifier ログ識別子
     * @param {boolean} result 結果
     * @param {string} data 追加データ
     */
    createLog = async (identifier, result, data) => {
        const body = {
            baseId: this.state.baseId,
            speakerId: this.state.speakerId,
            identifier: identifier,
            result: result,
            data: data,
        };

        await postCreateUserLog(body);
    };

    setLoading = (isLoading) => {
        if (isLoading) {
            this[MODE.LOADING].current.show();
        } else {
            this[MODE.LOADING].current.close();
        }
    };

    showFailedSoundSource = () => {
        this[MODAL.FAILED_GET_SOUND_SOURCE].current.init();
    };

    /**
     * 画面描画
     * @returns
     */
    render() {
        // 進む・戻るボタン検知時、スピーカー操作画面を表示する
        window.addEventListener('popstate', () => {
            if (this.state.mode === MODE.USER_SURVEY) {
                this.setMode(MODE.SPEAKER_OPERATION);
            }
        });

        // 回転したときに初期位置に戻すため、モバイル限定でBody要素に縦スクロールを付ける
        if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
            const body = document.getElementsByTagName('body');
            for (let b of body) {
                b.style.overflowY = 'scroll';
            }
        }

        return (
            <Container className="contents_user">
                <Navbar className="navbar-user">
                    <NavbarBrand>
                        <Row>
                            <img alt="logo" src={logo} />
                        </Row>
                    </NavbarBrand>
                </Navbar>
                {/* スピーカー操作画面 */}
                {this.state.mode === MODE.SPEAKER_OPERATION && (
                    <SpeakerOperation
                        modalOpen={this.modalOpen}
                        audioPlay={this.audioPlay}
                        soundSetting={this.soundSetting}
                        soundSource={this.setSoundSource}
                        soundSourceNo={this.state.soundSource}
                        soundSourceName={this.state.soundSourceName}
                        nowPlay={this.state.nowPlay}
                        setParam={this.setParam}
                    />
                )}
                {/* セッション有効期限切れアラートモーダル */}
                <ModalSessionExpired
                    ref={this[MODAL.SESSION_EXPIRED]}
                    setMode={this.setMode}
                ></ModalSessionExpired>
                {/* 停止時間選択モーダル */}
                <ModalSelectStopTime
                    ref={this[MODAL.SELECT_STOP_TIME]}
                    okProc={this.audioStop}
                ></ModalSelectStopTime>
                {/* 音源選択モーダル */}
                <ModalSelectSoundSource
                    ref={this[MODAL.SELECT_SOUND_SOURCE]}
                    okProc={this.setSoundSource}
                    modalOpen={this.modalOpen}
                    speakerId={this.state.speakerId}
                    setLoading={this.setLoading}
                    createLog={this.createLog}
                    showFailedSoundSource={this.showFailedSoundSource}
                ></ModalSelectSoundSource>
                {/* 音源再生不可アラートモーダル */}
                <ModalPlayFailure
                    ref={this[MODAL.USER_CANNOT_PLAY]}
                    setMode={this.setMode}
                    content={DISP_RESOURCE.USER_CANNOT_PLAY}
                ></ModalPlayFailure>
                {/* 音源再生失敗アラートモーダル */}
                <ModalPlayFailure
                    ref={this[MODAL.USER_FAILED_PLAY]}
                    setMode={this.setMode}
                    content={DISP_RESOURCE.USER_FAILED_PLAY}
                ></ModalPlayFailure>
                <ModalSoundSourceFailure
                    ref={this[MODAL.FAILED_GET_SOUND_SOURCE]}
                    setMode={this.setMode}
                    content={DISP_RESOURCE.USER_FAILED_GET_SOUND_SOURCES}
                ></ModalSoundSourceFailure>
                {/*システムラーアラートモーダル */}
                <ModalPlayFailure
                    ref={this[MODAL.SYSTEM_ERROR]}
                    setMode={this.setMode}
                    content={DISP_RESOURCE.EXCEPTION_OCCURED}
                ></ModalPlayFailure>
                {/* アンケート回答確認モーダル */}
                <ModalSurveyConfirm
                    ref={this[MODAL.SURVEY_CONFIRM]}
                    setMode={this.setMode}
                ></ModalSurveyConfirm>
                {/* アンケート回答画面 */}
                {this.state.mode === MODE.USER_SURVEY && (
                    <Survey
                        setMode={this.setMode}
                        speakerId={this.state.speakerId}
                        baseId={this.state.baseId}
                        createLog={this.createLog}
                        modalOpen={this.modalOpen}
                    />
                )}
                {/* ローディング画面 */}
                <Loading ref={this[MODE.LOADING]} />
            </Container>
        );
    }
}

export default User;
