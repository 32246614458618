import React from 'react';
import moment from 'moment';
import parser from 'html-react-parser';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter,
    Label,
    FormGroup,
} from 'reactstrap';
import { CHOICE_MODAL_OPT } from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';

/**
 * 操作選択モーダル
 * モーダル内にラジオボタンを用意し、ユーザーに選択を促す
 */
class ModalChoice extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {object[]} props.speakers - スピーカーリスト
     * @param {object[]} props.schedules - スケジュールリスト
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} フロア名設定モーダルの表示有無
             */
            modal: false,

            /**
             * @type {number} 表示中のスケジュール繰返しID
             */
            scheduleRepeatId: null,

            /**
             * @type {number} 選択中のラジオボタン
             */
            choice: CHOICE_MODAL_OPT.FIRST,

            /**
             * @type {boolean} 以降全件適用フラグ
             */
            applyAll: false,

            /**
             * @type {string} コンテンツ
             */
            content: '',
        };
    }

    /**
     * フロア名設定モーダル表示有無の設定
     * @param {boolean} flag -フロア名設定モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * 選択したラジオボタンを設定
     * @param {number} choice - 選択したラジオボタン
     */
    setChoice = (choice) => {
        this.setState({ choice: choice });
    };

    /**
     * モーダル初期化
     * @param {object} duplicateSchedule 重複スケジュール
     */
    init = (duplicateSchedule) => {
        const { speakers, schedules } = this.props;
        const { speakerId, scheduleRepeatId } = duplicateSchedule;

        // スピーカー取得
        const speaker = speakers.find((spk) => spk.id === speakerId);

        // スケジュール取得
        const schedule = schedules.find(
            (sch) => sch.scheduleRepeatId === scheduleRepeatId
        );

        // スケジュールの時間
        const time = moment(
            schedule.periodStartDate + ' ' + schedule.periodStartTime
        );

        // 重複スケジュールモーダル表示文言作成
        const content = DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_CONTENT.format(
            speaker.name,
            time.format('YYYY/MM/DD HH:mm')
        );

        // 表示データセット
        this.setState({
            content: content,
            choice: CHOICE_MODAL_OPT.FIRST,
            scheduleRepeatId: scheduleRepeatId,
            applyAll: false,
        });

        // 重複スケジュールモーダル表示
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = () => {
        const { scheduleRepeatId, choice, applyAll } = this.state;
        const result = { scheduleRepeatId, choice, applyAll };

        this.props.okProc(result);

        // モーダル閉じる
        this.setModal(false);
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const { content, choice, applyAll } = this.state;
        return (
            <>
                <Modal
                    className="modal_scheudle_dulplicate_choice"
                    isOpen={this.state.modal}
                    centered
                    backdrop="static"
                >
                    {/* モーダルヘッダー タイトル表示欄 */}
                    <ModalHeader toggle={this.close}>
                        {DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_TITLE}
                    </ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Label for="modal_scheudle_dulplicate_choice_content">
                            {parser(content)}
                        </Label>
                        <div className="modal_duplicate_choice_form">
                            {/* ラジオボタン */}
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.FIRST}
                                        onChange={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                        checked={
                                            choice === CHOICE_MODAL_OPT.FIRST
                                        }
                                    />
                                    {
                                        DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_FIRST_OPT
                                    }
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.SECOND}
                                        onChange={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                        checked={
                                            choice === CHOICE_MODAL_OPT.SECOND
                                        }
                                    />
                                    {
                                        DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_SECOND_OPT
                                    }
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.THIRD}
                                        onChange={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                        checked={
                                            choice === CHOICE_MODAL_OPT.THIRD
                                        }
                                    />
                                    {
                                        DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_THIRD_OPT
                                    }
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.FOURTH}
                                        onChange={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                        checked={
                                            choice === CHOICE_MODAL_OPT.FOURTH
                                        }
                                    />
                                    {
                                        DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_FOURTH_OPT
                                    }
                                </Label>
                            </FormGroup>
                            <br />
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onChange={() =>
                                            this.setState({
                                                applyAll: !applyAll,
                                            })
                                        }
                                        checked={applyAll}
                                    />
                                    {DISP_RESOURCE.CHOICE_DUPLICATE_TYPE_CHECK}
                                </Label>
                            </FormGroup>
                        </div>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="without_cancel">
                        <Button color="success" onClick={this.onClickOkHandler}>
                            {DISP_RESOURCE.OK}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ModalChoice;
