// API定義
export const API_DOMAIN = process.env.REACT_APP_DOMAIN;

// API_URL一覧
export const API_URL = {
    // バージョン情報取得
    GET_VERSION: '/version',
    // ユーザー情報取得
    GET_USER: '/user',
    // フロア情報一覧取得
    GET_FLOOR_LIST: '/floor/list',
    // フロア情報呼び出し
    LOAD_FLOOR: '/floor/load',
    // フロア情報保存
    SAVE_FLOOR: '/floor/save',
    // フロア情報削除
    DELETE_FLOOR: '/floor/delete',
    // フロアモード変更
    CHANGE_FLOOR_MODE: '/floor/mode/change',
    // フロアテスト再生開始
    START_TEST_PLAY_FLOOR: '/floor/test-play/start',
    // フロアテスト再生停止
    STOP_TEST_PLAY_FLOOR: '/floor/test-play/stop',
    // スピーカー情報取得
    GET_SPEAKER: '/speaker',
    // スピーカー疎通確認
    CHECK_SPEAKER_CONNECTION: '/speaker/connect',
    // スピーカー再生(テスト音源再生含む)
    PLAY_SPEAKER: '/speaker/play',
    // スピーカー停止(テスト音源停止含む)
    STOP_SPEAKER: '/speaker/stop',
    // スピーカー音量変更
    CHANGE_SPEAKER_VOLUME: '/speaker/change-volume',
    // スピーカーミュート実施
    MUTE_SPEAKER: '/speaker/mute',
    // スピーカーミュート解除
    UNMUTE_SPEAKER: '/speaker/unmute',
    // スピーカー設定反映
    SETTING_SPEAKER: '/speaker/setting',
    // スピーカー音源一覧取得
    GET_SPEAKER_SOUND_SOURCE: '/speaker/sound-source/list',
    // スピーカーステータス取得
    GET_SPEAKER_STATUS: '/speaker/status',
    // アンケート情報取得
    GET_SURVEY: '/survey',
    // ログ情報取得（オフィス管理者向け）
    GET_USER_LOGS: '/logs/user',
    // ログ情報登録（オフィス利用者向け）
    CREATE_USER_LOG: '/logs/user/create',
    // ログ情報登録（オフィス管理者向け）
    CREATE_MANAGER_LOG: '/logs/manager/create',
    // スケジュール一覧取得
    GET_SCHEDULES: '/schedule/list',
    // スケジュール登録
    CREATE_SCHEDULE: '/schedule/create',
    // スケジュール更新
    UPDATE_SCHEDULE: '/schedule/update',
    // スケジュール削除
    DELETE_SCHEDULE: '/schedule/delete',
    // 拠点情報変更チェック
    CONFIRM_CHANGED_BASE_INFO: '/base/confirm-changed-info',
    // 拠点情報CSV反映
    REFLECT_BASE_CSV: '/base/reflect-csv',
};

export const RESPONSE_STATUS = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500,
};

export const REQUEST_QUERY = {
    GET_USER: '?userId={0}',
    GET_FLOOR_LIST: '?userId={0}',
    LOAD_FLOOR: '?userId={0}&floorId={1}',
    GET_SPEAKER: '?speakerId={0}',
    GET_SPEAKER_STATUS: '?floorId={0}',
    CHECK_SPEAKER_CONNECTION: '?userId={0}&baseId={1}&baseSpeakerId={2}',
    GET_SCHEDULES: '?userId={0}&floorId={1}',
    GET_LOG: '?baseId={0}&startDate={1}&endDate={2}&sortKey={3}&sortType={4}',
    GET_SPEAKER_SOUND_SOURCE: '?speakerId={0}&userType={1}',
    GET_SURVEY: '?baseId={0}&speakerId={1}',
    CONFIRM_CHANGED_BASE_INFO: '?userId={0}',
};
