import classNames from 'classnames';
import React, { createRef } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row,
    Col,
} from 'reactstrap';
import { MODAL, MODE } from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';
import {
    getFloorList,
    loadFloor,
    deleteFloor as deleteFloorApi,
} from '../../../api/areaSettingApi';

import ModalChoice from './ModalChoice';
import ModalMessage from './ModalMessage';

/**
 * 既存フロア読込モーダル
 */
class ModalLoadFloor extends React.Component {
    /**
     * @constructor
     * @param {object} props
     * @param {string} props.modalMode - モーダルの状態(フロア情報の読み込み/削除)
     * @param {function} props.loadJSON - JSONファイルの読み込み (フロア情報の読み込み時のみ)
     * @param {function} props.saveFloor - フロア情報の保存処理 (フロア情報の読み込み時のみ)
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * 既存フロア読込モーダル表示有無
             * @type {boolean}
             */
            modal: false,
            /**
             * 選択中のフロアID
             * @type {number}
             */
            targetFloor: null,
            /**
             * フロア情報リスト
             */
            floorList: [{}],
            /**
             * フロア情報削除処理結果メッセージ
             */
            resultMessage: '',
        };

        /**
         * 操作選択モーダル
         */
        this[MODAL.CHOICE_SAVE_FLOOR] = createRef();
        this[MODAL.CONFIRM_DELETE_FLOOR] = createRef();
        this[MODAL.LOAD_DELETE_FLOOR_MSG] = createRef();
    }

    /**
     * 選択フロア設定
     * @param {number} targetFloor - 選択フロアID
     */
    setTargetFloor = (targetFloor) => {
        this.setState({ targetFloor: targetFloor });
    };
    /**
     * モーダル表示有無設定
     * @param {boolean} flag - モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * フロアリスト設定
     * @param {object[]} floorList
     */
    setFloorList = (floorList) => {
        this.setState({ floorList: floorList });
    };

    /**
     * フロア情報削除処理結果メッセージ設定
     * @param {string} message
     */
    setResultMessage = (message) => {
        this.setState({ resultMessage: message });
    };

    /**
     * モーダル初期化
     */
    init = async () => {
        try {
            // フロアマップ情報取得API、ユーザーIDに応じた拠点のフロア情報を取得
            const result = await getFloorList(this.props.userId);

            if (!result || !result.floorInfo || result.floorInfo.length === 0) {
                // フロアマップが無い場合
                this.setResultMessage(DISP_RESOURCE.LOAD_FLOOR_NO_FLOOR);
                this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
                return;
            }

            this.setFloorList(result.floorInfo);
            this.setTargetFloor(null);
            this.setModal(true);
        } catch (e) {
            this.setResultMessage(DISP_RESOURCE.LOAD_FAILED);
            this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
        }
    };

    /**
     * モーダル閉じ
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * 読み込み、削除ボタン押下時処理
     */
    onClickHandler = async () => {
        if (this.state.targetFloor === null) {
            // フロアが選択されていない場合何もしない、画面もとじない
            return;
        }

        if (this.props.modalMode === MODAL.LOAD_FLOOR) {
            // フロア情報読み込みの場合
            if (!this.props.savedFlag) {
                // フロア情報未保存時は、保存するかの操作選択モーダルを出す
                this.openModal(MODAL.CHOICE_SAVE_FLOOR);
            } else {
                try {
                    // フロア情報保存時はJSONファイル読み込み
                    const result = await loadFloor(
                        this.props.userId,
                        this.state.targetFloor
                    );
                    if (!result || !result.floorInfo) {
                        return;
                    }
                    const jsonData = {
                        floorId: result.floorInfo.floorId,
                        floorName: result.floorInfo.floorName,
                        areaImage: result.floorInfo.floorMap,
                        realWidth: result.floorInfo.mapRealWidth,
                        baseSpeakers: result.floorInfo.baseSpeakerInfo ?? [],
                        speakers: result.floorInfo.speakerInfo ?? [],
                        groups: result.floorInfo.speakerGroupInfo ?? [],
                        isOperating: result.floorInfo.isOperation,
                        mode: result.floorInfo.isOperation ? MODE.SET_SCHEDULES : MODE.SET_SPEAKERS,
                        savedFlag: true,
                    };
                    this.load(jsonData);
                } catch (e) {
                    this.setResultMessage(DISP_RESOURCE.LOAD_FAILED);
                    this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
                }
            }
        } else {
            // フロア情報削除の場合
            this.openModal(MODAL.CONFIRM_DELETE_FLOOR);
        }
    };

    /**
     * 対象のJSONデータ読み込み
     * @param {object} jsonData
     */
    load = (jsonData) => {
        // JSONファイル読み込み
        this.props.settingAreaInfo(jsonData);
        this.close();
        this.setResultMessage(DISP_RESOURCE.LOAD_SUCCESS);
        this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
    };

    /**
     * このコンポーネントから呼び出すモーダルの表示処理
     * @param {string} modalName - モーダル名
     * @param {[string, number] | null} param - パラメータ
     */
    openModal = (modalName) => {
        this[modalName].current.init();
    };

    /**
     * 操作選択モーダルで選んだ選択肢の操作を実行
     * @param {number} choiceNumber - モーダルで選択したラジオボタンの値
     */
    choiceAction = async (choiceNumber) => {
        try {
            const result = await loadFloor(
                this.props.userId,
                this.state.targetFloor
            );
            const jsonData = {
                floorId: result.floorInfo.floorId,
                floorName: result.floorInfo.floorName,
                areaImage: result.floorInfo.floorMap,
                realWidth: result.floorInfo.mapRealWidth,
                baseSpeakers: result.floorInfo.baseSpeakerInfo ?? [],
                speakers: result.floorInfo.speakerInfo ?? [],
                groups: result.floorInfo.speakerGroupInfo ?? [],
                mode: MODE.SET_SPEAKERS,
                savedFlag: true,
            };
            if (choiceNumber === '0' && this.props.floorName === '') {
                // 未保存のフロアマップを保存する かつ フロア名未設定時
                this.props.modalOpen(MODAL.SAVE_FLOOR, jsonData);
                this.close();
            } else if (choiceNumber === '0') {
                // 未保存のフロアマップを保存する場合
                this.props.saveFloor();
                this.load(jsonData);
            } else {
                // 未保存のフロアマップを保存しない場合
                this.load(jsonData);
            }
        } catch (e) {
            // フロア情報読み込み失敗時
            this.setResultMessage(DISP_RESOURCE.LOAD_FAILED);
            this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
        }
    };

    /**
     * フロア情報削除
     */
    deleteFloor = async () => {
        try {
            // フロア削除API
            await deleteFloorApi(this.props.userId, this.state.targetFloor);

            // 表示中のフロア情報を削除する場合は初期表示に戻す
            this.props.clearFloorInfo(this.state.targetFloor);

            this.setResultMessage(DISP_RESOURCE.DELETE_SUCCESS);
        } catch (e) {
            // エラー発生時
            this.setResultMessage(DISP_RESOURCE.DELETE_FAILED);
        }
        this.close();
        this.openModal(MODAL.LOAD_DELETE_FLOOR_MSG);
    };

    /**
     * 画面描画
     */
    render() {
        return (
            <>
                <Modal
                    className="modal_load_floor"
                    isOpen={this.state.modal}
                    centered
                    backdrop="static"
                >
                    {/* モーダルヘッダー */}
                    <ModalHeader toggle={this.close}></ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody>
                        <Label sm={12}>
                            {DISP_RESOURCE.LOAD_FLOOR_MESSAGE}
                        </Label>
                        <div className="div_load_floor_radio" sm={12}>
                            <Row>
                                {this.state.floorList.map((floorInfo, idx) => {
                                    return (
                                        <Col sm={4} key={idx}>
                                            <Button
                                                className={classNames(
                                                    this.state.targetFloor ===
                                                        floorInfo.floorId
                                                        ? 'active_radio'
                                                        : '',
                                                    'w-100',
                                                    'floor_name'
                                                )}
                                                color="secondary"
                                                onClick={() =>
                                                    this.setTargetFloor(
                                                        floorInfo.floorId
                                                    )
                                                }
                                                active={
                                                    this.state.targetFloor ===
                                                    floorInfo.floorId
                                                }
                                                title={floorInfo.floorName}
                                            >
                                                {floorInfo.floorName}
                                            </Button>
                                        </Col>
                                    );
                                }, [])}
                            </Row>
                        </div>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter>
                        <Button
                            color="success"
                            className="w-100"
                            onClick={this.onClickHandler}
                        >
                            {/* モーダルの表示状態に応じてボタン名切り替え */}
                            {this.props.modalMode === MODAL.LOAD_FLOOR
                                ? DISP_RESOURCE.LOAD_FLOOR_BUTTON
                                : DISP_RESOURCE.DELETE}
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* 操作選択モーダル */}
                <ModalChoice
                    ref={this[MODAL.CHOICE_SAVE_FLOOR]}
                    title={DISP_RESOURCE.CHOICE_SAVE_FLOOR_TITLE}
                    content={DISP_RESOURCE.CHOICE_SAVE_FLOOR_CONTENT}
                    firstOpt={DISP_RESOURCE.CHOICE_SAVE_FLOOR_FIRST_OPT_LOAD}
                    secondOpt={DISP_RESOURCE.CHOICE_SAVE_FLOOR_SECOND_OPT_LOAD}
                    okProc={this.choiceAction}
                ></ModalChoice>

                {/* フロア削除確認モーダル */}
                <ModalMessage
                    ref={this[MODAL.CONFIRM_DELETE_FLOOR]}
                    title={DISP_RESOURCE.DELETE_FLOOR_TITLE}
                    content={DISP_RESOURCE.DELETE_FLOOR_CONTENT}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.deleteFloor}
                    isCancel={true}
                ></ModalMessage>
                {/* フロア削除処理結果表示モーダル */}
                <ModalMessage
                    ref={this[MODAL.LOAD_DELETE_FLOOR_MSG]}
                    content={this.state.resultMessage}
                    okString={DISP_RESOURCE.OK}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
            </>
        );
    }
}

export default ModalLoadFloor;
