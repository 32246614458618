import React, { createRef } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import ModalMessage from './ModalMessage';
import { BASE_SPEAKER_CHANGE_TYPE } from '../../../common/constants';
import { reflectBaseCsv } from '../../../api/areaSettingApi';

/**
 * 運用・設定切替用モーダル
 */
class ModalOperationMode extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {string} props.title - モーダルタイトル(必須でない)
     * @param {string} props.content - メッセージ
     * @param {boolean} props.savedFlag - 保存済み確認
     * @param {function} props.changeOperationMode - 運用モード切替処理
     * @param {function} props.reloadFloor - フロアリロード処理
     * @param {function} props.checkFloor - 拠点情報比較処理
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} モーダル表示有無
             */
            modal: false,
            /**
             * @type {string} 表示文言
             */
            content: '',
            /**
             * @type {string} フロア更新確認文言
             */
            checkFloorStr: '',
            /**
             * @type {object[]} 変更拠点スピーカーリスト
             */
            changeBaseSpeakers: [],
            /**
             * @type {string} 処理結果文言
             */
            message: '',
        };

        this.parser = require('html-react-parser');
        this.choiceModal = createRef();
        this.checkBaseModal = createRef();
        this.messageModal = createRef();
    }

    /**
     * モーダル表示有無設定
     * @param {boolean} flag -モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * 表示文言設定
     * @param {boolean} flag -モーダル表示有無
     */
    setContent = (content) => {
        this.setState({ content });
    };

    /**
     * フロア更新確認文言設定
     * @param {string} checkFloorStr
     */
    setDiffStr = (checkFloorStr) => {
        this.setState({ checkFloorStr });
    };

    /**
     * 変更拠点スピーカーリスト設定
     * @param {object[]} changeBaseSpeakers
     */
    setChangeBaseSpeakers = (changeBaseSpeakers) => {
        this.setState({ changeBaseSpeakers });
    };

    /**
     * 処理結果文言設定
     * @param {string} message
     */
    setMessage = (message) => {
        this.setState({ message });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        const content = this.props.isOperating
            ? DISP_RESOURCE.TOP_OPERATION_TO_SETTING
            : DISP_RESOURCE.TOP_SETTING_TO_OPERATION;
        this.setContent(content);
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = async () => {
        const { isOperating, savedFlag, checkFloor, changeOperationMode } =
            this.props;

        if (isOperating) {
            // 運用モードから設定モード
            try {
                const checkFloorInfo = await checkFloor();

                if (
                    checkFloorInfo &&
                    checkFloorInfo.changedBaseSpeaker &&
                    checkFloorInfo.changedBaseSpeaker.length > 0
                ) {
                    // 拠点スピーカーの追加・更新・削除がある場合
                    let checkFloorStr = '<br /><br />';
                    for (let info of checkFloorInfo.changedBaseSpeaker) {
                        const idx = BASE_SPEAKER_CHANGE_TYPE.findIndex(
                            (item) => item.key === info.changeType
                        );

                        checkFloorStr +=
                            '[' +
                            BASE_SPEAKER_CHANGE_TYPE[idx].type +
                            '] ' +
                            info.unitName +
                            '<br />';
                    }

                    this.setDiffStr(checkFloorStr);
                    this.setChangeBaseSpeakers(
                        checkFloorInfo.changedBaseSpeaker
                    );
                    this.checkBaseModal.current.init();
                } else {
                    // 拠点スピーカーの追加・更新・削除が無い場合
                    changeOperationMode(false);
                }
            } catch (e) {
                // エラーによる更新チェック失敗時
                this.setMessage(DISP_RESOURCE.EXCEPTION_OCCURED);
                this.messageModal.current.init();
                changeOperationMode(false);
            }
        } else {
            // 設定モードから運用モード
            if (savedFlag) {
                changeOperationMode(true);
            }
        }
        this.close();
    };

    /**
     * 拠点情報変更確認
     */
    checkBaseOkHandler = async () => {
        const { changeOperationMode } = this.props;
        const { changeBaseSpeakers } = this.state;

        // 拠点情報更新
        let requestParam = {
            userId: this.props.userId,
            changedBaseSpeaker: changeBaseSpeakers,
        };
        try {
            await reflectBaseCsv(requestParam);
            // 拠点情報更新成功時
            this.setMessage(DISP_RESOURCE.UPDATE_SUCCESS);
            this.messageModal.current.init();

            changeOperationMode(false);
        } catch (e) {
            // 拠点情報更新失敗時
            this.setMessage(DISP_RESOURCE.UPDATE_FAILED);
            this.messageModal.current.init();
        }
    };

    /**
     * 拠点情報変更無視
     */
    checkBaseCancelHandler = () => {
        const { changeOperationMode } = this.props;
        changeOperationMode(false);
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const { modal, content, checkFloorStr, message } = this.state;

        return (
            <>
                <Modal
                    className="modal_commons"
                    isOpen={modal}
                    centered
                    backdrop="static"
                >
                    {/* モーダルヘッダー タイトル表示欄(タイトルは必須ではない) */}
                    <ModalHeader toggle={this.close}></ModalHeader>
                    {/* モーダルボディ メッセージ表示欄 */}
                    <ModalBody className="px-5">
                        <Label for="modal_commons_content">
                            {this.parser(content)}
                        </Label>
                    </ModalBody>
                    {/* モーダルフッター ボタン類*/}
                    <ModalFooter className="with_cancel">
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.onClickOkHandler}>
                            {DISP_RESOURCE.OK}
                        </Button>
                    </ModalFooter>
                </Modal>
                <ModalMessage
                    ref={this.checkBaseModal}
                    content={DISP_RESOURCE.TOP_DIFF_INFO + checkFloorStr}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.checkBaseOkHandler}
                    cancelProc={this.checkBaseCancelHandler}
                    isCancel={true}
                />
                <ModalMessage
                    ref={this.messageModal}
                    content={message}
                    okString={DISP_RESOURCE.OK}
                    okProc={() => {}}
                    isCancel={false}
                />
            </>
        );
    }
}

export default ModalOperationMode;
