import moment from 'moment';
import {
    REPEAT_TYPE,
    SCHEDULE_GROUP_SPEAKER_ID_PREFIX,
} from '../../../common/constants';

/**
 * 新規スケジュールリスト取得
 * @param {number[]} speakerIds 対象スピーカーID
 * @param {object} scheduleInfo スケジュール情報
 * @returns {object[]} 新規スケジュールリスト
 */
export const getNewScheduleList = (speakerIds, scheduleInfo) => {
    // 新規スケジュール時間リスト
    const repeatList = [];

    // 対象スピーカーIDでループ
    for (const speakerId of speakerIds) {
        // スケジュール繰り返し情報テーブル項目セット（「繰り返しなし」の場合は1件のみ）
        const repeatParams = {
            speakerId,
            scheduleId: scheduleInfo.id,
            newStart: moment(
                scheduleInfo.periodStartDate +
                    ' ' +
                    scheduleInfo.periodStartTime,
                'YYYY-MM-DD HH:mm'
            ),
            newEnd: moment(
                scheduleInfo.periodEndDate + ' ' + scheduleInfo.periodEndTime,
                'YYYY-MM-DD HH:mm'
            ),
        };

            // 繰返し（毎日以外）の場合、初期スケジュールの設定をしない
        if (
            !scheduleInfo.repeatFlag ||
            (scheduleInfo.repeatFlag &&
                scheduleInfo.repeatType === REPEAT_TYPE.EVERY_DAY)
        ) {
            repeatList.push(repeatParams);
        }

        // 繰返しの設定があり場合
        if (scheduleInfo.repeat) {
            switch (scheduleInfo.repeatType) {
                // 毎日
                case REPEAT_TYPE.EVERY_DAY:
                    {
                        // 設定日を含む5日設定：設定日 + 4日
                        const repeatDay =
                            Number.parseInt(scheduleInfo.repeatOption1) - 1;

                        for (let i = 1; i <= repeatDay; i++) {
                            // 設定期間に日数を加える
                            const startDate = moment(
                                scheduleInfo.periodStartDate
                            )
                                .add(i, 'days')
                                .format('YYYY-MM-DD');
                            const endDate = moment(scheduleInfo.periodEndDate)
                                .add(i, 'days')
                                .format('YYYY-MM-DD');

                            const repeatParams = {
                                speakerId,
                                scheduleId: scheduleInfo.id,
                                newStart: moment(
                                    startDate +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                                newEnd: moment(
                                    endDate + ' ' + scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                            };
                            repeatList.push(repeatParams);
                        }
                    }
                    break;
                // 毎週
                case REPEAT_TYPE.EVERY_WEEK:
                    {
                        // 週
                        const targetDays = scheduleInfo.repeatOption2;

                        // 設定期間
                        const baseStart = moment(scheduleInfo.periodStartDate);
                        const baseEnd = moment(scheduleInfo.periodEndDate);
                        const repeatEnd = moment(scheduleInfo.repeatEndDate);

                        // 日をまたぐ場合
                        const isTwoDay = baseEnd.isAfter(baseStart);
                        
                        // 設定期間の週数
                        const weeks = Math.ceil(repeatEnd.diff(baseStart, 'days') / 7);

                        // 設定日のある週は設定日以後からセット
                        for (const dayIdx of targetDays) {
                            if (dayIdx >= baseStart.weekday()) {
                                // 開始日が繰返し終了日を超える場合終了
                                if (moment(baseStart).day(dayIdx).diff(repeatEnd) > 0) {
                                    break;
                                }

                                // 日をまたぐ場合、終了日を次の日にセット
                                const endDayIdx = isTwoDay
                                    ? dayIdx + 1
                                    : dayIdx;

                                const newStart = moment(
                                    moment(baseStart)
                                        .day(dayIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const newEnd = moment(
                                    moment(baseEnd)
                                        .day(endDayIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const repeatParams = {
                                    speakerId,
                                    scheduleId: scheduleInfo.id,
                                    newStart,
                                    newEnd,
                                };
                                repeatList.push(repeatParams);
                            }
                        }

                        // 設定日の翌週から指定の曜日をセット
                        for (let i = 1; i <= weeks; i++) {
                            const startDate = moment(baseStart).add(i, 'weeks');
                            const endDate = moment(baseEnd).add(i, 'weeks');

                            for (const dayIdx of targetDays) {
                                // 日をまたぐ場合、終了日を次の日にセット
                                const endDayIdx = isTwoDay
                                    ? dayIdx + 1
                                    : dayIdx;

                                // 開始日が繰返し終了日を超える場合終了
                                if (startDate.day(dayIdx).diff(repeatEnd) > 0) {
                                    break;
                                }

                                const newStart = moment(
                                    moment(startDate)
                                        .day(dayIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const newEnd = moment(
                                    moment(endDate)
                                        .day(endDayIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                );

                                const repeatParams = {
                                    speakerId,
                                    scheduleId: scheduleInfo.id,
                                    newStart,
                                    newEnd,
                                };
                                repeatList.push(repeatParams);
                            }
                        }
                    }
                    break;
                // 週月（日付指定）
                case REPEAT_TYPE.PICK_DATE:
                    {
                        // 月間隔
                        const monthInterval = Number.parseInt(
                            scheduleInfo.repeatOption1
                        );
                        // 対象日付
                        const targetDates = scheduleInfo.repeatOption2;

                        // 設定期間
                        const baseStart = moment(scheduleInfo.periodStartDate);
                        const baseEnd = moment(scheduleInfo.periodEndDate);
                        const repeatEnd = moment(scheduleInfo.repeatEndDate);

                        // 日をまたぐ場合
                        const isTwoDay = baseEnd.isAfter(baseStart);

                        // 設定期間の月数
                        const month = repeatEnd.diff(baseStart, 'month');

                        // 設定日のある月は設定日以後からセット
                        for (const date of targetDates) {
                            const endDateIdx = isTwoDay ? date + 1 : date;

                            if (date >= baseStart.get('date')) {
                                const newStart = moment(
                                    moment(baseStart)
                                        .date(date)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const newEnd = moment(
                                    moment(baseEnd)
                                        .date(endDateIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                );

                                // 開始日が繰返し終了日を超える場合終了
                                if (newStart.diff(repeatEnd) > 0) {
                                    break;
                                }

                                /** momentjsの仕様上、
                                 * 「2/31」など存在しない日付は翌日に自動変換されるので、
                                 *  日付を再確認し設定日と合わない場合、スキップする */
                                if (newStart.get('date') !== date) continue;

                                const repeatParams = {
                                    speakerId,
                                    scheduleId: scheduleInfo.id,
                                    newStart,
                                    newEnd,
                                };
                                repeatList.push(repeatParams);
                            }
                        }

                        // 設定の月単位にセット
                        for (
                            let i = monthInterval;
                            i <= month;
                            i = i + monthInterval
                        ) {
                            for (const dateIdx of targetDates) {
                                // 日をまたぐ場合、終了日を次の日にセット
                                const endDateIdx = isTwoDay
                                    ? dateIdx + 1
                                    : dateIdx;

                                const newStart = moment(
                                    moment(baseStart)
                                        .add(i, 'months')
                                        .date(dateIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const newEnd = moment(
                                    moment(baseEnd)
                                        .add(i, 'months')
                                        .date(endDateIdx)
                                        .format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                );

                                /** momentjsの仕様上、
                                 * 「2/31」など存在しない日付は翌日に自動変換されるので、
                                 *  日付を再確認し設定日と合わない場合、スキップする */
                                if (newStart.get('date') !== dateIdx) continue;

                                // 開始日が繰返し終了日を超える場合終了
                                if (newStart.diff(repeatEnd) > 0) {
                                    break;
                                }

                                const repeatParams = {
                                    speakerId,
                                    scheduleId: scheduleInfo.id,
                                    newStart,
                                    newEnd,
                                };
                                repeatList.push(repeatParams);
                            }
                        }
                    }
                    break;
                // 週月（曜日指定）
                case REPEAT_TYPE.PICK_DAY:
                    {
                        // 月間隔
                        const monthInterval = Number.parseInt(
                            scheduleInfo.repeatOption1
                        );
                        // 対象週
                        const targetWeeks = scheduleInfo.repeatOption2;
                        // 対象曜日
                        const targetDays = scheduleInfo.repeatOption3;

                        // 設定期間
                        const baseStart = moment(scheduleInfo.periodStartDate);
                        const baseEnd = moment(scheduleInfo.periodEndDate);
                        const repeatEnd = moment(scheduleInfo.repeatEndDate);

                        // 日をまたぐ場合
                        const isTwoDay = baseEnd.isAfter(baseStart);

                        // 設定期間の月数
                        const months = repeatEnd.diff(baseStart, 'month');

                        // 設定月の最初日
                        let monthStart = moment(baseStart).startOf('month');
                        // 設定月の最終日
                        let monthEnd = moment(baseEnd).endOf('month');
                        // 設定月の日数
                        let monthDays = monthEnd.date();

                        // 最初月の開始日
                        const firstMonthDate = baseStart.date();

                        // 日数分ループ(最初月)
                        for(let day = firstMonthDate; day <= monthDays; day++) {
                            // 月の開始日から日数分加算
                            const targetDate = moment(monthStart.format()).add(day - 1, 'd');

                            // 繰返し終了日を超えている場合は終了
                            if (targetDate.diff(repeatEnd) > 0) break;

                            // 日数から何週目の何曜日であるかを計算
                            const targetWeek = Math.ceil(day / 7);
                            const targetDay = targetDate.day();

                            // 繰返し対象日の場合
                            if (targetWeeks.includes(targetWeek) && targetDays.includes(targetDay)) {
                                // 日をまたぐ場合、終了日を次の日にセット
                                const addDay = isTwoDay ? 1 : 0;
                                const startDate = moment(targetDate.format());
                                const endDate = moment(targetDate.format()).add(addDay, 'd');

                                const newStart = moment(
                                    startDate.format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const newEnd = moment(
                                    endDate.format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                );
                                const repeatParams = {
                                    speakerId,
                                    scheduleId: scheduleInfo.id,
                                    newStart,
                                    newEnd,
                                };
                                repeatList.push(repeatParams);
                            }
                        }

                        // 設定の月間隔にセット
                        for (let i = monthInterval; i <= months; i = i + monthInterval) {
                            // 対象月の最初日
                            monthStart = moment(baseStart)
                                .add(i, 'months')
                                .startOf('month');
                            // 対象月の最終日
                            monthEnd = moment(baseEnd)
                                .add(i, 'months')
                                .endOf('month');

                            // 対象月の日数
                            monthDays = monthEnd.date();

                            // 日数分ループ
                            for(let day = 1; day <= monthDays; day++) {
                                // 月の開始日から日数分加算
                                const targetDate = moment(monthStart.format()).add(day - 1, 'd');

                                // 繰返し終了日を超えている場合は終了
                                if (targetDate.diff(repeatEnd) > 0) break;

                                // 日数から何週目の何曜日であるかを計算
                                const targetWeek = Math.ceil(day / 7);
                                const targetDay = targetDate.day();

                                // 繰返し対象日の場合
                                if (targetWeeks.includes(targetWeek) && targetDays.includes(targetDay)) {
                                    // 日をまたぐ場合、終了日を次の日にセット
                                    const addDay = isTwoDay ? 1 : 0;
                                    const startDate = moment(targetDate.format());
                                    const endDate = moment(targetDate.format()).add(addDay, 'd');

                                    const newStart = moment(
                                        startDate.format('YYYY-MM-DD') +
                                            ' ' +
                                            scheduleInfo.periodStartTime,
                                        'YYYY-MM-DD HH:mm'
                                    );
                                    const newEnd = moment(
                                        endDate.format('YYYY-MM-DD') +
                                            ' ' +
                                            scheduleInfo.periodEndTime,
                                        'YYYY-MM-DD HH:mm'
                                    );
                                    const repeatParams = {
                                        speakerId,
                                        scheduleId: scheduleInfo.id,
                                        newStart,
                                        newEnd,
                                    };
                                    repeatList.push(repeatParams);
                                }
                            }
                        }
                    }
                    break;
                // 周年
                case REPEAT_TYPE.EVERY_YEAR:
                    {
                        // 設定年を含む5年設定：設定年 + 4年
                        const repeatYear = Number.parseInt(
                            scheduleInfo.repeatOption1
                        );

                        const baseStart = moment(scheduleInfo.periodStartDate);
                        const repeatEnd = moment(scheduleInfo.repeatEndDate);
                        
                        // 設定期間の年数
                        const years = repeatEnd.diff(baseStart, 'years');

                        for (
                            let i = 0;
                            i <= years;
                            i = i + repeatYear
                        ) {
                            // 設定期間に年数を加える
                            const startDate = moment(
                                scheduleInfo.periodStartDate
                            )
                                .add(i, 'years')
                                .format('YYYY-MM-DD');
                            const endDate = moment(scheduleInfo.periodEndDate)
                                .add(i, 'years')
                                .format('YYYY-MM-DD');

                            // 開始日が繰返し終了日を超える場合終了
                            if (moment(startDate).diff(repeatEnd) > 0) {
                                break;
                            }

                            const repeatParams = {
                                speakerId,
                                scheduleId: scheduleInfo.id,
                                newStart: moment(
                                    startDate +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                                newEnd: moment(
                                    endDate + ' ' + scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                            };
                            repeatList.push(repeatParams);
                        }
                    }
                    break;
                // カスタム
                case REPEAT_TYPE.CUSTOM:
                    {
                        const targetCustomDate = scheduleInfo.repeatCustom;

                        // 設定期間
                        const baseStart = moment(scheduleInfo.periodStartDate);
                        const baseEnd = moment(scheduleInfo.periodEndDate);

                        // 日をまたぐ場合
                        const isTwoDay = baseEnd.isAfter(baseStart);
                        for (const date of targetCustomDate) {
                            // 日をまたぐ場合、終了日を次の日にセット
                            const startDate = moment(date);
                            const endDate = isTwoDay
                                ? moment(date).add(1, 'days')
                                : moment(date);

                            const repeatParams = {
                                speakerId,
                                scheduleId: scheduleInfo.id,
                                newStart: moment(
                                    startDate.format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodStartTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                                newEnd: moment(
                                    endDate.format('YYYY-MM-DD') +
                                        ' ' +
                                        scheduleInfo.periodEndTime,
                                    'YYYY-MM-DD HH:mm'
                                ),
                            };
                            repeatList.push(repeatParams);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
    return repeatList;
};

/**
 * 重複スケジュールリスト取得
 * @param {number[]} speakerIds 対象スピーカーIDリスト
 * @param {object[]} newSchedules 新規スケジュール
 * @param {object[]} oldSchedules 既存スケジュール
 * @returns {object[]} 重複スケジュールリスト
 */
export const getDuplicateSchedules = (
    speakerIds,
    newSchedules,
    oldSchedules,
    scheduleInfo
) => {
    // 重複スケジュールリスト
    const duplList = [];

    // 選択中のスピーカーから時間が重複するスケジュール検索
    for (const targetSpeakerId of speakerIds) {
        // 新規スケジュールリストからスピーカーIDで絞りこみ
        const newSchList = newSchedules.filter(
            (newSch) => Number(newSch.speakerId) === targetSpeakerId
        );

        // スケジュールID（新規なら null）
        const scheduleId = newSchList[0].scheduleId;

        // 更新の場合、スケジュールグループIDを取得
        let scheduleGroupId = null;
        if (scheduleId !== null) {
            const schedule = oldSchedules.find(
                (schedule) => schedule.scheduleId === scheduleId
            );
            scheduleGroupId = schedule.scheduleGroupId;
        }

        // 新規は同スピーカーIDを持つ「既存スケジュール」すべてが重複確認対象
        // 更新 当スケジュールIDを除外した同スピーカーIDを持つ「既存スケジュール」が対象
        //      グループIDがあり場合、同グループIDを除外した同スピーカーIDを持つ「既存スケジュール」が対象
        const oldSchList =
            scheduleId === null
                ? oldSchedules.filter(
                      (oldSch) => Number(oldSch.speakerId) === targetSpeakerId
                  )
                : scheduleGroupId === null
                ? oldSchedules.filter(
                      (oldSch) =>
                          Number(oldSch.speakerId) === targetSpeakerId &&
                          oldSch.scheduleId !== scheduleId
                  )
                : oldSchedules.filter(
                      (oldSch) =>
                          Number(oldSch.speakerId) === targetSpeakerId &&
                          oldSch.scheduleGroupId !== scheduleGroupId
                  );

        // 更新時のみ以下を処理
        if (scheduleId !== null) {
            // 編集前の繰返し情報取得
            const selectedRepeat = oldSchedules.find(
                (old) => old.scheduleRepeatId === scheduleInfo.scheduleRepeatId
            );
            const selectedOldPeriodStart = moment(
                `${selectedRepeat.periodStartDate} ${selectedRepeat.periodStartTime}`
            );
            const selectedOldPeriodEnd = moment(
                `${selectedRepeat.periodEndDate} ${selectedRepeat.periodEndTime}`
            );

            // 編集後の期間が編集前より以前の日付の場合、同スケジュールの修正前期間の設定期間も重複対象にする
            const isChangedPeriodBeforeOld = selectedOldPeriodStart.isAfter(
                newSchList[0].newStart
            );

            if (isChangedPeriodBeforeOld) {
                // 選択した繰返し情報は重複対処から除外する必要があるため、グループなら同期間の別スピーカーの繰返しIDも格納
                const repeatIds = [];
                if (scheduleGroupId !== null) {
                    oldSchedules.reduce((repeatIds, old) => {
                        if (old.scheduleGroupId !== scheduleGroupId)
                            return repeatIds;

                        const oldPeriodStart = moment(
                            `${old.periodStartDate} ${old.periodStartTime}`
                        );
                        const oldPeriodEnd = moment(
                            `${old.periodEndDate} ${old.periodEndTime}`
                        );

                        if (
                            Number(old.speakerId) === targetSpeakerId &&
                            oldPeriodStart.isSame(selectedOldPeriodStart) &&
                            oldPeriodEnd.isSame(selectedOldPeriodEnd) &&
                            !repeatIds.includes(old.scheduleRepeatId)
                        ) {
                            repeatIds.push(old.scheduleRepeatId);
                        }
                        return repeatIds;
                    }, repeatIds);
                } else {
                    repeatIds.push(scheduleInfo.scheduleRepeatId);
                }

                let targetScheduleId = null;

                if (scheduleGroupId !== null) {
                    targetScheduleId = oldSchedules.find(
                        (old) =>
                            Number(old.speakerId) === targetSpeakerId &&
                            old.scheduleGroupId === scheduleGroupId
                    ).scheduleId;
                } else {
                    targetScheduleId = scheduleId;
                }

                // 設定期間より前の同スケジュールの繰返し期間を取得
                const scheduleBeforeSettingPeriodList = oldSchedules.filter(
                    (oldSch) => {
                        // スピーカーID、スケジュールID、既存から繰返しあり、選択（編集をした）繰返し情報ではない、で絞る
                        if (
                            !(
                                Number(oldSch.speakerId) === targetSpeakerId &&
                                oldSch.scheduleId === targetScheduleId &&
                                oldSch.repeatFlag
                            ) ||
                            repeatIds.includes(oldSch.scheduleRepeatId)
                        )
                            return false;

                        // 既存（編集前）の開始日時
                        const oldStart = moment(
                            `${oldSch.periodStartDate} ${oldSch.periodStartTime}`
                        );

                        // 既存開始日時より前に開始しているスケジュール
                        return oldStart.isBefore(selectedOldPeriodStart);
                    }
                );

                // 取得した重複候補をリストの先頭に格納（重複モーダル表示の場合、時間順を保つため）
                oldSchList.unshift(...scheduleBeforeSettingPeriodList);
            }
        }
        // 重複確認
        for (const newSch of newSchList) {
            const list = oldSchList.filter((sch) => {
                let result = false;
                // 新規開始 <= 既存開始 < 新規終了
                let oldStart = moment(
                    sch.periodStartDate + ' ' + sch.periodStartTime,
                    'YYYY-MM-DD HH:mm'
                );
                let oldEnd = null;

                result =
                    oldStart.isBetween(newSch.newStart, newSch.newEnd) ||
                    newSch.newStart.isSame(oldStart);

                if (!result) {
                    oldEnd = moment(
                        sch.periodEndDate + ' ' + sch.periodEndTime,
                        'YYYY-MM-DD HH:mm'
                    );

                    // 新規開始 < 既存終了 <= 新規終了
                    result =
                        oldEnd.isBetween(newSch.newStart, newSch.newEnd) ||
                        newSch.newEnd.isSame(oldEnd);
                }

                // 既存開始 ＜ 新規 ＜ 既存終了
                if (!result) {
                    const startBetweenOld = newSch.newStart.isBetween(
                        oldStart,
                        oldEnd
                    );
                    const endBetweenOld = newSch.newEnd.isBetween(
                        oldStart,
                        oldEnd
                    );
                    result = startBetweenOld && endBetweenOld;
                }
                return result;
            });

            // 重複するスケジュールは重複リストに格納
            for (const schedule of list) {
                duplList.push({
                    speakerId: Number(schedule.speakerId),
                    scheduleRepeatId: schedule.scheduleRepeatId,
                    applyType: null,
                });
            }
        }
    }

    return duplList;
};

/**
 * スケジュール設定値変更確認
 * @param {object} newSch 新規スケジュール
 * @param {object} oldSch 既存スケジュール
 * @returns
 */
export const diffScheduleData = (newSch, oldSch) => {
    // グループ行フラグ
    const isGroupHeader = newSch.speakerId.startsWith(
        SCHEDULE_GROUP_SPEAKER_ID_PREFIX
    );

    // 期間変更確認
    const start = moment(newSch.periodStartDate + ' ' + newSch.periodStartTime);
    const oldStart = moment(
        oldSch.periodStartDate + ' ' + oldSch.periodStartTime
    );

    const end = moment(newSch.periodEndDate + ' ' + newSch.periodEndTime);
    const oldEnd = moment(oldSch.periodEndDate + ' ' + oldSch.periodEndTime);

    const periodChangeFlag = !(start.isSame(oldStart) && end.isSame(oldEnd));

    // 繰返し（毎週・週月・周年）の場合
    let repeatEndChangeFlag = false;
    if (newSch.repeat && (newSch.repeatType !== REPEAT_TYPE.EVERY_DAY && newSch.repeatType !== REPEAT_TYPE.CUSTOM)) {
        repeatEndChangeFlag = newSch.repeatEndDate !== oldSch.repeatEndDate;
    }

    // 繰返し設定変更確認
    const repeatChangeFlag =
        newSch.repeat !== oldSch.repeatFlag ||
        newSch.repeatType !== oldSch.repeatType ||
        newSch.repeatOption1 !== oldSch.repeatOption1 ||
        newSch.repeatOption2.toString() !== oldSch.repeatOption2.toString() ||
        newSch.repeatOption3.toString() !== oldSch.repeatOption3.toString() ||
        newSch.repeatCustom.toString() !== oldSch.repeatCustom.toString();

    // その他（スケジュール名、音源、音量、色）変更確認
    const othersChangeFlag =
        newSch.scheduleName !== oldSch.scheduleName ||
        newSch.soundSource !== oldSch.soundSource ||
        newSch.volume !== oldSch.volume ||
        newSch.colorId !== oldSch.colorId;

    return {
        isGroupHeader,
        periodChangeFlag,
        repeatChangeFlag,
        repeatEndChangeFlag,
        othersChangeFlag,
    };
};
