import { ITEM_TYPES } from '../../../common/constants';
import { useDrag } from 'react-dnd';

/** サイドバースピーカーアイテム */
const SideBarSpeakerItem = (item) => {
    const { name, url, speakerTypeId } = item;
    const [, drag] = useDrag(
        () => ({
            // ドロップタイプ
            type: ITEM_TYPES.SIDEBAR_SPEAKER,
            // ドロップ情報
            item: { name, speakerTypeId },
            // ドロップ終了
            // end(item, monitor) {
            //   const dropResult = monitor.getDropResult();
            //   if (item && dropResult) {
            //     console.log(dropResult);
            //     console.log('dropEnd');
            //   }
            // },
        }),
        [name, speakerTypeId]
    );
    return (
        <div ref={drag}>
            <span>{name}</span>
            <img src={url} alt="sidebar_spk" />
        </div>
    );
};

export default SideBarSpeakerItem;
