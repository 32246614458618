import axios from 'axios';
import {
    API_DOMAIN,
    API_URL,
    REQUEST_QUERY,
    RESPONSE_STATUS,
} from './apiContants';

/**
 * スケジュール情報を取得する
 * @param {object} params リクエスト情報
 * @returns スケジュール情報
 */
export const getSchedules = async (params) => {
    const reqQuery = REQUEST_QUERY.GET_SCHEDULES.format(
        params.userId,
        params.floorId
    );
    // API呼び出し
    const response = await axios.get(
        API_DOMAIN + API_URL.GET_SCHEDULES + reqQuery
    );

    if (response.status === RESPONSE_STATUS.OK) {
        // 成功
        return response.data;
    } else {
        return {};
    }
};

/**
 * スケジュールを作成する
 * @param {object} params リクエスト情報
 * @returns 作成結果
 */
export const createSchedules = async (params) => {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.CREATE_SCHEDULE,
        params
    );

    if (response.status === RESPONSE_STATUS.OK) {
        // 成功
        return response.data;
    } else {
        return {};
    }
};

/**
 * スケジュールを更新する
 * @param {object} params リクエスト情報
 * @returns 更新結果
 */
export const updateSchedules = async (params) => {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.UPDATE_SCHEDULE,
        params
    );

    if (response.status === RESPONSE_STATUS.OK) {
        // 成功
        return response.data;
    } else {
        return {};
    }
};

/**
 * スケジュールを削除する
 * @param {object} params リクエスト情報
 * @returns 削除結果
 */
export const deleteSchedules = async (params) => {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.DELETE_SCHEDULE,
        params
    );

    if (response.status === RESPONSE_STATUS.OK) {
        // 成功
        return response.data;
    } else {
        return {};
    }
};
