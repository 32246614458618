import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import exclamation from '../../../img/exclamation.svg';

/**
 * メッセージ表示用（確認用）モーダル
 * モーダルのフッターはOKボタン（表示固定）とキャンセルボタン（表示非表示可変）
 */
class ModalMessage extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {string} props.bodyClass - ModalBody className
     * @param {string} props.title - モーダルタイトル(必須でない)
     * @param {string} props.content - メッセージ
     * @param {string} props.okString - OKボタン表示用文字列
     * @param {function} props.okProc - OKボタン押下時の処理
     * @param {boolean} props.isCancel - キャンセルボタン表示有無
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} モーダル表示有無
             */
            modal: false,
        };

        this.parser = require('html-react-parser');
    }

    /**
     * モーダル表示有無設定
     * @param {boolean} flag -モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = () => {
        this.props.okProc();
        this.close();
    };

    /**
     * キャンセルボタン押下時処理
     */
    onClickCancelHandler = () => {
        if (this.props.cancelProc !== undefined) {
            this.props.cancelProc();
        }

        this.close();
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const { bodyClass, title, content, okString, isCancel } = this.props;

        const bodyClassName = bodyClass !== undefined ? bodyClass : '';
        return (
            <Modal
                className="modal_commons"
                isOpen={this.state.modal}
                centered
                backdrop="static"
            >
                {/* モーダルヘッダー タイトル表示欄(タイトルは必須ではない) */}
                <ModalHeader toggle={this.onClickCancelHandler}>
                    {this.props.isExclamation ? (
                        <img
                            src={exclamation}
                            className="exclamation"
                            alt="exclamation"
                        />
                    ) : null}
                    {title}
                </ModalHeader>
                {/* モーダルボディ メッセージ表示欄 */}
                <ModalBody className={`${bodyClassName} px-5`}>
                    <Label for="modal_commons_content">
                        {this.parser(content)}
                    </Label>
                </ModalBody>
                {isCancel && (
                    // モーダルフッター（キャンセルボタンあり）
                    <ModalFooter className="with_cancel">
                        <Button
                            outline
                            color="success"
                            onClick={this.onClickCancelHandler}
                        >
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.onClickOkHandler}>
                            {okString}
                        </Button>
                    </ModalFooter>
                )}
                {!isCancel && (
                    // モーダルフッター（キャンセルボタンなし）
                    <ModalFooter className="without_cancel">
                        <Button
                            className="mx-auto w-75"
                            color="success"
                            onClick={this.onClickOkHandler}
                        >
                            {okString}
                        </Button>
                    </ModalFooter>
                )}
            </Modal>
        );
    }
}

export default ModalMessage;
