import { SESSION_STORAGE_KEY } from '../common/constants';

/**
 * String.format(...args)
 * 例：'a:{0}, b:{1}, c:{2}'.format('A', 'B', 'C')
 * 返し: 'a:A, b:B, c:C'
 *  */
export const useStringFormat = () => {
    if (!String.prototype.format) {
        // eslint-disable-next-line no-extend-native
        String.prototype.format = function () {
            var args = arguments;
            return this.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] === 'undefined' ||
                    args[number] === null
                    ? ''
                    : args[number];
            });
        };
    }
};

/**
 * 画面側で仮使用するIDを採番する
 * @param {array} array 採番を行うデータ配列
 * @returns 採番したID
 */
export const getNewId = (array) => {
    const ids = array.map(function (s) {
        return s.id;
    });

    let id = Math.min.apply(null, ids);
    if (id > 0) {
        id = -10;
    } else {
        id -= 1;
    }

    return id;
};

/**
 * データの存在チェックを行う
 * @param {Any} item
 * @returns true(存在する)、false(存在しない)
 */
export const isEmpty = (item) => {
    if (typeof item === 'undefined' || item === null) {
        return true;
    }

    if (typeof item === 'string') return item === '';
    if (Array.isArray(item)) return item.length === 0;

    return false;
};

/**
 * リクエストに付加するヘッダーの作成
 * @returns ヘッダー情報
 */
export function createHeaders() {
    const headers = {
        'Content-Type': 'application/json',
        'x-user-Access-Date': sessionStorage.getItem(
            SESSION_STORAGE_KEY.USER_ACCESS_DATE
        ),
    };

    return headers;
}
