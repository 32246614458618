import axios from 'axios';
import { API_DOMAIN, API_URL } from './apiContants';

// ユーザー情報取得API
export async function getUser(userId, password) {
    const requestBody = {
        userId: userId,
        password: password,
    };
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.GET_USER, requestBody)
        .then((result) => {
            // ステータスコードが200系の場合
            return result.data;
        })
        .catch(() => {
            // ステータスコードが200系以外の場合
            return null;
        });

    return response;
}
