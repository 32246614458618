import axios from 'axios';
import { API_DOMAIN, API_URL, REQUEST_QUERY } from './apiContants';

/**
 * スピーカー接続確認API呼び出し
 * @param {number} userId ログインID
 * @param {number} baseId 拠点ID
 * @param {number} baseSpeakerId 拠点スピーカーID
 * @returns レスポンスデータ
 */
export async function checkSpeakerConnection(userId, baseId, baseSpeakerId) {
    // API呼び出し
    const response = await axios
        .get(
            API_DOMAIN +
                API_URL.CHECK_SPEAKER_CONNECTION +
                REQUEST_QUERY.CHECK_SPEAKER_CONNECTION.format(
                    userId,
                    baseId,
                    baseSpeakerId
                )
        )
        .catch((err) => {
            return err.response.data;
        });
    return response.data;
}

/**
 * スピーカー再生API呼び出し
 * @param {object} data スピーカー情報
 * @returns レスポンスデータ
 */
export async function playSpeaker(data) {
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.PLAY_SPEAKER, data)
        .catch((err) => {
            return err.response;
        });
    return response;
}

/**
 * スピーカー再生停止API呼び出し
 * @param {object} data スピーカー情報
 * @returns レスポンスデータ
 */
export async function stopSpeaker(data) {
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.STOP_SPEAKER, data)
        .catch((err) => {
            return err.response;
        });
    return response;
}

/**
 * スピーカー設定(スピーカー機器に基準音量設定を送信)
 * @param {object} data スピーカー情報
 * @returns レスポンスデータ
 */
export async function settingSpeaker(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.SETTING_SPEAKER,
        data
    );
    return response.data;
}

/**
 * スピーカーミュート
 * @param {object} data リクエストパラメータ
 */
export async function muteSpeaker(data) {
    // API呼び出し
    const response = await axios.post(API_DOMAIN + API_URL.MUTE_SPEAKER, data);
    return response;
}

/**
 * スピーカーミュート解除
 * @param {object} data リクエストパラメータ
 */
export async function unmuteSpeaker(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.UNMUTE_SPEAKER,
        data
    );
    return response;
}

/**
 * スピーカー音量変更
 * @param {object} data リクエストパラメータ
 */
export async function changeSpeakerVolume(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.CHANGE_SPEAKER_VOLUME,
        data
    );
    return response;
}

// フロアのスピーカー状態取得
export async function getSpeakerStatus(floorId) {
    // API呼び出し
    const response = await axios.get(
        API_DOMAIN +
            API_URL.GET_SPEAKER_STATUS +
            REQUEST_QUERY.GET_SPEAKER_STATUS.format(floorId)
    );
    return response.data;
}

// スピーカーの音源リスト取得
export async function getSoundSources(data) {
    const response = await axios
        .get(
            API_DOMAIN +
                API_URL.GET_SPEAKER_SOUND_SOURCE +
                REQUEST_QUERY.GET_SPEAKER_SOUND_SOURCE.format(
                    data.speakerId,
                    data.userType
                )
        )
        .catch(() => {
            return { data: null };
        });

    return response.data;
}

/**
 * フロアに対するテスト再生を開始するAPIを呼び出す
 * @param {object} data data
 * @returns 実行結果
 */
export async function postStartTestPlayFloor(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.START_TEST_PLAY_FLOOR,
        data
    );

    return response;
}

/**
 * フロアに対するテスト再生を終了するAPIを呼び出す
 * @param {object} data data
 * @returns 実行結果
 */
export async function postStopTestPlayFloor(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.STOP_TEST_PLAY_FLOOR,
        data
    );

    return response;
}
