import React from 'react';
import { Navbar, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import DISP_RESOURCE from '../common/dispResource';
import { MODAL, MODE } from '../common/constants';

/**
 * 共通ヘッダー
 */
class TopBar extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {string} props.floorName - フロア名
     * @param {string} props.pageType - 画面
     * @param {string} props.mode - 画面の状態
     * @param {boolean} props.isOperating - 運用モードフラグ
     * @param {boolean} props.disableChangeMode - 運用モード切替ボタン制御
     * @param {function} props.setMode - 画面状態の設定処理
     * @param {function} props.modalOpen - モーダル表示処理
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * トップバー制御フラグ
             * @type {boolean}
             */
            topNavCollaps: false,
        };
    }

    /**
     * state更新(setState)
     * @param {boolean} topNavCollaps - トップバー制御フラグ
     */
    setTopNavCollaps = (topNavCollaps) => {
        this.setState({ topNavCollaps: topNavCollaps });
    };
    /**
     * トップバー制御
     */
    toggleTopNav = () => {
        this.setTopNavCollaps(!this.state.topNavCollaps);
    };

    /**
     * 画面描画
     * @returns {React.ReactElement}
     */
    render() {
        const {
            pageType,
            mode,
            setMode,
            isOperating,
            disableChangeMode,
            openChangeOperation,
            modalOpen,
        } = this.props;
        return (
            <Navbar className="top_bar">
                <img
                    className="top_logo"
                    alt="logo"
                    src={require('../img/logo.svg').default}
                />
                <Collapse isOpen={!this.state.topNavCollaps} navbar>
                    {pageType === 'SETTING' && (
                        <Nav className="topbar_menu">
                            {isOperating && (
                                <>
                                    <NavItem>
                                        <NavLink
                                            disabled={
                                                mode === MODE.SET_SCHEDULES
                                            }
                                            onClick={() =>
                                                setMode(MODE.SET_SCHEDULES)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_SCHEDULE_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={mode === MODE.VIEW_LOG}
                                            onClick={() =>
                                                setMode(MODE.VIEW_LOG)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_LOG_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() =>
                                                disableChangeMode
                                                    ? null
                                                    : modalOpen(
                                                          MODAL.CHANGE_OPEARTION
                                                      )
                                            }
                                        >
                                            <img
                                                alt="setting_mode"
                                                src={
                                                    require('../img/setting.svg')
                                                        .default
                                                }
                                            />{' '}
                                            {DISP_RESOURCE.TOP_SETTING_MODE}
                                        </NavLink>
                                    </NavItem>
                                </>
                            )}
                            {!isOperating && (
                                <>
                                    <NavItem>
                                        <NavLink
                                            disabled={mode === MODE.SET_FLOOR}
                                            onClick={() =>
                                                setMode(MODE.SET_FLOOR)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_FLOOR_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={
                                                mode === MODE.SET_SPEAKERS
                                            }
                                            onClick={() =>
                                                setMode(MODE.SET_SPEAKERS)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_SPEAKER_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={mode === MODE.SET_GROUPS}
                                            onClick={() =>
                                                setMode(MODE.SET_GROUPS)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_GROUP_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            disabled={mode === MODE.VIEW_LOG}
                                            onClick={() =>
                                                setMode(MODE.VIEW_LOG)
                                            }
                                        >
                                            {DISP_RESOURCE.TOP_LOG_SETTING}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            onClick={() =>
                                                disableChangeMode
                                                    ? null
                                                    : openChangeOperation()
                                            }
                                        >
                                            <img
                                                alt="operation_mode"
                                                src={
                                                    require('../img/operation.svg')
                                                        .default
                                                }
                                                width={35}
                                            />{' '}
                                            {DISP_RESOURCE.TOP_OPERATION_MODE}
                                        </NavLink>
                                    </NavItem>
                                </>
                            )}
                        </Nav>
                    )}
                </Collapse>
            </Navbar>
        );
    }
}

export default TopBar;
