import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Timeline, {
    DateHeader,
    TimelineHeaders,
    TimelineMarkers,
    TodayMarker,
} from 'react-calendar-timeline';
import DISP_RESOURCE from '../../../common/dispResource';
import moment from 'moment';
import 'moment/locale/ja';
import {
    SCHEDULE_COLORS,
    SCHEDULE_VISIBLE_TYPE,
} from '../../../common/constants';

moment.locale('ja');

/**
 * スケジューラー本体
 * @param {object}   props             - コンポーネント呼び出し側から渡される値
 * @param {object[]} props.rows        - スピーカ（スケジュールバーの行）
 * @param {object[]} props.schedules   - スケジュール
 * @param {number}   props.visibleHour - スケジューラー表示時間(H)
 * @param {object}   props.visibleTime - スケジューラー表示時刻
 * @param {string}   props.visibleType - スケジューラー表示タイプ
 */
export default class CustomTimeline extends React.Component {
    /**
     * スケジューラー表示開始時間取得
     * @returns {number} 表示開始時間（ミリ秒）
     */
    visibleTimeStart = () => {
        const { visibleHour, visibleTime, visibleType } = this.props;

        const time = moment(visibleTime).set('hour', visibleHour);

        switch (visibleType) {
            case SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS:
                // 12時間
                return time.startOf('hour').valueOf();
            case SCHEDULE_VISIBLE_TYPE.ONE_DAY:
                // 1日
                return time.startOf('day').valueOf();
            case SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS:
                // 7日
                return time.startOf('day').valueOf();
            case SCHEDULE_VISIBLE_TYPE.ONE_WEEK:
                return time.startOf('week').valueOf();
            default:
                return time.startOf('hour').valueOf();
        }
    };

    /**
     * スケジューラー表示終了時間取得
     * @returns {number} 表示終了時間（ミリ秒）
     */
    visibleTimeEnd = () => {
        const { visibleHour, visibleTime, visibleType } = this.props;

        const time = moment(visibleTime).set('hour', visibleHour);

        switch (visibleType) {
            case SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS:
                // 12時間
                return time.startOf('hour').add(12, 'hour').valueOf();
            case SCHEDULE_VISIBLE_TYPE.ONE_DAY:
                // 1日
                return time.endOf('day').valueOf();
            case SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS:
                // 7日
                return time.startOf('day').add(7, 'day').valueOf();
            case SCHEDULE_VISIBLE_TYPE.ONE_WEEK:
                return time.endOf('week').valueOf();
            default:
                return time.startOf('hour').add(12, 'hour').valueOf();
        }
    };

    /**
     * スケジュールホバー時のツールチップ用日付文言取得
     * @param {object} schedule
     * @returns {string}
     */
    getDateString = (schedule) => {
        const start = moment(schedule.start);
        const end = moment(schedule.end);

        if (end.format('DD') !== start.format('DD')) {
            // スケジュールの開始日と終了日が異なる場合
            return (
                start.format('M/D(ddd) H:mm-') +
                end.format('M/D(ddd) H:mm') +
                (schedule.repeat ? '(' + DISP_RESOURCE.REPEAT + ')' : '')
            );
        } else {
            return (
                start.format('M/D(ddd) H:mm-') +
                end.format('H:mm') +
                (schedule.repeat ? '(' + DISP_RESOURCE.REPEAT + ')' : '')
            );
        }
    };

    /**
     * スケジュール表示の左サイドバー「スピーカー名枠」のレンダリング（行）
     * @param {object} group 行（スピーカー）の情報
     * @returns
     */
    groupRenderer = ({ group }) => {
        return (
            <div title={group.speakerName} className="scheduler_group_header">
                <span className="scheduler_group_name">
                    {group.speakerName}
                </span>
            </div>
        );
    };

    /**
     * スケジュール
     * @param {object} item           スケジュール
     * @param {object} itemContext    表示状態情報
     * @param {function} getItemProps ルート要素に適用するProps
     * @returns
     */
    itemRenderer = ({ item, itemContext, getItemProps }) => {
        const { showSettings } = this.props;
        const scheduleColor = SCHEDULE_COLORS.find(
            (color) => color.id === item.colorId
        );

        return (
            <div
                {...getItemProps({
                    style: {
                        background: scheduleColor.hex,
                        border: `1px solid ${scheduleColor.hex}`,
                        borderRadius: 8,
                        boxShadow: '2px 1px 4px #AAA',
                        zIndex: 0,
                    },
                    onMouseDown: () => {
                        showSettings(item);
                    },
                })}
            >
                <div
                    id={'ScheduleUpdateTooltip_' + item.id}
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        paddingLeft: 10,
                        textAlign: 'left',
                        color: '#FFF',
                        fontSize: 20,
                    }}
                >
                    <b>{item.scheduleName}</b>
                    <UncontrolledTooltip
                        fade={false}
                        placement="top"
                        target={'ScheduleUpdateTooltip_' + item.id}
                        trigger="hover"
                    >
                        {DISP_RESOURCE.SCHEDULE_TOOLTIP_TITLE}
                        {item.scheduleName}
                        <br />
                        {DISP_RESOURCE.SCHEDULE_TOOLTIP_DATETIME}
                        {this.getDateString(item)}
                        <br />
                        {DISP_RESOURCE.SCHEDULE_TOOLTIP_SOUNDSOURCE}
                        {item.soundSourceName}
                    </UncontrolledTooltip>
                </div>
            </div>
        );
    };

    render() {
        const { rows, schedules, visibleType, showSettings } = this.props;

        // スピーカー（行）無しの場合、表示エラー防止のため、空1行を入れる
        const scheduleRows =
            rows.length > 0
                ? rows
                : [{ speakerId: '', speakerName: '', speakerIds: [] }];

        // スケジューラー内部データ紐づけキー
        const keys = {
            groupIdKey: 'speakerId', // 行のIDパラメータ名
            groupTitleKey: 'speakerName', // 行のtitleタグ
            groupLabelKey: 'speakerName',
            groupDivTitleKey: 'speakerName',
            groupRightTitleKey: 'none',
            itemIdKey: 'id', // スケジュールのキー（連番）
            itemTitleKey: 'scheduleName',
            itemDivTitleKey: 'none',
            itemGroupKey: 'speakerId', // 行に紐づくパラメータ名
            itemTimeStartKey: 'start', // スケジュール開始時間（ミリ秒）のパラメータ名
            itemTimeEndKey: 'end', // スケジュール終了時間（ミリ秒）のパラメータ名
        };

        return (
            <div className="scheduler_main_scroll_div">
                <div className="scheduler_main">
                    <Timeline
                        groups={scheduleRows} // 行
                        items={schedules} // スケジュール
                        keys={keys} // 行、スケジュールの紐づけキー情報
                        buffer={2} // 表領域の長さ（表示の倍数
                        stackItems={false} // 行ごと1行で固定
                        itemHeightRatio={0.85} // スケジュールアイテムの太さ
                        canMove={false} // マウス移動（マウス操作）
                        canResize={false} // リサイズ（マウス操作）
                        visibleTimeStart={this.visibleTimeStart()} // 表示開始時間
                        visibleTimeEnd={this.visibleTimeEnd()} // 表示終了時間
                        sidebarWidth={250} // 左サイドバーの幅
                        onCanvasClick={() => showSettings()} // 空の領域クリック時の処理
                        itemRenderer={this.itemRenderer} // スケジュールアイテムの要素
                        groupRenderer={this.groupRenderer} // 左サイドバーの要素
                        lineHeight={50} // 行の高さ(px)
                        style={{ height: rows.length * 50 + 100 }} // スケジュールの表示領域の高さ調節
                    >
                        <TimelineMarkers>
                            <TodayMarker>
                                {({ styles }) => (
                                    <div
                                        className="today_marker"
                                        style={{
                                            ...styles,
                                            background: '#5fc7bc',
                                        }}
                                    />
                                )}
                            </TodayMarker>
                        </TimelineMarkers>
                        <TimelineHeaders
                            calendarHeaderClassName="scheduler_date_header"
                            className="scheduler_root_header"
                        >
                            {/* 12時間 表示用ヘッダー */}
                            {visibleType ===
                                SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS && (
                                <DateHeader
                                    className="schedule_12hour_header"
                                    height={50}
                                    labelFormat={'HH:mm'}
                                    unit="hour"
                                />
                            )}
                            {/* 24時間 表示用ヘッダー */}
                            {visibleType === SCHEDULE_VISIBLE_TYPE.ONE_DAY && (
                                <DateHeader
                                    className="schedule_24hour_header"
                                    height={50}
                                    labelFormat={'HH:mm'}
                                    unit="hour"
                                />
                            )}
                            {/* 7日、1週間 表示用ヘッダー */}
                            {(visibleType ===
                                SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS ||
                                visibleType ===
                                    SCHEDULE_VISIBLE_TYPE.ONE_WEEK) && (
                                <>
                                    <DateHeader
                                        className="schedule_day_header"
                                        height={45}
                                        labelFormat={
                                            DISP_RESOURCE.SCHEDULE_DAY_LABEL_FORMAT
                                        }
                                        unit="day"
                                    />
                                    <DateHeader
                                        className="schedule_time_marking"
                                        height={5}
                                        labelFormat={' '}
                                        unit="hour"
                                    />
                                </>
                            )}
                        </TimelineHeaders>
                    </Timeline>
                </div>
            </div>
        );
    }
}
