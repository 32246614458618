import React, { createRef } from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Input } from 'reactstrap';
import { CronJob as cron } from 'cron';
import {
    MODE,
    MODAL,
    REAL_WIDTH,
    PAGE_URL,
    BASE_SPEAKER,
    EXTERNAL_FILE_CONTENTS as EX_FILE,
    SESSION_STORAGE_KEY,
    CREATE_LOG_IDENTIFIER,
    OFFICE_CLIENT_MODE,
    SPEAKER_STATUS,
    POLLING_PATTERN,
    OFFICE_USER_TYPE,
    COOKIE_KEY,
} from '../common/constants';
import DISP_RESOURCE from '../common/dispResource';
import * as Utils from '../common/utils';
import parser from 'html-react-parser';

import TopBar from './TopBar';
import SideBar from './area_setting/SideBar';
import Area from './area_setting/Area';
import Scheduler from './area_setting/Scheduler';
import ModalSaveFloor from './area_setting/modal/ModalSaveFloor';
import ModalNewFloor from './area_setting/modal/ModalNewFloor';
import ModalLoadFloor from './area_setting/modal/ModalLoadFloor';
import ModalSpeaker from './area_setting/modal/ModalSpeaker';
import ModalMessage from './area_setting/modal/ModalMessage';
import ModalOperationMode from './area_setting/modal/ModalOperationMode';
import ModalChoice from './area_setting/modal/ModalChoice';
import { CHOICE_MODAL_OPT } from '../common/constants';

import Log from './area_setting/Log';
import SpeakerList from './area_setting/scheduler/SpeakerList';

import {
    confirmChangedBaseInfo,
    loadFloor,
    saveFloor as saveFloorApi,
    changeFloorMode,
} from '../api/areaSettingApi';
import * as SPEAKER_API from '../api/speakerApi';
import * as LOG_API from '../api/logApi';

/**
 * フロアマップ設定画面
 */
class AreaSetting extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);

        // セッションストレージからユーザーID、拠点IDを取得。いずれかが取得出来ない場合はログイン画面に遷移。
        if (
            !sessionStorage.getItem(SESSION_STORAGE_KEY.USER_ID) ||
            !sessionStorage.getItem(SESSION_STORAGE_KEY.BASE_ID)
        ) {
            let link = document.createElement('a');
            link.href = PAGE_URL.URL;
            link.click();
            return;
        }

        // Cookieから読み込みフロア情報とそのフロアが所属する拠点IDを取得する
        let floorId = null;
        let floorJoinedBaseId = null;
        const cookies = document.cookie.split(';');
        cookies.forEach(function(value) {
            const contents = value.trim().split('=');

            switch(contents[0]) {
                case COOKIE_KEY.LOAD_FLOOR_ID:
                    floorId = contents[1];
                    break;
                case COOKIE_KEY.FLOOR_JOINED_BASE_ID:
                    floorJoinedBaseId = contents[1];
                    break;
                default:
                    break;
            }
        });

        // セッションストレージのログイン情報と読み込みフロア情報が一致しない場合、cookie情報を削除する
        if (floorId !== null && floorJoinedBaseId !== sessionStorage.getItem(SESSION_STORAGE_KEY.BASE_ID)) {
            floorId = null;
            document.cookie = COOKIE_KEY.LOAD_FLOOR_ID + '=; max-age=0';
            document.cookie = COOKIE_KEY.FLOOR_JOINED_BASE_ID + '=; max-age=0';
        }

        this.state = {
            /**
             * ユーザーID
             * @type {String}
             */
            userId: sessionStorage.getItem(SESSION_STORAGE_KEY.USER_ID),
            /**
             * 拠点ID
             */
            baseId: sessionStorage.getItem(SESSION_STORAGE_KEY.BASE_ID),
            /**
             * 運用モード表示切替フラグ
             * @type {boolean}
             */
            isOperating: false,
            /**
             * 画面の状態
             * @type {string}
             */
            mode: MODE.SET_FLOOR,
            /**
             * フロアID
             */
            floorId: floorId,
            /**
             * 拠点名
             * @type {string}
             */
            floorName: '',
            /**
             * エリアマップ
             * @type {string}
             */
            areaImage: null,
            /**
             * マップ横方向の実サイズ
             * @type {number}
             */
            realWidth: REAL_WIDTH.DEFAULT,
            /**
             * マップ配置済みスピーカー
             * @param {object[]}
             */
            speakers: [
                /*
                { 
                id: number,             // ID
                groupId: number,        // グループID number
                left: number,           // icon表示位置（横）
                right: number,          // icon表示位置（縦）
                deviceType: string,     // IP機器タイプ constants.SPEAKER_TYPE IP
                status: string,         // constants.SPEAKER_STATUS_TYPE
                name: string,           // スピーカー名
                ipAddress: string       // IPアドレス
                volume:                 // 基準音量設定(constants?)
                testFlag: boolean       // テスト再生
                degree:                 // 設定角度(constants?)
                qrcode: string          // qrcode
                isMute: boolean         // ミュート
                isFailure: boolean      // 故障
                scheduleVolume: number  // 現在音量
                }
                */
            ],
            /**
             * グループリスト
             * @type {object[]}
             */
            groups: [
                /**
                 * id: number,            // グループID
                 * name: string,          // グループ名
                 */
            ],
            /**
             * 拠点情報のスピーカーリスト
             */
            baseSpeakers: [
                /**
                 * baseSpeakerId: number,
                 * baseId: number,
                 * unitName: String,
                 * unitTypeId: number,
                 * speakerTypeId: number,
                 * location: String,
                 * ipAddress: String,
                 * port: number,
                 * isUse: boolean,
                 */
            ],
            /**
             * 選択中のスピーカーID
             * @type {number[]}
             */
            currentSpeaker: [],
            /**
             * 選択中のグループID
             * @type {number}
             */
            currentGroup: null,
            /**
             * 削除ボダン表示フラグ
             * @type {boolean}
             */
            currentDelGroup: null,
            /**
             * 選択されたグループ解除対象スピーカーID
             */
            selectedUngroupSpeakerId: null,
            /**
             * スケジューラ表示・選択用フラグ
             * @type {number[]}
             */
            schedulTargets: [],
            /**
             * モーダル表示有無（新規フロアマップ読込）
             * @type {boolean}
             */
            modalNewFloorFlag: false,
            /**
             * 保存状態フラグ
             * 初期表示時や保存ボタン押下時は保存済み状態となり、スピーカーの設定やグループ設定をすると未保存状態になる
             * @type {boolean}
             */
            savedFlag: true,
            /**
             * 音圧表示フラグ
             * @type {boolean}
             */
            soundPressureFlag: false,

            /**
             * スピーカーリスト表示フラグ
             * @type {boolean}
             */
            speakerListFlag: false,
            /**
             * グループ追加予定スピーカーが属しているグループIDリスト
             */
            otherGroupSpeakerIds: [],
            /**
             * フロア情報を保存して運用モードに移行するかのフラグ
             */
            isChangeOperation: false,
            /**
             * メッセージモーダル表示用の文字列
             */
            displayMessage: '',
        };

        /**
         * 選択グループにスピーカー追加するためのリスト
         * @type {object[]}
         */
        this.remainGroupSpeaker = [];

        // コンポネント参照
        this.SideBar = createRef();
        this.Scheduler = createRef();
        this.SpeakerList = createRef();

        // モーダル参照
        this[MODAL.SAVE_FLOOR] = createRef();
        this[MODAL.DETAIL_SPEAKER] = createRef();
        this[MODAL.LOAD_FLOOR] = createRef();
        this[MODAL.DELETE_FLOOR] = createRef();
        this[MODAL.DISPLAY_MESSAGE] = createRef();
        this[MODAL.CHECK_GROUP] = createRef();
        this[MODAL.DELETE_GROUP] = createRef();
        this[MODAL.UNGROUP_SPEAKER] = createRef();
        this[MODAL.CHANGE_OPEARTION] = createRef();
        this[MODAL.CHOICE_SAVE_FLOOR] = createRef();

        // ポーリング用のCronジョブ
        this.pollingJob = new cron({
            cronTime: POLLING_PATTERN,
            onTick: this.reflectSpeakerStatus.bind(this),
            start: false,
        });

        // フロア読み込み済みの場合、自動でロード
        if (this.state.floorId != null && this.state.floorId > 0) {
            this.autoChangeOperationMode();
        }
    }

    componentWillUnmount() {
        if (this.pollingTimer !== null) {
            clearInterval(this.pollingTimer);
            this.pollingTimer = null;
        }
    }
    /** ----------------------state更新(setState)---------------------- */
    setOperationFlag = (isOperating) => {
        this.setState({ isOperating });
    };

    setFloorName = (floorName) => {
        if (
            this.state.mode === MODE.SET_FLOOR &&
            this.state.areaImage !== null &&
            floorName !== ''
        ) {
            this.setState({ floorName: floorName, mode: MODE.SET_SPEAKERS });
        } else {
            this.setState({ floorName: floorName });
        }
    };
    setAreaImage = (areaImage) => {
        this.setState({ areaImage: areaImage });
    };

    setRealWidth = (event) => {
        if (event.target.value > REAL_WIDTH.MAX) {
            this.setState({ realWidth: REAL_WIDTH.MAX });
        } else {
            this.setState({ realWidth: event.target.value });
        }
        this.setState({ savedFlag: false });
    };
    setSpeakers = (speakers) => {
        this.setState({ speakers: speakers });
    };
    // スピーカー選択
    selectSpeaker = (id) => {
        if (this.state.currentGroup === null) {
            // グループ未選択時何もしない
            return;
        }
        if (id === null) {
            // スピーカー選択解除
            this.setState({ currentSpeaker: [] });
            return;
        }

        const idx = this.state.currentSpeaker.findIndex(
            (speakerId) => speakerId === id
        );
        if (idx !== -1) {
            // 選択済みの場合、選択解除
            this.setState({
                currentSpeaker: this.state.currentSpeaker.filter(
                    (speakerId) => speakerId !== id
                ),
            });
        } else {
            const speaker = this.state.speakers.find(
                (speaker) => speaker.id === id
            );
            if (speaker.groupId !== this.state.currentGroup) {
                // 選択中のグループに所属していないスピーカーの場合のみ、選択スピーカーに追加
                this.setState({
                    currentSpeaker: [...this.state.currentSpeaker, id],
                });
            }
        }
    };
    setGroups = (groups) => {
        this.setState({ groups: groups });
    };
    selectGroup = (id) => {
        if (this.state.currentGroup === id) {
            // 同じグループを押下すると閉じる
            this.setState({ currentGroup: null });
        } else {
            this.setState({ currentGroup: id });
        }
        // 選択中のスピーカー、削除グループのクリア
        this.setState({ currentSpeaker: [] });
        this.selectDelGroup(null);
    };
    selectDelGroup = (id) => {
        this.setState({ currentDelGroup: id });
    };

    setSelectedUngroupSpeakerId = (id) => {
        this.setState({ selectedUngroupSpeakerId: id });
    }

    setSchedulTargets = (speakers) => {
        this.setState({ schedulTargets: speakers });
    };

    setModalNewFloor = () => {
        this.setState({ modalNewFloorFlag: !this.state.modalNewFloorFlag });
    };

    setSavedFlag = (flag) => {
        this.setState({ savedFlag: flag });
    };
    setRemainGroupSpeaker = (selSpeakers) => {
        this.remainGroupSpeaker = selSpeakers;
    };
    setOtherGroupSpeakerIds = (groupSpeakerIds) => {
        this.setState({ otherGroupSpeakerIds: groupSpeakerIds });
    };

    setSoundPressureFlag = (flag) => {
        this.setState({ soundPressureFlag: flag });
        document.cookie = COOKIE_KEY.SOUND_PRESSURE + '=' + flag.toString() + "; max-age=31536000";
    };

    setDisplayMessage = (displayMessage) => {
        this.setState({ displayMessage });
    };

    setMute = async (isMute) => {
        const { userId, floorId, baseId, speakers, schedulTargets } =
            this.state;

        let spks = [...speakers];

        if (isMute) {
            // mute API
            for (const speakerId of schedulTargets) {
                const params = {
                    speakerId,
                    stopTime: null,
                    userType: OFFICE_USER_TYPE.ADMIN,
                    userId,
                };

                const response = await SPEAKER_API.muteSpeaker(params);

                const spk = spks.find((spk) => spk.id === speakerId);

                if (response.status === 200) {
                    spk.isMute = true;
                }

                // ログ登録
                const result = response.status === 200 ? true : false;
                const logData = {
                    baseId: baseId,
                    speakerId: spk.id,
                    identifier: CREATE_LOG_IDENTIFIER.MUTE_ON,
                    result: result,
                    data: '-',
                };
                await LOG_API.postCreateManagerLog(logData);
            }
        } else {
            // unmute API
            const params = {
                userId,
                floorId,
            };

            const response = await SPEAKER_API.unmuteSpeaker(params);

            if (response.status === 200) {
                for (const speaker of speakers) {
                    const spk = spks.find((spk) => spk.id === speaker.id);

                    if (spk.isMute) {
                        // ログ登録
                        const logData = {
                            baseId: baseId,
                            speakerId: spk.id,
                            identifier: CREATE_LOG_IDENTIFIER.MUTE_OFF,
                            result: true,
                            data: '-',
                        };
                        await LOG_API.postCreateManagerLog(logData);
                    }
                    spk.isMute = false;
                }
            } else {
                for (const speaker of speakers) {
                    const spk = spks.find((spk) => spk.id === speaker.id);

                    if (spk.isMute) {
                        // ログ登録
                        const logData = {
                            baseId: baseId,
                            speakerId: spk.id,
                            identifier: CREATE_LOG_IDENTIFIER.MUTE_OFF,
                            result: false,
                            data: '-',
                        };
                        await LOG_API.postCreateManagerLog(logData);
                    }
                }
            }
        }
        this.setSpeakers(spks);
    };

    /**
     * スピーカー音量増加
     * @param {number} speakerId
     * @param {number} volume
     * @returns
     */
    incrementSpeakerVolume = async (speakerId, volume) => {
        const { userId, baseId } = this.state;
        if (volume >= EX_FILE.SCHEDULE_MAX_VOLUME) return;

        const updateVolume =
            volume + 3 >= EX_FILE.SCHEDULE_MAX_VOLUME
                ? EX_FILE.SCHEDULE_MAX_VOLUME
                : volume + 3;
        const params = {
            speakerId,
            volume: updateVolume,
            userId,
            userType: OFFICE_USER_TYPE.ADMIN,
        };

        const response = await SPEAKER_API.changeSpeakerVolume(params);

        // ログ登録
        const result = response.status === 200 ? true : false;
        const logData = {
            baseId,
            speakerId: speakerId,
            identifier: CREATE_LOG_IDENTIFIER.SOUND_SETTING,
            result,
            data: updateVolume,
        };
        await LOG_API.postCreateManagerLog(logData);

        this.updateSpeaker({ id: speakerId, volume: updateVolume });
    };

    /**
     * スピーカー音量減少
     * @param {number} speakerId
     * @param {number} volume
     * @returns
     */
    decrementSpeakerVolume = async (speakerId, volume) => {
        const { userId, baseId } = this.state;
        if (volume <= EX_FILE.SCHEDULE_MIN_VOLUME) return;

        const updateVolume =
            volume - 3 <= EX_FILE.SCHEDULE_MIN_VOLUME
                ? EX_FILE.SCHEDULE_MIN_VOLUME
                : volume - 3;
        const params = {
            speakerId,
            volume: updateVolume,
            userId,
            userType: OFFICE_USER_TYPE.ADMIN,
        };

        const response = await SPEAKER_API.changeSpeakerVolume(params);

        const result = response.status === 200 ? true : false;
        // ログ登録
        const logData = {
            baseId,
            speakerId: speakerId,
            identifier: CREATE_LOG_IDENTIFIER.SOUND_SETTING,
            result,
            data: updateVolume,
        };
        await LOG_API.postCreateManagerLog(logData);

        this.updateSpeaker({ id: speakerId, volume: updateVolume });
    };

    /**
     * 全選択を解除する
     */
    resetStateForChangeMode = () => {
        const targetStates = {
            currentSpeaker: null,
            currentGroup: null,
            currentDelGroup: null,
            schedulTargets: [],
            soundPressureFlag: false,
            speakerListFlag: false,
            isChangeOperation: false,
        };

        this.setState(targetStates);
    };

    toggleSpeakerListFlag = (flag) => {
        this.setState({ speakerListFlag: flag });
    };

    /**
     * フロア情報の保存処理
     * @param {String | undefined} floorName - フロア名(新規フロア保存時のみ使用)
     */
    saveFloor = async (floorName = undefined) => {
        const floorData = {
            floorInfo: {
                userId: this.state.userId,
                baseId: this.state.baseId,
                floorId: this.state.floorId,
                floorName: floorName ?? this.state.floorName,
                floorMap: this.state.areaImage,
                mapRealWidth: this.state.realWidth,
                speakerInfo: this.state.speakers,
                speakerGroupInfo: this.state.groups,
            },
        };

        // フロアマップ情報保存API、設定したフロア情報を保存
        const response = await saveFloorApi(floorData);

        // 保存済み状態にする
        this.setSavedFlag(true);

        return response;
    };

    /**
     * QRコードダウンロード前にフロア情報をセーブする
     * @param {*} changedSpeaker 
     * @returns 
     */
    saveFloorAndQrDownload = async (changedSpeaker) => {
        // このタイミングではプロパティのスピーカー情報リストが更新されていないので最新のスピーカー情報リストを作成する
        let speakers = JSON.parse(JSON.stringify(this.state.speakers));
        for (var s of speakers) {
            if (s.id === changedSpeaker.id) {
                s.selectSpeakerId = changedSpeaker.selectSpeakerId;
                s.ipAddress = changedSpeaker.ipAddress;
                s.name = changedSpeaker.name;
                s.deviceType = changedSpeaker.deviceType;
                s.isPrivateRoom = changedSpeaker.isPrivateRoom;
                s.basicVolume = changedSpeaker.basicVolume;
                s.degree = changedSpeaker.degree;
                break;
            }
        }

        const floorData = {
            floorInfo: {
                userId: this.state.userId,
                baseId: this.state.baseId,
                floorId: this.state.floorId,
                floorName: this.state.floorName,
                floorMap: this.state.areaImage,
                mapRealWidth: this.state.realWidth,
                speakerInfo: speakers,
                speakerGroupInfo: this.state.groups,
            }
        };

        // フロアマップ情報保存API、設定したフロア情報を保存
        try {
            await saveFloorApi(floorData);
            await this.reloadFloor(this.state.mode, false);

            // 保存済み状態にする
            this.setSavedFlag(true);
        } catch(e) {
            this.setDisplayMessage(DISP_RESOURCE.SAVE_FAILED);
            this.modalOpen(MODAL.DISPLAY_MESSAGE);

            return null;
        }
    }

    /**
     * フロア再呼び込み
     * @param {number | undefined} paramFloorId - フロアID（新規フロアマップ保存時のみ使用）
     * @returns
     */
    reloadFloor = async (mode, afterLoad, paramFloorId = undefined) => {
        const { userId } = this.state;
        const floorId = paramFloorId ?? this.state.floorId;

        // フロア情報保存時はJSONファイル読み込み
        const result = await loadFloor(userId, floorId);
        if (!result || !result.floorInfo) {
            return;
        }

        if (afterLoad) {
            mode = result.floorInfo.isOperation ? MODE.SET_SCHEDULES : MODE.SET_SPEAKERS
        }

        const jsonData = {
            floorId: result.floorInfo.floorId,
            floorName: result.floorInfo.floorName,
            areaImage: result.floorInfo.floorMap,
            realWidth: result.floorInfo.mapRealWidth,
            isOperating: result.floorInfo.isOperation,
            baseSpeakers: result.floorInfo.baseSpeakerInfo ?? [],
            speakers: result.floorInfo.speakerInfo ?? [],
            groups: result.floorInfo.speakerGroupInfo ?? [],
            mode: mode,
            savedFlag: true,
        };

        this.settingAreaInfo(jsonData);
        
        if (mode == MODE.SET_SCHEDULES) {
            if (this.pollingJob)
            // ポーリング用タスクの開始
            this.pollingJob.start();
        } else {
            // ポーリング用タスクの終了
            this.pollingJob.stop();
        }
    };

    /**
     * 運用モードから設定モード切替時、
     * 拠点情報CSVとシステム内で保持する拠点情報を比較
     * @returns
     */
    checkFloor = async () => {
        const result = await confirmChangedBaseInfo(this.state.userId);
        return result;
    };
    /** ----------------------スピーカー配置制御---------------------- */
    /**
     * 新規スピーカー追加
     * @param {object} data - スピーカー情報
     */
    createSpeaker = (data) => {
        // id採番（スピーカー配列の最大値の「id」+ 1）
        const id = Utils.getNewId(this.state.speakers);
        const inputs = { id, name: 'speaker', ...data };
        // 新規スピーカーをリストに追加
        this.setSpeakers(
            update(this.state.speakers, {
                $push: [inputs],
            })
        );
        // 未保存状態にする
        this.setSavedFlag(false);

        // スピーカー設定画面表示
        this.modalOpen(MODAL.DETAIL_SPEAKER, {
            arrayName: 'speakers',
            id: id,
            isFirst: true,
        });
    };

    /**
     * 既存スピーカー内容更新（スピーカー移動時も含む）
     * @param {object} data - スピーカー情報
     */
    updateSpeaker = (data) => {
        // スピーカーリストから設定変更を行ったスピーカーを検索
        const idx = this.state.speakers.findIndex(
            (speaker) => speaker.id === data.id
        );
        // 該当のスピーカー情報を更新
        this.setSpeakers(
            update(this.state.speakers, {
                [idx]: {
                    $merge: { ...data },
                },
            })
        );
        // 未保存状態にする
        this.setSavedFlag(false);
    };

    /**
     * 既存スピーカー削除
     * @param {number} id - フロアマップ情報に紐づいているスピーカーID
     */
    deleteSpeaker = (id) => {
        const idx = this.state.speakers.findIndex(
            (speaker) => speaker.id === id
        );
        if (id >= 0) {
            // DBに登録済みのスピーカーは削除フラグを切り替え
            this.setSpeakers(
                update(this.state.speakers, {
                    [idx]: {
                        $merge: { isDelete: true },
                    },
                })
            );
        } else if (id < -1) {
            // DB未登録のスピーカーは削除
            this.setSpeakers(
                update(this.state.speakers, {
                    $splice: [[idx, 1]],
                })
            );
        }
        this.selectSpeaker(null);
        // 未保存状態にする
        this.setSavedFlag(false);
    };

    /**
     * スピーカー機器の選択重複チェックを行う
     * （スピーカー詳細設定画面で選択したスピーカー機器が、他のスピーカーで既に設定されているか確認する）
     * @param {string} selectSpeakerId スピーカー機器名
     * @param {number} excludeId - 除外するスピーカーID(スピーカー詳細設定画面で表示中のスピーカー)
     * @return {boolean} 重複している場合true, していない場合false
     */
    checkDuplicateSpeaker = (selectSpeakerId, excludeId) => {
        const baseSpeakerIdx = this.state.baseSpeakers.findIndex(
            (baseSpeaker) => baseSpeaker.baseSpeakerId == selectSpeakerId
        );
        if (this.state.baseSpeakers[baseSpeakerIdx][BASE_SPEAKER.IS_USE]) {
            // 拠点スピーカーが別のフロアで既に使用されている場合(拠点スピーカー情報のisUseで確認)
            return true;
        }

        // 選択したスピーカー機器が設定されているスピーカーのインデックスを検索
        const idx = this.state.speakers.findIndex(
            (speaker) =>
                speaker.selectSpeakerId == selectSpeakerId &&
                speaker.id !== excludeId
        );

        if (idx === -1) {
            // 選択したスピーカー機器が設定されているスピーカーがない・スピーカー詳細設定画面で表示中のスピーカーと同じ場合falseを返却
            return false;
        }
        // その他の場合、選択したスピーカーは重複しているためtrueを返却
        return true;
    };

    /** ----------------------グループ制御---------------------- */
    /**
     * 新規グループ追加
     * @param {object} data - グループ情報
     */
    createGroups = (data) => {
        // id採番（スピーカー配列の最大値の「id」+ 1）/ uuidなんかが普通に楽かも
        const id = Utils.getNewId(this.state.groups);
        const inputs = {
            id,
            ...data,
            isDelete: false,
        };
        this.setGroups(
            update(this.state.groups, {
                $push: [inputs],
            })
        );
        this.setSavedFlag(false);
    };
    /**
     * 既存グループ名変更
     * @param {object} data - グループ情報
     */
    updateGroup = (data) => {
        const idx = this.state.groups.findIndex(
            (group) => group.id === data.id
        );
        this.setGroups(
            update(this.state.groups, {
                [idx]: {
                    $merge: { ...data },
                },
            })
        );
        this.setSavedFlag(false);
    };
    /**
     * グループ削除
     * @param {object} data - グループ情報
     */
    deleteGroup = () => {
        // グループ所属スピーカーを未所属に更新
        const speakers = this.state.speakers.map((speaker) =>
            speaker.groupId === this.state.currentDelGroup
                ? { ...speaker, groupId: null }
                : speaker
        );
        this.setSpeakers(speakers);
        // グループ削除
        const idx = this.state.groups.findIndex(
            (group) => group.id === this.state.currentDelGroup
        );
        if (idx !== -1) {
            this.setGroups(
                update(this.state.groups, {
                    [idx]: {
                        $merge: { isDelete: true },
                    },
                })
            );
            this.selectGroup(null);
            this.selectDelGroup(null);
        }
        this.setSavedFlag(false);
    };

    /**
     * グループからスピーカーを解除する
     */
    ungroupSpeaker = () => {
        const { selectedUngroupSpeakerId } = this.state;
        // スピーカーをグループ未所属に更新
        const speakers = this.state.speakers.map((speaker) =>
            speaker.id === selectedUngroupSpeakerId
                ? { ...speaker, groupId: null }
                : speaker
        );
        this.setSpeakers(speakers);
        this.setSelectedUngroupSpeakerId(null);
    }

    /**
     * 選択スピーカーがグループに入っているか確認
     */
    checkGroupSpeaker = () => {
        const { currentSpeaker, currentGroup } = this.state;
        if (currentSpeaker.length === 0) {
            // スピーカーが選択されていない場合なにもしない
            return;
        }

        // 選択したスピーカーのリストを作成
        const selSpeakers = currentSpeaker.map((number) => {
            return this.state.speakers.find((speaker) => speaker.id === number);
        });

        // 追加予定スピーカーリストとして保存
        this.setRemainGroupSpeaker(selSpeakers);

        // 追加予定スピーカーの中に、既に他グループに入っているスピーカーがあるか検索
        const otherGroupSpeakers = selSpeakers.filter(
            (speaker) => speaker.groupId && speaker.groupId !== currentGroup
        );

        const otherGroupSpeakerIds = [];

        // 別のグループのID取得
        for (const speaker of otherGroupSpeakers) {
            otherGroupSpeakerIds.push({
                speakerId: speaker.id,
                groupId: speaker.groupId,
            });
        }

        this.setOtherGroupSpeakerIds(otherGroupSpeakerIds);

        // 別のグループがある場合、モーダル表示
        if (otherGroupSpeakers.length > 0) {
            this.modalOpen(MODAL.CHECK_GROUP);
        } else {
            // 別のグループがない場合、グループに追加
            this.putInGroup();
        }
    };

    // グループ追加モーダルキャンセル時処理
    deleteRemainGroupSpeaker = () => {
        this.checkRemainGroupSpeaker(false);
    };

    /**
     * グループ追加確認処理
     * @param {boolean} inGroup 別のグループのスピーカー追加フラグ
     */
    checkRemainGroupSpeaker = (inGroup = true) => {
        const { otherGroupSpeakerIds } = this.state;

        // 別グループスピーカーは追加しない場合、
        if (!inGroup) {
            // グループ追加予定スピーカーリストから対象のグループに属するスピーカーを除外する
            this.remainGroupSpeaker = this.remainGroupSpeaker.filter(
                (speaker) => {
                    return speaker.id !== otherGroupSpeakerIds[0].speakerId;
                }
            );
        }

        // モーダルで確認が終わった別グループのIDを除外する
        const groupSpeakerIds = otherGroupSpeakerIds;
        groupSpeakerIds.splice(0, 1);
        this.setOtherGroupSpeakerIds(groupSpeakerIds);

        // 確認すべき別グループIDが残っている場合、繰返しグループ追加確認モーダルを表示する
        if (groupSpeakerIds.length > 0) {
            setTimeout(() => {
                this.modalOpen(MODAL.CHECK_GROUP);
            }, 100);
        } else {
            // 別のグループがない場合、グループに追加
            this.putInGroup();
        }
    };

    /**
     * グループに選択スピーカー追加
     */
    putInGroup = () => {
        // グループ追加予定スピーカーリストの全対象のグループを更新する
        const speakers = this.state.speakers.map((speaker) =>
            this.remainGroupSpeaker.findIndex(
                (subSpeaker) => subSpeaker.id === speaker.id
            ) !== -1
                ? { ...speaker, groupId: this.state.currentGroup }
                : speaker
        );

        this.setState({ speakers, savedFlag: false });

        // スピーカーの選択を解除する
        this.setState({ currentSpeaker: [] });
    };

    /** ------------------スケジューラ制御----------------- */
    /**
     * スケジューラ画面のスピーカー選択(単体、複数)
     * @param {number[]} speakerIds 更新対象のスピーカーID
     */
    putScheduleTargets = (speakerIds) => {
        this.setSchedulTargets(
            update(this.state.schedulTargets, { $push: speakerIds })
        );
    };
    /**
     * スケジューラ画面の選択済みスピーカー解除(単体、複数)
     * @param {number[]} speakerIds 更新対象のスピーカーID
     */
    removeScheduleTargets = (speakerIds) => {
        let ids = this.state.schedulTargets.filter(
            (id) => !speakerIds.includes(id)
        );

        this.setSchedulTargets(
            update(this.state.schedulTargets, { $set: ids })
        );
    };

    /** ----------------------その他---------------------- */
    /**
     * 画面状態切り替え
     * @param {string} mode
     */
    setMode = (mode) => {
        const { floorName, areaImage } = this.state;
        if (floorName === '' || areaImage === null) {
            // フロア名またはフロア画像が未設定の場合画面状態の切り替えをしない
            return;
        }
        // 各画面選択 初期化
        this.resetStateForChangeMode();
        this.setState({ mode: mode });
    };

    /**
     * モーダル表示
     * @param {string} modalName - モーダルに紐づけた名前(refで指定)
     * @param {*} param - モーダルの初期表示時に使用するパラメータ、必要に応じて設定
     */
    modalOpen = (modalName, param = null) => {
        if (modalName === MODAL.NEW_FLOOR) {
            // 新規フロアマップ読込モーダル（このモーダルのみ、実装の方法が他と異なるため）
            this.setModalNewFloor();
        } else if (modalName === MODAL.SAVE_FLOOR) {
            /*
             * フロア保存モーダルを呼び出す場合（新規フロアマップ読込モーダル、既存フロア読み込みモーダルから呼び出す）
             * 引数のparamには以下の内容を設定しておくこと
             * param = {
             *   floorName: string, areaImage: string, realWidth: number, mode: string, speakers: object,
             *   groups: object, currentSpeaker: null, currentGroup: null, currentDelGroup: null, savedFlag: boolean,
             * }
             */
            this[modalName].current.init(param);
        } else if (modalName === MODAL.DETAIL_SPEAKER) {
            /*
             * スピーカー詳細設定モーダルを呼び出す場合
             * 引数のparamには以下の内容を設定しておくこと
             * param = {arrayName: string, id: number, isFirst: boolean}
             */
            if (param.isFirst) {
                // スピーカー追加時
                this[modalName].current.init({ id: param.id }, param.isFirst);
            } else {
                const idx = this.state[param.arrayName].findIndex(
                    (item) => item.id === param.id
                );
                this[modalName].current.init(
                    this.state[param.arrayName][idx],
                    param.isFirst
                );
            }
        } else {
            // それ以外の場合
            this[modalName].current.init();
        }
    };

    /**
     * 選択されたスピーカーが所属されているスピーカー名を取得
     */
    getSelectedSpeakerName = () => {
        if (
            this.state.currentGroup !== null &&
            this.state.otherGroupSpeakerIds.length !== 0
        ) {
            const speakerId = this.state.otherGroupSpeakerIds[0].speakerId;
            const selSpeaker = this.state.speakers.find(
                (speaker) => speaker.id === speakerId
            );

            if (selSpeaker) {
                return selSpeaker.name;
            }
        }
        return '';
    };

    /**
     * 選択されたスピーカーが所属されているグループ名を取得
     */
    getSelectedSpeakerGroupName = () => {
        if (
            this.state.currentGroup !== null &&
            this.state.otherGroupSpeakerIds.length !== 0
        ) {
            const groupId = this.state.otherGroupSpeakerIds[0].groupId;
            const selSpeakerGroup = this.state.groups.find(
                (group) => group.id === groupId
            );

            if (selSpeakerGroup) {
                return selSpeakerGroup.name;
            }
        }
        return '';
    };

    /**
     * フロア情報設定処理
     * @param {object} area - フロア情報
     */
    settingAreaInfo = (area) => {
        this.setState(update(this.state, { $merge: area }));
        document.cookie = COOKIE_KEY.LOAD_FLOOR_ID + '=' + area.floorId + "; max-age=31536000";
        document.cookie = COOKIE_KEY.FLOOR_JOINED_BASE_ID + '=' + sessionStorage.getItem(SESSION_STORAGE_KEY.BASE_ID) + '; max-age=31536000';

        if (area.mode == MODE.SET_SCHEDULES) {
            if (this.pollingJob)
            // ポーリング用タスクの開始
            this.pollingJob.start();
        } else {
            // ポーリング用タスクの終了
            this.pollingJob.stop();
        }
    }

    // 運転モード切替または、フロア編集情報保存確認モーダルを表示
    openChangeOperation = () => {
        if (this.state.savedFlag) {
            // フロアの変更が保存されてる場合、運転モード切替を開く
            this.modalOpen(MODAL.CHANGE_OPEARTION);
        } else {
            // フロアの変更が保存されていない場合、フロア編集情報保存確認を開く
            this.modalOpen(MODAL.CHOICE_SAVE_FLOOR);
        }
    };

    /**
     * 画面表示時に自動で運用モードに切り替える
     */
    autoChangeOperationMode = async () => {
        // フロア読み込み
        await this.reloadFloor(MODE.SET_FLOOR, true);
    }

    // 運用・設定モード切り替え処理
    changeOperationMode = async (isOperating) => {
        const { userId, floorId } = this.state;
        this.resetStateForChangeMode();

        // モード切替情報をサーバーに通知
        var changeModeData = {
            userId: userId,
            floorId: floorId,
            mode: isOperating
                ? OFFICE_CLIENT_MODE.OPERATION
                : OFFICE_CLIENT_MODE.SETTING,
        };
        await changeFloorMode(changeModeData);

        const mode = isOperating ? MODE.SET_SCHEDULES : MODE.SET_SPEAKERS;

        // フロア再呼び込み
        await this.reloadFloor(mode, false);
    };

    /**
     * スピーカーステータスを画面に反映する
     */
    reflectSpeakerStatus = async () => {
        const { floorId, speakers } = this.state;

        let spks = [...speakers];
        // スピーカーの設定を取得
        const data = await SPEAKER_API.getSpeakerStatus(floorId);
        for (const speaker of data.speakerStatusList) {
            // 拠点スピーカーIDでスピーカー検索
            const spk = spks.find((spk) => spk.id === speaker.speakerId);

            if (Utils.isEmpty(spk)) continue;

            const colorId =
                speaker.status === SPEAKER_STATUS.START
                    ? speaker.colorId
                    : null;

            spk.status = speaker.status;
            spk.isMute = speaker.isMute;
            spk.isFailure = speaker.isFailure;
            spk.volume = speaker.volume;
            spk.colorId = colorId;
        }

        this.setSpeakers(spks);
    };

    /**
     * サイドバー、スピーカーリストの高さ変更
     * スケジュール表示領域の高さ変動に合わせて高さを変更する
     * @param {string} schHeight スケジュール表示領域の高さ
     */
    resizeBackDivs = (schHeight) => {
        if (this.SpeakerList.current != null) {
            // 画面高さ(100vh) - スケジュール表示領域高さ - position:sticky領域高さ
            this.SpeakerList.current.setMaxHeight(
                `calc(100vh - ${schHeight} - 90px)`
            );
        }
        if (this.SideBar.current != null) {
            // 画面高さ(100vh) - スケジュール表示領域高さ - position:sticky領域高さ
            this.SideBar.current.setScheduleMaxHeight(
                `calc(100vh - ${schHeight} - 230px)`
            );
        }
    };

    /**
     * フロア情報削除時に、削除対象のフロア情報を表示中の場合は初期表示に戻す
     * @param {number} targetFloorId - 削除対象のフロアID
     */
    clearFloorInfo = (targetFloorId) => {
        if (this.state.floorId === targetFloorId) {
            this.setState({
                isOperating: false,
                mode: MODE.SET_FLOOR,
                floorId: null,
                floorName: '',
                areaImage: null,
                realWidth: REAL_WIDTH.REAL_WIDTH,
                speakers: [],
                groups: [],
                currentSpeaker: [],
                currentGroup: null,
                currentDelGroup: null,
                schedulTargets: [],
                modalNewFloorFlag: false,
                savedFlag: true,
                otherGroupSpeakerIds: [],
            });

            this.remainGroupSpeaker = [];
        }
    };

    /**
     * フロア保存選択モーダル表示
     */
    choiceHandler = async (choiceNumber) => {
        if (choiceNumber === CHOICE_MODAL_OPT.FIRST) {
            try {
                await this.saveFloor();

                this.setDisplayMessage(DISP_RESOURCE.SAVE_SUCCESS);
                this.modalOpen(MODAL.DISPLAY_MESSAGE);
            } catch (e) {
                this.setDisplayMessage(DISP_RESOURCE.SAVE_FAILED);
                this.modalOpen(MODAL.DISPLAY_MESSAGE);
                return;
            }
            this.changeOperationMode(true);
        } else {
            this.changeOperationMode(true);
        }
    };

    /**
     * 画面描画
     * @returns
     */
    render() {
        const {
            userId,
            mode,
            floorId,
            baseId,
            floorName,
            areaImage,
            realWidth,
            speakers,
            groups,
            baseSpeakers,
            currentSpeaker,
            currentGroup,
            currentDelGroup,
            soundPressureFlag,
            speakerListFlag,
            schedulTargets,
            isOperating,
            displayMessage,
        } = this.state;
        // グローバルキーボードイベント
        document.addEventListener('keydown', this.handleKeyPress);
        return (
            <div>
                <TopBar
                    floorName={floorName}
                    pageType="SETTING" /*仮*/
                    mode={mode}
                    setMode={this.setMode}
                    isOperating={isOperating}
                    setOperationFlag={this.setOperationFlag}
                    disableChangeMode={floorId === null}
                    modalOpen={this.modalOpen}
                    openChangeOperation={this.openChangeOperation}
                ></TopBar>
                {mode === MODE.VIEW_LOG ? (
                    <div className="contents_area">
                        <Log baseId={baseId} />
                    </div>
                ) : (
                    /* コンテンツ */
                    <div className="contents_area">
                        <DndProvider backend={HTML5Backend}>
                            {/* サイドバー*/}
                            <SideBar
                                ref={this.SideBar}
                                mode={mode}
                                setMode={this.setMode}
                                modalOpen={this.modalOpen}
                                groupsInfo={{
                                    speakers: speakers,
                                    groups: groups,
                                    currentGroup: currentGroup,
                                    currentDelGroup: currentDelGroup,
                                    createGroups: this.createGroups,
                                    updateGroup: this.updateGroup,
                                    selectGroup: this.selectGroup,
                                    selectDelGroup: this.selectDelGroup,
                                    deleteGroup: this.deleteGroup,
                                    setSelectedUngroupSpeakerId: this.setSelectedUngroupSpeakerId,
                                    checkGroupSpeaker: this.checkGroupSpeaker,
                                }}
                                scheduleInfo={{
                                    speakers: speakers,
                                    groups: groups,
                                    schedulTargets: schedulTargets,
                                    speakerListFlag: speakerListFlag,
                                    setSchedulTargets: this.setSchedulTargets,
                                    putScheduleTargets: this.putScheduleTargets,
                                    removeScheduleTargets:
                                        this.removeScheduleTargets,
                                    toggleSpeakerListFlag:
                                        this.toggleSpeakerListFlag,
                                }}
                            />
                            {!speakerListFlag ? (
                                <div className="area">
                                    {/* フロアエリアヘッダー */}
                                    <div className="area_header">
                                        {!isOperating && (
                                            <>
                                                {mode === MODE.SET_FLOOR && (
                                                    <div className="floor_header">
                                                        {
                                                            DISP_RESOURCE.AREA_REAL_WIDTH
                                                        }
                                                        <Input
                                                            type="number"
                                                            value={realWidth}
                                                            onChange={
                                                                this
                                                                    .setRealWidth
                                                            }
                                                            min={REAL_WIDTH.MIN}
                                                            max={REAL_WIDTH.MAX}
                                                        />{' '}
                                                        {
                                                            DISP_RESOURCE.AREA_REAL_WIDTH_UNIT
                                                        }
                                                    </div>
                                                )}
                                                {mode === MODE.SET_SPEAKERS && (
                                                    <div className="speaker_header">
                                                        {
                                                            DISP_RESOURCE.SPEAKER_GUIDE
                                                        }
                                                    </div>
                                                )}
                                                {mode === MODE.SET_GROUPS && (
                                                    <div className="group_header">
                                                        <img
                                                            alt="add"
                                                            src={
                                                                require('../img/round_plus.svg')
                                                                    .default
                                                            }
                                                        />
                                                        {parser(
                                                            DISP_RESOURCE.GROUP_GUIDE
                                                        )}
                                                    </div>
                                                )}
                                                {mode ===
                                                    MODE.SET_SCHEDULES && (
                                                    <div></div>
                                                )}
                                                <ModalSaveFloor
                                                    ref={this[MODAL.SAVE_FLOOR]}
                                                    realWidth={realWidth}
                                                    areaImage={areaImage}
                                                    floorName={floorName}
                                                    userId={userId}
                                                    mode={mode}
                                                    setFloorName={
                                                        this.setFloorName
                                                    }
                                                    saveFloor={this.saveFloor}
                                                    reloadFloor={
                                                        this.reloadFloor
                                                    }
                                                    settingAreaInfo={
                                                        this.settingAreaInfo
                                                    }
                                                ></ModalSaveFloor>
                                            </>
                                        )}
                                    </div>
                                    {/* フロアエリアコンテンツ */}
                                    <div className="content">
                                        <Area
                                            areaWidth={realWidth}
                                            areaImage={areaImage}
                                            speakers={speakers}
                                            currentSpeaker={currentSpeaker}
                                            currentGroup={currentGroup}
                                            setAreaImage={this.setAreaImage}
                                            createSpeaker={this.createSpeaker}
                                            updateSpeaker={this.updateSpeaker}
                                            selectSpeaker={this.selectSpeaker}
                                            modalOpen={this.modalOpen}
                                            mode={mode}
                                            soundPressureFlag={
                                                soundPressureFlag
                                            }
                                            baseSpeakers={baseSpeakers}
                                            scheduleInfo={{
                                                schedulTargets: schedulTargets,
                                                putScheduleTargets:
                                                    this.putScheduleTargets,
                                                removeScheduleTargets:
                                                    this.removeScheduleTargets,
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="speaker_list_area">
                                    <SpeakerList
                                        ref={this.SpeakerList}
                                        speakers={this.state.speakers}
                                        groups={this.state.groups}
                                        speakerListFlag={speakerListFlag}
                                        toggleSpeakerListFlag={
                                            this.toggleSpeakerListFlag
                                        }
                                        incrementSpeakerVolume={
                                            this.incrementSpeakerVolume
                                        }
                                        decrementSpeakerVolume={
                                            this.decrementSpeakerVolume
                                        }
                                        resize={this.resizeBackDivs}
                                    />
                                </div>
                            )}
                        </DndProvider>
                    </div>
                )}
                {
                    /* スケジュールバー領域 */
                    mode === MODE.SET_SCHEDULES && (
                        <Scheduler
                            ref={this.Scheduler}
                            userId={userId}
                            floorId={floorId}
                            baseId={baseId}
                            speakers={speakers}
                            groups={groups}
                            schedulTargets={schedulTargets}
                            soundPressureFlag={soundPressureFlag}
                            speakerListFlag={speakerListFlag}
                            setSpeakers={this.setSpeakers}
                            setSoundPressureFlag={this.setSoundPressureFlag}
                            setSchedulTargets={this.setSchedulTargets}
                            setMute={this.setMute}
                            incrementSpeakerVolume={this.incrementSpeakerVolume}
                            decrementSpeakerVolume={this.decrementSpeakerVolume}
                            resize={this.resizeBackDivs}
                        />
                    )
                }
                {/* 新規フロア読込MODAL */}
                <ModalNewFloor
                    modal={this.state.modalNewFloorFlag}
                    setModal={this.setModalNewFloor}
                    savedFlag={this.state.savedFlag}
                    floorName={this.state.floorName}
                    settingAreaInfo={this.settingAreaInfo}
                    saveFloor={this.saveFloor}
                    modalOpen={this.modalOpen}
                ></ModalNewFloor>
                {/* 既存フロア読込MODAL */}
                <ModalLoadFloor
                    ref={this[MODAL.LOAD_FLOOR]}
                    modalMode={MODAL.LOAD_FLOOR}
                    loadJSON={this.loadJSON}
                    saveFloor={this.saveFloor}
                    savedFlag={this.state.savedFlag}
                    setSavedFlag={this.setSavedFlag}
                    floorName={this.state.floorName}
                    modalOpen={this.modalOpen}
                    settingAreaInfo={this.settingAreaInfo}
                    userId={userId}
                ></ModalLoadFloor>
                {/* 既存フロア削除MODAL */}
                <ModalLoadFloor
                    ref={this[MODAL.DELETE_FLOOR]}
                    modalMode={MODAL.DELETE_FLOOR}
                    modalOpen={this.modalOpen}
                    userId={userId}
                    clearFloorInfo={this.clearFloorInfo}
                ></ModalLoadFloor>
                {/* メッセージ出力MODAL*/}
                <ModalMessage
                    ref={this[MODAL.DISPLAY_MESSAGE]}
                    title={''}
                    content={displayMessage}
                    okString={DISP_RESOURCE.CLOSE}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
                {/* スピーカー詳細MODAL */}
                <ModalSpeaker
                    ref={this[MODAL.DETAIL_SPEAKER]}
                    updateSpeaker={this.updateSpeaker}
                    modalOpen={this.modalOpen}
                    deleteSpeaker={this.deleteSpeaker}
                    checkDuplicateSpeaker={this.checkDuplicateSpeaker}
                    userId={userId}
                    baseId={this.state.baseId}
                    speakers={this.state.speakers}
                    baseSpeakers={this.state.baseSpeakers}
                    saveFloorAndQrDownload={this.saveFloorAndQrDownload}
                ></ModalSpeaker>
                {/* スピーカーグループ確認MODAL */}
                <ModalMessage
                    ref={this[MODAL.CHECK_GROUP]}
                    title={DISP_RESOURCE.CHECK_GROUP_TITLE}
                    content={DISP_RESOURCE.CHECK_GROUP_CONTENT.format(
                        this.getSelectedSpeakerName(),
                        this.getSelectedSpeakerGroupName()
                    )}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.checkRemainGroupSpeaker}
                    isCancel={true}
                    cancelProc={this.deleteRemainGroupSpeaker}
                ></ModalMessage>
                {/* グループ削除MODAL */}
                <ModalMessage
                    ref={this[MODAL.DELETE_GROUP]}
                    title={DISP_RESOURCE.DELETE_GROUP_TITLE}
                    content={DISP_RESOURCE.DELETE_GROUP_CONTENT}
                    okString={DISP_RESOURCE.DELETE}
                    okProc={this.deleteGroup}
                    isCancel={true}
                ></ModalMessage>
                {/* グループ解除MODAL */}
                <ModalMessage
                    ref={this[MODAL.UNGROUP_SPEAKER]}
                    title={DISP_RESOURCE.UNGROUP_SPEAKER_TITLE}
                    content={DISP_RESOURCE.UNGROUP_SPEAKER_CONTENT}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.ungroupSpeaker}
                    isCancel={true}
                ></ModalMessage>
                {/* 設定→運用切替モーダル */}
                <ModalOperationMode
                    ref={this[MODAL.CHANGE_OPEARTION]}
                    changeOperationMode={this.changeOperationMode}
                    saveFloor={this.saveFloor}
                    checkFloor={this.checkFloor}
                    isOperating={this.state.isOperating}
                    savedFlag={this.state.savedFlag}
                    userId={userId}
                />
                {/* 操作選択モーダル */}
                <ModalChoice
                    ref={this[MODAL.CHOICE_SAVE_FLOOR]}
                    title={DISP_RESOURCE.CHOICE_SAVE_FLOOR_TITLE}
                    content={DISP_RESOURCE.CHOICE_SAVE_FLOOR_CONTENT}
                    firstOpt={
                        DISP_RESOURCE.CHOICE_SAVE_FLOOR_FIRST_OPT_OPERATION
                    }
                    secondOpt={
                        DISP_RESOURCE.CHOICE_SAVE_FLOOR_SECOND_OPT_OPERATION
                    }
                    okProc={this.choiceHandler}
                />
            </div>
        );
    }
}

export default AreaSetting;
