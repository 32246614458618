import React, { createRef } from 'react';
import { Button, Col, Input, Popover, Row } from 'reactstrap';
import { Rnd } from 'react-rnd';
import moment from 'moment';
import classNames from 'classnames';
import DISP_RESOURCE from '../../common/dispResource';
import * as Utils from '../../common/utils';
import {
    EXTERNAL_FILE_CONTENTS as EX_FILE,
    OFFICE_USER_TYPE,
    SCHEDULE_VISIBLE_TYPE,
    SPEAKER_STATUS,
    CREATE_LOG_IDENTIFIER,
    COOKIE_KEY,
} from '../../common/constants';

import CustomTimeline from './scheduler/CustomTimeline';
import ScheduleSetting from './scheduler/ScheduleSetting';
import 'react-calendar-timeline/lib/Timeline.css';
import ModalSoundTest from './modal/ModalSoundTest';
import ModalMessage from './modal/ModalMessage';
import { ReactComponent as SettingImg } from '../../img/setting.svg';

import * as SCHEDULE_API from '../../api/scheduleApi';
import * as SPEAKER_API from '../../api/speakerApi';
import * as LOG_API from '../../api/logApi';
import {
    getSoundSources,
    postStartTestPlayFloor,
    postStopTestPlayFloor,
} from '../../api/speakerApi';

// スケジューラー表示高さ最大値
const maxHeight = '90vh';

const settingRndMinHeight = '0.1px';

class Scheduler extends React.Component {
    /**
     * スケジューラー
     * @param {number}   props.userId               - ユーザーID
     * @param {number}   props.floorId              - フロアID
     * @param {number}   props.baseId               - 拠点ID
     * @param {object[]} props.speakers             - スピーカー情報
     * @param {object[]} props.groups               - グループ情報
     * @param {number[]} props.schedulTargets       - スケジュール対象スピーカ
     * @param {boolean}  props.soundPressureFlag    - 音圧表示フラグ
     * @param {boolean}  props.speakerListFlag      - スピーカーリスト表示フラグ
     * @param {function} props.setSpeakers          - スピーカー更新
     * @param {function} props.setSoundPressureFlag - 音圧表示フラグ変更処理
     * @param {function} props.setSchedulTargets    - スケジュール対象更新処理
     * @param {function} props.setMute              - ミュート処理
     * @param {function} incrementSpeakerVolume     - スピーカー音量増加処理
     * @param {function} decrementSpeakerVolume     - スピーカー音量減少処理
     * @param {object}   scheduler                  - 下段スケージューラ表示画面要素
     * @param {object}   setting                    - スケージュール設定画面要素
     */
    constructor(props) {
        super(props);
        // スケジュール表示画面
        this.scheduler = null;

        // スケジュール設定画面
        this.setting = null;

        // 初期値
        let scheduleVisibleType = SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS;
        let scheduleStartTime = '09';
        let soundPressure = false;

        // Cookie情報取得
        const cookies = document.cookie.split(';');
        cookies.forEach(function(value) {
            const contents = value.trim().split('=');

            switch(contents[0]) {
                case COOKIE_KEY.SCHEDULE_VISIBLE_TYPE:
                    scheduleVisibleType = contents[1];
                    break;
                case COOKIE_KEY.SCHEDULE_START_TIME:
                    scheduleStartTime = contents[1];
                    break;
                case COOKIE_KEY.SOUND_PRESSURE:
                    soundPressure = JSON.parse(contents[1].toLowerCase());
                    break;
                default:
                    break;
            }
        });

        props.setSoundPressureFlag(soundPressure);

        this.state = {
            /**
             * @type {boolean} スケジュール表示画面サイズ調整用ドラッグフラグ
             */
            isDragging: false,
            /**
             * @type {boolean} 表示開始時刻設定用ポップフラグ
             */
            isOpenHourPop: false,
            /**
             * @type {boolean} ミュートフラグ
             */
            isMute: false,
            /**
             * @type {*} 表示開始時刻（デフォルト9時）
             */
            hour: scheduleStartTime,
            /**
             * @type {*} スケジュールバー表示範囲（時）（デフォルト9時）
             */
            visibleHour: scheduleStartTime,
            /**
             * @type {*} スケジュールバー表示範囲（日）
             */
            visibleTime: moment().valueOf(),
            /**
             * @type {*} スケジュールバー表示タイプ（デフォルト：12時間）
             */
            visibleType: scheduleVisibleType,
            /**
             * 音源リスト
             */
            soundSources: [],
            /**
             * スケジュールバー行（スピーカー）
             */
            rows: [{ speakerId: '', speakerName: '', speakerIds: [] }],
            /**
             * スケジュール
             */
            schedules: [],
            /**
             * アラート表示文言
             */
            alertModalContent: '',
        };

        this.scheduleDisplayInterval = null;

        this.SchedulerSetting = createRef();
        this.SoundTestModal = createRef();
        this.ScheduleAlertModal = createRef();
    }

    /**
     * 画面表示時 初期化処理(ライフサイクル)
     */
    componentDidMount = async () => {
        const { speakers } = this.props;
        this.scheduler =
            document.getElementsByClassName(
                `scheduler_div_parent`
            )[0].firstChild;
        this.settings = document.getElementsByClassName(
            `scheduler_setting_parent`
        )[0].firstChild;

        this.scheduler.style.height = EX_FILE.SCHEDULER_DEFAULT_HEIGHT + 'px';

        // ミュート中スピーカーがある場合、ミュート表示
        const muteSpeakers = speakers.filter((speaker) => speaker.isMute);
        this.setState({ isMute: muteSpeakers.length > 0 });

        this.init();

        // 30秒ごと表示時間更新
        this.scheduleDisplayInterval = setInterval(() => {
            const { visibleTime } = this.state;
            const vt = moment(visibleTime);
            const now = moment();
            if (
                now.format('YYMMDD') === vt.format('YYMMDD') ||
                (now.diff(vt, 'hours') >= -1 && now.diff(vt, 'hours') <= 0)
            ) {
                // 表示時間が当日の場合 または 日を跨いですぐの場合、表示時間更新
                this.setState({ visibleTime: now.valueOf() });
            }
        }, 30000);
    };

    componentWillUnmount = () => {
        if (this.scheduleDisplayInterval !== null) {
            clearInterval(this.scheduleDisplayInterval);
        }
    };

    init = async () => {
        const { userId, floorId, speakers, setSpeakers } = this.props;

        // スケジュール取得
        const scheduleInfos = await SCHEDULE_API.getSchedules({
            userId,
            floorId,
        });
        this.setState({
            rows: scheduleInfos.scheduleRows,
            schedules: scheduleInfos.schedules,
        });

        // 現在スピーカー状態取得、表示反映
        let spks = [...speakers];
        const data = await SPEAKER_API.getSpeakerStatus(floorId);
        for (const speaker of data.speakerStatusList) {
            // 拠点スピーカーIDでスピーカー検索
            const spk = spks.find((spk) => spk.id === speaker.speakerId);

            if (Utils.isEmpty(spk)) continue;

            const colorId =
                speaker.status === SPEAKER_STATUS.START
                    ? speaker.colorId
                    : null;
            spk.status = speaker.status;
            spk.volume = speaker.volume;
            spk.colorId = colorId;
        }

        setSpeakers(spks);
    };

    /**
     * スケジュール登録
     * @param {object} values スケジュール入力値
     * @param {object[]} duplicateInfos 重複スケジュール処理リスト
     */
    createSchedules = async (values, duplicateInfos) => {
        const { soundSources } = this.state;
        const { userId, floorId, schedulTargets, setSchedulTargets } =
            this.props;

        const soundSourceName = soundSources.find(
            (sound) => sound.soundSourceNo === Number(values.soundSource)
        ).soundSourceName;

        const params = {
            floorInfo: {
                userId,
                floorId,
            },
            scheduleInfo: {
                speakerIds: schedulTargets,
                scheduleName: values.scheduleName,
                periodStartDate: values.periodStartDate,
                periodStartTime: values.periodStartTime,
                periodEndDate: values.periodEndDate,
                periodEndTime: values.periodEndTime,
                soundSource: values.soundSource,
                soundSourceName: soundSourceName,
                volume: values.volume,
                colorId: values.colorId,
                repeatFlag: values.repeat,
                repeatType: values.repeat ? values.repeatType : null,
                repeatOption1: values.repeat ? values.repeatOption1 : '',
                repeatOption2: values.repeatOption2,
                repeatOption3: values.repeatOption3,
                repeatCustom: values.repeatCustom,
                repeatEndDate: values.repeatEndDate,
            },
            scheduleDuplicateInfo: duplicateInfos,
        };

        try {
            await SCHEDULE_API.createSchedules(params);
            await this.init();

            setSchedulTargets([]);
            this.closeSettings();

            // スケジュール登録成功時
            await this.setAlertModalContent(DISP_RESOURCE.SETTING_SUCCESS);
            this.ScheduleAlertModal.current.init();
        } catch (e) {
            // スケジュール登録失敗時
            await this.setAlertModalContent(DISP_RESOURCE.SETTING_FAILED);
            this.ScheduleAlertModal.current.init();
        }
    };

    /**
     * スケジュール更新
     * @param {object} values スケジュール入力値
     * @param {object} updateOptions スケジュール更新オプション
     * @param {object[]} duplicateInfos 重複スケジュール処理リスト
     */
    updateSchedule = async (values, updateOptions, duplicateInfos) => {
        const { soundSources } = this.state;
        const { userId, floorId, setSchedulTargets } = this.props;

        const soundSourceName = soundSources.find(
            (sound) => sound.soundSourceNo === Number(values.soundSource)
        ).soundSourceName;

        const params = {
            floorInfo: {
                userId,
                floorId,
            },
            scheduleInfo: {
                scheduleId: values.id,
                scheduleRepeatId: values.scheduleRepeatId,
                scheduleName: values.scheduleName,
                periodStartDate: values.periodStartDate,
                periodStartTime: values.periodStartTime,
                periodEndDate: values.periodEndDate,
                periodEndTime: values.periodEndTime,
                soundSource: values.soundSource,
                soundSourceName: soundSourceName,
                volume: values.volume,
                colorId: values.colorId,
                repeatFlag: values.repeat,
                repeatType: values.repeat ? values.repeatType : null,
                repeatOption1: values.repeat ? values.repeatOption1 : '',
                repeatOption2: values.repeatOption2,
                repeatOption3: values.repeatOption3,
                repeatCustom: values.repeatCustom,
                repeatEndDate: values.repeatEndDate,
            },
            scheduleDuplicateInfo: duplicateInfos,
            periodChangeFlag: updateOptions.periodChangeFlag,
            repeatChangeFlag: updateOptions.repeatChangeFlag,
            repeatEndChangeFlag: updateOptions.repeatEndChangeFlag,
            groupUpdateFlag: updateOptions.groupUpdateFlag,
            repeatUpdateFlag: updateOptions.repeatUpdateFlag,
        };

        try {
            await SCHEDULE_API.updateSchedules(params);
            await this.init();

            setSchedulTargets([]);
            this.closeSettings();

            // スケジュール更新成功時
            await this.setAlertModalContent(DISP_RESOURCE.UPDATE_SUCCESS);
            this.ScheduleAlertModal.current.init();
        } catch (e) {
            // スケジュール更新失敗時
            await this.setAlertModalContent(DISP_RESOURCE.UPDATE_FAILED);
            this.ScheduleAlertModal.current.init();
        }
    };

    /**
     * スケジュール削除
     * @param {object}  schedule   削除対象スケジュール情報
     * @param {object}  deleteOptions  グループの全スケジュール削除
     */
    deleteSchedule = async (schedule, deleteOptions) => {
        const { userId, floorId, setSchedulTargets } = this.props;

        const params = {
            userId,
            floorId,
            scheduleId: schedule.id,
            scheduleRepeatId: schedule.scheduleRepeatId,
            groupDeleteFlag: deleteOptions.groupDeleteFlag,
            repeatDeleteFlag: deleteOptions.repeatDeleteFlag,
        };

        try {
            await SCHEDULE_API.deleteSchedules(params);
            await this.init();

            setSchedulTargets([]);
            this.closeSettings();

            // スケジュール削除成功時
            await this.setAlertModalContent(DISP_RESOURCE.DELETE_SUCCESS);
            this.ScheduleAlertModal.current.init();
        } catch (e) {
            // スケジュール削除失敗時
            await this.setAlertModalContent(DISP_RESOURCE.DELETE_FAILED);
            this.ScheduleAlertModal.current.init();
        }
    };

    /**
     * スケジューラー表示更新
     * @param {string} visibleType スケジューラー表示タイプ
     */
    setSchedulerTime = (visibleType) => {
        this.setState({ visibleType, visibleTime: moment().valueOf() });
        document.cookie = COOKIE_KEY.SCHEDULE_VISIBLE_TYPE + '=' + visibleType + "; max-age=31536000";
    };

    /**
     * アラート表示文言更新
     * @param {string} content アラート表示文言
     * @returns
     */
    setAlertModalContent = (content) => {
        return new Promise((resolve) =>
            this.setState({ alertModalContent: content }, resolve)
        );
    };

    /**
     * スケジュール詳細画面表示
     * @param {object} schedule スケジュール情報（更新時のみ）
     */
    showSettings = async (schedule = null) => {
        const { rows } = this.state;
        const { schedulTargets, setSchedulTargets } = this.props;

        let firstSpeakerId =
            schedulTargets.length > 0 ? schedulTargets[0] : null;
        // 対象未選択の場合、
        if (schedule == null && firstSpeakerId === null) {
            await this.setAlertModalContent(
                DISP_RESOURCE.SCHEDULE_NONE_TARGET_ALERT
            );

            this.ScheduleAlertModal.current.init();
            return;
        } else if (schedule != null) {
            // 既存スケジュールの場合、スケジュール対象スピーカー自動選択処理
            const row = rows.find(
                (row) => row.speakerId === schedule.speakerId
            );
            firstSpeakerId = Number(row.speakerIds[0]);
            setSchedulTargets(row.speakerIds.map((id) => Number.parseInt(id)));
        }

        // 先頭のスピーカーから音源取得
        const params = {
            speakerId: firstSpeakerId,
            userType: OFFICE_USER_TYPE.ADMIN,
        };

        this.SchedulerSetting.current.init(schedule);
        this.settings.style.height = maxHeight;

        const data = await getSoundSources(params);
        // 取得成功の場合
        if (data !== null) {
            this.setState({ soundSources: data.soundSources });
        } else {
            // 取得失敗の場合、アラートモーダル表示
            await this.setAlertModalContent(
                DISP_RESOURCE.SCHEDULE_FAILED_GET_SOUND_SOURCES_ALERT
            );
            this.ScheduleAlertModal.current.init();
            return;
        }
    };

    /**
     * スケジュール詳細画面非表示
     */
    closeSettings = () => {
        const { setSchedulTargets } = this.props;
        setSchedulTargets([]);
        this.setState({ soundSources: [] });
        this.settings.style.height = settingRndMinHeight;
    };

    /**
     * スケジュール表示時間を今日に変更
     */
    setSchedulerTimeToday = () => {
        const { visibleType } = this.state;

        switch (visibleType) {
            case SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS:
                // 12時間
                moment().subtract('24', 'hour').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_DAY:
                // 1日
                moment().subtract('1', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS:
                // 7日
                moment().subtract('7', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_WEEK:
                // 1週
                moment().subtract('1', 'week').valueOf();
                break;
            default:
                break;
        }
        
        this.setState({ visibleTime: moment().valueOf() });
    }

    /**
     * スケジューラー表示を前の時間に変更
     */
    prevTimes = () => {
        const { visibleType, visibleTime } = this.state;

        const time = moment(visibleTime);

        switch (visibleType) {
            case SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS:
                // 12時間
                time.subtract('24', 'hour').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_DAY:
                // 1日
                time.subtract('1', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS:
                // 7日
                time.subtract('7', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_WEEK:
                // 1週
                time.subtract('1', 'week').valueOf();
                break;
            default:
                break;
        }

        const now = moment();
        if (
            (visibleType === SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS ||
                visibleType === SCHEDULE_VISIBLE_TYPE.ONE_DAY) &&
            now.format('YYMMDD') === time.format('YYMMDD')
        ) {
            // 表示タイプが12時間,1日で、表示時間が当日の場合、現在時刻を設定
            // （当日以外を設定している間、表示時間を30秒ごとに更新しないため）
            this.setState({ visibleTime: now.valueOf() });
        } else if (
            (visibleType === SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS ||
                visibleType === SCHEDULE_VISIBLE_TYPE.ONE_WEEK) &&
            (now.format('YYMMDD') === time.format('YYMMDD') ||
                (now.diff(time, 'hours') >= -166 &&
                    now.diff(time, 'hours') <= 0))
        ) {
            // 表示タイプが7日, 1週間で、表示時間が当日~6日後の場合、現在時刻を設定
            // （当日以外を設定している間、表示時間を30秒ごとに更新しないため）
            this.setState({ visibleTime: now.valueOf() });
        } else {
            // 上記以外の場合変更した時間を適用
            this.setState({ visibleTime: time.valueOf() });
        }
    };

    /**
     * スケジューラー表示を次の時間に変更
     */
    nextTimes = () => {
        const { visibleType, visibleTime } = this.state;

        const time = moment(visibleTime);

        switch (visibleType) {
            case SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS:
                // 12時間
                time.add('24', 'hour').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_DAY:
                // 1日
                time.add('1', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS:
                // 7日
                time.add('7', 'day').valueOf();
                break;
            case SCHEDULE_VISIBLE_TYPE.ONE_WEEK:
                // 1週
                time.add('1', 'week').valueOf();
                break;
            default:
                break;
        }

        const now = moment();
        if (
            (visibleType === SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS ||
                visibleType === SCHEDULE_VISIBLE_TYPE.ONE_DAY) &&
            now.format('YYMMDD') === time.format('YYMMDD')
        ) {
            // 表示タイプが12時間,1日で、表示時間が当日の場合、現在時刻を設定
            // （当日以外を設定している間、表示時間を30秒ごとに更新しないため）
            this.setState({ visibleTime: now.valueOf() });
        } else if (
            (visibleType === SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS ||
                visibleType === SCHEDULE_VISIBLE_TYPE.ONE_WEEK) &&
            (now.format('YYMMDD') === time.format('YYMMDD') ||
                (now.diff(time, 'hours') >= -166 &&
                    now.diff(time, 'hours') <= 0))
        ) {
            // 表示タイプが7日, 1週間で、表示時間が当日~6日後の場合、現在時刻を設定
            // （当日以外を設定している間、表示時間を30秒ごとに更新しないため）
            this.setState({ visibleTime: now.valueOf() });
        } else {
            // 上記以外の場合変更した時間を適用
            this.setState({ visibleTime: time.valueOf() });
        }
    };

    /**
     * スケジューラー表示開始時間設定ポップの表示切替
     */
    toggleTimepop = () => {
        const { isOpenHourPop, visibleHour, visibleType } = this.state;

        if (visibleType !== SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS) return;
        this.setState({ isOpenHourPop: !isOpenHourPop, hour: visibleHour });
    };

    /**
     * スケジューラー表示開始時刻更新
     */
    setVisibleHour = () => {
        this.setState({ visibleHour: this.state.hour });
        document.cookie = COOKIE_KEY.SCHEDULE_START_TIME + '=' + this.state.hour + "; max-age=31536000";
        this.toggleTimepop();
    };

    /**
     * 音圧スイッチトーグル
     */
    toggleSoundPressure = () => {
        const { soundPressureFlag, setSoundPressureFlag } = this.props;
        setSoundPressureFlag(!soundPressureFlag);
    };

    /**
     * ミュート
     */
    onClickMuteBtn = () => {
        const { isMute } = this.state;
        const { schedulTargets, setMute } = this.props;

        if (!isMute && schedulTargets.length === 0) return;

        this.setState({ isMute: !isMute });
        setMute(!isMute);
    };

    /**
     * 選択されたスピーカーに対してテスト再生を開始する
     * @returns 実行結果
     */
    startTestPlay = async () => {
        const { userId, baseId, floorId, schedulTargets } = this.props;
        const data = {
            userId,
            floorId,
            speakerIdList: schedulTargets,
            soundSourceNo: EX_FILE.TEST_SOUND_SOURCE_ID,
        };
        const response = await postStartTestPlayFloor(data);

        if (response.status === 200) {
            // ログ保存
            for (let d of response.data.speakerInfo) {
                const logData = {
                    baseId: baseId,
                    speakerId: d.speakerId,
                    identifier: CREATE_LOG_IDENTIFIER.TEST_PLAY_ON,
                    result: d.result,
                    data: '-',
                };
                LOG_API.postCreateManagerLog(logData);
            }
        }

        return response.data;
    };

    /**
     * 選択されたスピーカーに対してテスト再生を終了する
     * @returns 実行結果
     */
    stopTestPlay = async () => {
        const { userId, baseId, floorId, schedulTargets } = this.props;
        const data = {
            userId,
            floorId,
            speakerIdList: schedulTargets,
            soundSourceNo: EX_FILE.TEST_SOUND_SOURCE_ID,
        };
        const response = await postStopTestPlayFloor(data);

        if (response.status === 200) {
            // ログ保存
            for (let d of response.data.speakerInfo) {
                const logData = {
                    baseId: baseId,
                    speakerId: d.speakerId,
                    identifier: CREATE_LOG_IDENTIFIER.TEST_PLAY_OFF,
                    result: d.result,
                    data: '-',
                };
                LOG_API.postCreateManagerLog(logData);
            }
        }

        return response.data;
    };

    /**
     * スケジュール高さリサイジング終了時にサイドバー、スピーカーリストの高さ調整
     */
    resizeStop = () => {
        this.props.resize(
            document.getElementsByClassName('scheduler_div_parent')[0]
                .firstChild.style.height
        );
        this.setState({ isDragging: false });
    };

    /**
     * スケジュール高さリサイジング中にサイドバー、スピーカーリストの高さ調整
     */
    resizing = () => {
        this.props.resize(
            document.getElementsByClassName('scheduler_div_parent')[0]
                .firstChild.style.height
        );
    };

    /**
     * 複数対象スピーカー音量変更（スケジュール表示領域ボタン用）
     * @param {number} speakerId
     * @param {number} volume
     * @returns
     */
    changeVolumeTargets = async (isIncrement) => {
        const {
            schedulTargets,
            speakers,
            incrementSpeakerVolume,
            decrementSpeakerVolume,
        } = this.props;

        const targets = speakers.filter((speaker) =>
            schedulTargets.includes(speaker.id)
        );

        for (const speaker of targets) {
            if (isIncrement) {
                await incrementSpeakerVolume(speaker.id, speaker.volume);
            } else {
                await decrementSpeakerVolume(speaker.id, speaker.volume);
            }
        }
    };

    /**
     * 選択中のスピーカーがミュート中かどうか
     * @return {boolean} 選択中のスピーカーが一つでもミュートの場合true, それ以外の場合false
     */
    isMuteTargets = () => {
        const { schedulTargets, speakers } = this.props;

        const targets = speakers.filter((speaker) =>
            schedulTargets.includes(speaker.id)
        );

        for (const speaker of targets) {
            if (speaker.isMute) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {
            speakers,
            groups,
            schedulTargets,
            soundPressureFlag,
            speakerListFlag,
        } = this.props;
        const {
            rows,
            schedules,
            hour,
            visibleHour,
            visibleTime,
            visibleType,
            soundSources,
            isOpenHourPop,
            isMute,
            alertModalContent,
        } = this.state;

        return (
            <>
                <div
                    className={classNames(
                        'mute_alert_div',
                        isMute && !speakerListFlag ? '' : 'mute_off_alert_div'
                    )}
                >
                    <div>
                        <b>{DISP_RESOURCE.ON_MUTE}</b>
                    </div>
                </div>
                <div className={'scheduler_div_parent'}>
                    {/* スケジュールバー */}
                    <Rnd
                        className={classNames(
                            'scheduler_div',
                            this.state.isDragging ? '' : 'smooth'
                        )}
                        default={{
                            width: '1920px',
                            height: 0,
                        }}
                        maxHeight={maxHeight}
                        enableResizing={{ top: true }}
                        disableDragging={false}
                        onResize={this.resizing}
                        onResizeStart={() =>
                            this.setState({ isDragging: true })
                        }
                        onResizeStop={this.resizeStop}
                        bounds="parents"
                    >
                        <div
                            className="scheduler_content"
                            onMouseDown={(e) => {
                                // マウスホイールクリック禁止
                                if (e.button === 1) {
                                    e.preventDefault();
                                }
                            }}
                        >
                            <div className="scheduler_top">
                                <div className="scheduler_top_left">
                                    {DISP_RESOURCE.SPEAKER_SCHEDULE}
                                </div>
                                <div className="scheduler_top_content">
                                    {(visibleType ===
                                        SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS ||
                                        visibleType ===
                                            SCHEDULE_VISIBLE_TYPE.ONE_DAY) && (
                                        <div className="scheduler_top_disp_date">
                                            {moment(visibleTime).format(
                                                DISP_RESOURCE.SCHEDULE_DATE_LABEL_FORMAT
                                            )}
                                        </div>
                                    )}
                                    {(visibleType === SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS) && (
                                        <div className="scheduler_top_disp_date">
                                            {moment(visibleTime).format(
                                                DISP_RESOURCE.SCHEDULE_YEAR_LABEL_FORMAT
                                            )}
                                        </div>
                                    )}
                                    {(visibleType === SCHEDULE_VISIBLE_TYPE.ONE_WEEK) && (
                                        <div className="scheduler_top_disp_date">
                                            {moment(visibleTime).startOf('week').format(
                                                DISP_RESOURCE.SCHEDULE_YEAR_LABEL_FORMAT
                                            )}
                                        </div>
                                    )}
                                    <img
                                        className="scheduler_top_con_btn"
                                        alt="left"
                                        src={
                                            require('../../img/left_arrow_d.svg')
                                                .default
                                        }
                                        onClick={this.prevTimes}
                                    />
                                    <Input
                                        type="select"
                                        className="scheduler_period_selecter"
                                        value={visibleType}
                                        onChange={(e) =>
                                            this.setSchedulerTime(
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option
                                            value={
                                                SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS
                                            }
                                        >
                                            {DISP_RESOURCE.TWELVE_HOURS}
                                        </option>
                                        <option
                                            value={
                                                SCHEDULE_VISIBLE_TYPE.ONE_DAY
                                            }
                                        >
                                            {DISP_RESOURCE.ONE_DAY}
                                        </option>
                                        <option
                                            value={
                                                SCHEDULE_VISIBLE_TYPE.SEVEN_DAYS
                                            }
                                        >
                                            {DISP_RESOURCE.SEVEN_DAYS}
                                        </option>
                                        <option
                                            value={
                                                SCHEDULE_VISIBLE_TYPE.ONE_WEEK
                                            }
                                        >
                                            {DISP_RESOURCE.ONE_WEEK}
                                        </option>
                                    </Input>
                                    <img
                                        className="scheduler_top_con_btn"
                                        alt="right"
                                        src={
                                            require('../../img/right_arrow_d.svg')
                                                .default
                                        }
                                        onClick={this.nextTimes}
                                    />
                                    <Button
                                        type="button"
                                        className="today_set_btn"
                                        color="success"
                                        outline
                                        onClick={this.setSchedulerTimeToday}
                                    >
                                        {DISP_RESOURCE.TODAY}
                                    </Button>
                                </div>
                                <div className="scheduler_top_right">
                                    <SettingImg
                                        className={classNames(
                                            'time_set_pop_btn',
                                            visibleType !==
                                                SCHEDULE_VISIBLE_TYPE.TWELVE_HOURS
                                                ? 'disabled'
                                                : ''
                                        )}
                                        id="Popover"
                                    ></SettingImg>
                                    <Popover
                                        popperClassName="time_set_pop"
                                        innerClassName="time_set_inner"
                                        fade={false}
                                        hideArrow={true}
                                        placement="bottom-end"
                                        target="Popover"
                                        trigger="legacy"
                                        isOpen={isOpenHourPop}
                                        toggle={this.toggleTimepop}
                                    >
                                        <div className="time_set_content">
                                            <p>
                                                {
                                                    DISP_RESOURCE.SCHEDULE_POP_CONTENT
                                                }
                                            </p>
                                            <p>
                                                <Input
                                                    type="select"
                                                    value={hour}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            hour: e.target
                                                                .value,
                                                        });
                                                    }}
                                                >
                                                    {(() => {
                                                        const options = [];
                                                        for (
                                                            let i = 0;
                                                            i < 24;
                                                            i++
                                                        ) {
                                                            const time =
                                                                i < 10
                                                                    ? '0' + i
                                                                    : i;
                                                            options.push(
                                                                <option
                                                                    key={
                                                                        'hour_' +
                                                                        i
                                                                    }
                                                                    value={time}
                                                                >
                                                                    {i}
                                                                    {
                                                                        DISP_RESOURCE.HOUR
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                        return options;
                                                    })()}
                                                </Input>
                                            </p>
                                            <p className="time_set_btns">
                                                <Button
                                                    color="success"
                                                    outline
                                                    onClick={this.toggleTimepop}
                                                >
                                                    {DISP_RESOURCE.CANCEL}
                                                </Button>
                                                <Button
                                                    color="success"
                                                    onClick={
                                                        this.setVisibleHour
                                                    }
                                                >
                                                    {DISP_RESOURCE.SETTING}
                                                </Button>
                                            </p>
                                        </div>
                                    </Popover>
                                </div>
                            </div>
                            <CustomTimeline
                                rows={rows}
                                schedules={schedules}
                                visibleHour={visibleHour}
                                visibleTime={visibleTime}
                                visibleType={visibleType}
                                showSettings={this.showSettings}
                            />
                        </div>
                        <div className="scheduler_right_side">
                            <p>{DISP_RESOURCE.VOLUME_CONTROL}</p>
                            <Row>
                                <Col sm={5}>
                                    <p>
                                        <img
                                            alt="mute"
                                            className="img_btn"
                                            src={
                                                isMute
                                                    ? require('../../img/mute_off.svg')
                                                          .default
                                                    : require('../../img/mute_on.svg')
                                                          .default
                                            }
                                            onClick={this.onClickMuteBtn}
                                        />
                                    </p>
                                    {isMute
                                        ? DISP_RESOURCE.MUTE_OFF
                                        : DISP_RESOURCE.MUTE}
                                </Col>
                                <Col sm={7} style={{ paddingLeft: 0 }}>
                                    <div 
                                        className="volume_down"
                                        alt="volume_down"
                                        onClick={() =>
                                            this.changeVolumeTargets(false)
                                        }
                                        >
                                    </div>
                                    <img
                                        alt="volume"
                                        src={
                                            require('../../img/speaker_normal.svg')
                                                .default
                                        }
                                    />
                                    <div 
                                        className="volume_up"
                                        alt="volume_up"
                                        onClick={() =>
                                            this.changeVolumeTargets(true)
                                        }
                                        >
                                    </div>
                                </Col>
                            </Row>
                            {/* 音圧スイッチ */}
                            <Row>
                                <Col sm={4} className="sound_pressure_label">
                                    {DISP_RESOURCE.SOUND_PRESSURE}
                                </Col>
                                <Col sm={7}>
                                    <div className="custom_switch sound_pressure">
                                        <Input
                                            id="soundpress"
                                            type="checkbox"
                                            checked={soundPressureFlag}
                                            onChange={this.toggleSoundPressure}
                                        />
                                        <label htmlFor="soundpress">
                                            {soundPressureFlag && (
                                                <span className="checked switch_label">
                                                    {DISP_RESOURCE.ON}
                                                </span>
                                            )}
                                            {!soundPressureFlag && (
                                                <span className="switch_label">
                                                    {DISP_RESOURCE.OFF}
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                            <p className="test_sound">
                                <span
                                    className={classNames(
                                        'btn',
                                        'btn-secondary',
                                        schedulTargets.length === 0 ||
                                            this.isMuteTargets()
                                            ? 'test_disabled'
                                            : ''
                                    )}
                                    onClick={() => {
                                        if (
                                            schedulTargets.length > 0 &&
                                            !this.isMuteTargets()
                                        )
                                            this.SoundTestModal.current.init();
                                    }}
                                >
                                    {DISP_RESOURCE.TEST_SOUND_TITLE}
                                </span>
                            </p>
                        </div>
                    </Rnd>
                </div>

                {/* スケジュール詳細設定画面 */}
                <div className={'scheduler_setting_parent'}>
                    <Rnd
                        className={classNames(
                            'schedule_setting_div',
                            this.state.isDragging ? '' : 'smooth'
                        )}
                        default={{
                            width: '100%',
                            height: '0px',
                        }}
                        maxHeight={maxHeight}
                        enableResizing={false}
                        disableDragging={true}
                        bounds="parents"
                    >
                        <ScheduleSetting
                            ref={this.SchedulerSetting}
                            speakers={speakers}
                            rows={rows}
                            groups={groups}
                            targets={schedulTargets}
                            schedules={schedules}
                            soundSources={soundSources}
                            createSchedules={this.createSchedules}
                            updateSchedule={this.updateSchedule}
                            deleteSchedule={this.deleteSchedule}
                            close={this.closeSettings}
                        />
                    </Rnd>
                </div>
                <ModalSoundTest
                    ref={this.SoundTestModal}
                    startTestPlay={this.startTestPlay}
                    stopTestPlay={this.stopTestPlay}
                />
                <ModalMessage
                    ref={this.ScheduleAlertModal}
                    title={''}
                    bodyClass="schedule_alert_modal_body"
                    content={alertModalContent}
                    okString={DISP_RESOURCE.OK}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
            </>
        );
    }
}

export default Scheduler;
