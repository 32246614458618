import DropBoxSpeaker from './area/DropboxSpeaker';

import FloorMap from '../../img/default_floor_map.png';

/**
 * フロアエリアコンテンツ
 * @param {object} props - コンポーネント呼び出し側から渡される値
 * @param {number} props.areaWidth - フロア横実寸
 * @param {string} props.areaImage - フロア画像
 * @param {object[]} props.speakers - スピーカー情報リスト
 * @param {object[]} props.baseSpeakers - 拠点スピーカー一覧
 * @param {number} props.currentSpeaker - 選択中のスピーカーID
 * @param {number} props.currentGroup - 選択中のグループID
 * @param {boolean} props.soundPressureFlag - 音圧表示フラグ
 * @param {object} props.scheduleInfo - スケジュール処理情報
 * @param {function} props.setAreaImage - フロア画像設定処理(画面表示用)
 * @param {function} props.createSpeaker - スピーカー情報追加処理
 * @param {function} props.updateSpeaker - スピーカー情報更新処理
 * @param {function} props.selectSpeaker - スピーカー選択処理
 * @param {function} props.modalOpen - モーダル表示処理
 */
const Area = (props) => {
    /**
     * エリア画像ありの場合、スピーカー配置用ボックスを表示
     */
    const MapDiv = () => {
        return (
            <div>
                <div className="map_div">
                    {/* スピーカードロップボックス */}
                    <DropBoxSpeaker
                        areaWidth={props.areaWidth}
                        areaImage={props.areaImage}
                        speakers={props.speakers}
                        baseSpeakers={props.baseSpeakers}
                        currentSpeaker={props.currentSpeaker}
                        currentGroup={props.currentGroup}
                        createSpeaker={props.createSpeaker}
                        updateSpeaker={props.updateSpeaker}
                        selectSpeaker={props.selectSpeaker}
                        modalOpen={props.modalOpen}
                        mode={props.mode}
                        soundPressureFlag={props.soundPressureFlag}
                        scheduleInfo={props.scheduleInfo}
                    ></DropBoxSpeaker>
                </div>
            </div>
        );
    };

    /**
     * エリア画像なしの場合、指定の画像を表示
     * @returns
     */
    const NullMapDiv = () => {
        return (
            <div className="map_div">
                <img src={FloorMap} alt="defaultFloorMap" />
            </div>
        );
    };

    return (
        <>
            {/* 画像なし：指定の画像
            画像あり：スピーカードロップボックス*/}
            {props.areaImage === null ? <NullMapDiv /> : <MapDiv />}
        </>
    );
};

export default Area;
