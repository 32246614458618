import React from 'react';
import { Button } from 'reactstrap';
import DISP_RESOURCE from '../../common/dispResource';
import { MODE, CREATE_LOG_IDENTIFIER, MODAL } from '../../common/constants';
import { getSurveyInfo } from '../../api/userApi';
import { RESPONSE_STATUS } from '../../api/apiContants';

/**
 * アンケート回答コンテンツ
 * @param {object} props - コンポーネント呼び出し側から渡される値
 * @param {function} props.setMode - 画面状態の設定変更
 */
class Survey extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * アンケートURL
             * @type {string}
             */
            url: '',
        };
    }

    async componentDidMount() {
        const response = await getSurveyInfo(
            this.props.baseId,
            this.props.speakerId
        );

        if (response && response.status === RESPONSE_STATUS.UNAUTHORIZED) {
            this.props.modalOpen(MODAL.SESSION_EXPIRED);
            return;
        }
        this.setState({
            url: response.data.url,
        });
        this.props.createLog(CREATE_LOG_IDENTIFIER.SURVEY_ACCESS, true, '-');
    }

    /**
     * オフィス利用者画面に戻る
     */
    toUserApp = () => {
        this.props.setMode(MODE.SPEAKER_OPERATION);
    };

    /**
     * 画面描画
     * @returns
     */
    render() {
        return (
            <div className="mx-3">
                <Button
                    color="success"
                    onClick={this.toUserApp}
                    className="mb-2 w-100"
                >
                    {DISP_RESOURCE.TO_USER_APP}
                </Button>
                <div id="iframeWrapper" className="iframe_wrapper">
                    <iframe
                        id="iframe"
                        title="survey"
                        src={this.state.url}
                        width="640"
                    >
                        {DISP_RESOURCE.LOAD_SURVEY}
                    </iframe>
                </div>
                <Button
                    color="success"
                    onClick={this.toUserApp}
                    className="mt-2 w-100"
                >
                    {DISP_RESOURCE.TO_USER_APP}
                </Button>
            </div>
        );
    }
}

export default Survey;
