import {
    ITEM_TYPES,
    MODAL,
    MODE,
    SPEAKER_TYPE,
    SCHEDULE_COLORS,
    SPEAKER_ICON_SIZE,
} from '../../../common/constants';
import { useDrag } from 'react-dnd';

import { ReactComponent as SpDef } from '../../../img/spDef_s_u.svg';
import { ReactComponent as SpDefSel } from '../../../img/spDef_s_s.svg';
import { ReactComponent as SpDefMute } from '../../../img/spDef_m_s_u.svg';
import { ReactComponent as SpDefMuteSel } from '../../../img/spDef_m_s_s.svg';
import { ReactComponent as SpDefErr } from '../../../img/spDef_e_u.svg';
import { ReactComponent as SpDefErrSel } from '../../../img/spDef_e_s.svg';

import { ReactComponent as SpGreen } from '../../../img/spGreen_s_u.svg';
import { ReactComponent as SpGreenSel } from '../../../img/spGreen_s_s.svg';
import { ReactComponent as SpGreenMute } from '../../../img/spGreen_m_s_u.svg';
import { ReactComponent as SpGreenMuteSel } from '../../../img/spGreen_m_s_s.svg';
import { ReactComponent as SpGreenErr } from '../../../img/spGreen_e_u.svg';
import { ReactComponent as SpGreenErrSel } from '../../../img/spGreen_e_s.svg';

import { ReactComponent as SpTable } from '../../../img/spTable_s_u.svg';
import { ReactComponent as SpTableSel } from '../../../img/spTable_s_s.svg';
import { ReactComponent as SpTableMute } from '../../../img/spTable_m_s_u.svg';
import { ReactComponent as SpTableMuteSel } from '../../../img/spTable_m_s_s.svg';
import { ReactComponent as SpTableErr } from '../../../img/spTable_e_u.svg';
import { ReactComponent as SpTableErrSel } from '../../../img/spTable_e_s.svg';

import { ReactComponent as SpDesk } from '../../../img/spDesk_s_u.svg';
import { ReactComponent as SpDeskSel } from '../../../img/spDesk_s_s.svg';
import { ReactComponent as SpDeskMute } from '../../../img/spDesk_m_s_u.svg';
import { ReactComponent as SpDeskMuteSel } from '../../../img/spDesk_m_s_s.svg';
import { ReactComponent as SpDeskErr } from '../../../img/spDesk_e_u.svg';
import { ReactComponent as SpDeskErrSel } from '../../../img/spDesk_e_s.svg';

import { ReactComponent as SpPanel } from '../../../img/spPanel_s_u.svg';
import { ReactComponent as SpPanelSel } from '../../../img/spPanel_s_s.svg';
import { ReactComponent as SpPanelMute } from '../../../img/spPanel_m_s_u.svg';
import { ReactComponent as SpPanelMuteSel } from '../../../img/spPanel_m_s_s.svg';
import { ReactComponent as SpPanelErr } from '../../../img/spPanel_e_u.svg';
import { ReactComponent as SpPanelErrSel } from '../../../img/spPanel_e_s.svg';

import { ReactComponent as SpSpot } from '../../../img/spSpot_s_u.svg';
import { ReactComponent as SpSpotSel } from '../../../img/spSpot_s_s.svg';
import { ReactComponent as SpSpotMute } from '../../../img/spSpot_m_s_u.svg';
import { ReactComponent as SpSpotMuteSel } from '../../../img/spSpot_m_s_s.svg';
import { ReactComponent as SpSpotErr } from '../../../img/spSpot_e_u.svg';
import { ReactComponent as SpSpotErrSel } from '../../../img/spSpot_e_s.svg';

/**
 * スピーカーアイテム
 * @param {number} id - スピーカーID
 * @param {number} left - スピーカーの表示位置（横）
 * @param {number} top - スピーカーの表示位置（縦）
 * @param {object} speakerInfo - スピーカー情報
 * @param {function} selectSpeaker - スピーカー選択処理
 * @param {number} currentSpeaker - 選択中のスピーカーID
 * @param {number} currentGroup - 選択中のグループID
 * @param {function} modalOpen - モーダル表示処理
 * @param {string} mode - 画面の表示状態
 * @param {object} scheduleInfo - スケージュール画面処理用情報
 */
const ItemAreaSpeaker = ({
    id,
    left,
    top,
    speakerInfo,
    selectSpeaker,
    currentSpeaker,
    currentGroup,
    modalOpen,
    mode,
    scheduleInfo,
}) => {
    const { schedulTargets, putScheduleTargets, removeScheduleTargets } =
        scheduleInfo;
    const [{ isDragging }, drag] = useDrag(
        () => ({
            // ドロップタイプ
            type: ITEM_TYPES.AREA_SPEAKER,
            // ドロップ情報
            item: {
                id,
                left,
                top,
                speakerInfo,
            },
            //  監視
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [id, left, top]
    );

    /**
     * スピーカーが選択されているか
     * 設定モードの場合は前半、運用モードの場合は後半の条件式で判定される
     * @type {boolean}
     */
    const isSelected =
        (currentSpeaker
            ? currentSpeaker.findIndex((speaker) => speaker === id) !== -1
            : false) ||
        (mode === MODE.SET_SCHEDULES && schedulTargets.includes(id));

    /**
     * 該当スピーカーが選択したグループに所属しているか
     * @type {boolean}
     */
    const isGroupSelected =
        currentGroup !== null && currentGroup === speakerInfo.groupId;

    /**
     * スピーカー選択処理
     * @param {Event} e - イベントオブジェクト
     */
    const select = (e) => {
        e.stopPropagation();
        selectSpeaker(id);
    };

    /**
     * スケジュールスピーカー選択処理
     * @param {Event} e - イベントオブジェクト
     */
    const schedulTargetselect = (e) => {
        e.stopPropagation();
        if (schedulTargets.includes(id)) {
            removeScheduleTargets([id]);
        } else {
            putScheduleTargets([id]);
        }
    };

    /**
     * 選択したスピーカーの詳細設定モーダルを表示
     */
    const openDetailSpeaker = () => {
        selectSpeaker(id);
        modalOpen(MODAL.DETAIL_SPEAKER, {
            arrayName: 'speakers',
            id: id,
            isFirst: false,
        });
    };

    // ドラッグ時表示切替
    if (isDragging) {
        return <div ref={drag} />;
    }

    // スピーカーアイコン
    const Icon = () => {
        if (speakerInfo.isDelete) {
            // 削除したアイコンは表示しない
            return <></>;
        }

        const classname = `spicon_${id}`;
        let iconColor = '#666';

        // スピーカー情報から現在のカラーを取得
        if (speakerInfo.colorId !== null && speakerInfo.colorId !== undefined) {
            iconColor = SCHEDULE_COLORS.find(
                (color) => color.id === speakerInfo.colorId
            ).hex;
        }

        // 再生中スケジュールカラー適用
        const css = `
      .${classname} {
        display: block;
        width: ${SPEAKER_ICON_SIZE}px;
        height: ${SPEAKER_ICON_SIZE}px;
      }
      .${classname} > g > circle,
      .${classname} > g > path:nth-child(1) {
        fill: ${iconColor};
      }
      `;

        let icon = <SpDef className={classname}></SpDef>;

        switch (speakerInfo.speakerTypeId) {
            // フェイクグリーン 植栽型アイコン
            case SPEAKER_TYPE.FAKEGREEN:
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpGreenErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpGreenErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpGreenErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpGreenMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpGreenMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpGreenMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpGreenSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpGreenSel className={`${classname} group_selected`} />
                    ) : (
                        <SpGreen className={classname} />
                    );
                    break;
                }

            // テーブル型アイコン
            case SPEAKER_TYPE.TABLE:
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpTableErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpTableErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpTableErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpTableMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpTableMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpTableMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpTableSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpTableSel className={`${classname} group_selected`} />
                    ) : (
                        <SpTable className={classname} />
                    );
                    break;
                }

            // デスク型アイコン
            case SPEAKER_TYPE.DESC:
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpDeskErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDeskErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpDeskErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpDeskMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDeskMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpDeskMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpDeskSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDeskSel className={`${classname} group_selected`} />
                    ) : (
                        <SpDesk className={classname} />
                    );
                    break;
                }

            // パネル型アイコン
            case SPEAKER_TYPE.PANEL:
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpPanelErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpPanelErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpPanelErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpPanelMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpPanelMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpPanelMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpPanelSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpPanelSel className={`${classname} group_selected`} />
                    ) : (
                        <SpPanel className={classname} />
                    );
                    break;
                }

            // スポットライト型アイコン
            case SPEAKER_TYPE.SPOTLIGHT:
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpSpotErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpSpotErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpSpotErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpSpotMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpSpotMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpSpotMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpSpotSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpSpotSel className={`${classname} group_selected`} />
                    ) : (
                        <SpSpot className={classname} />
                    );
                    break;
                }

            // 普通型アイコン
            default: {
                if (speakerInfo.isFailure && mode === MODE.SET_SCHEDULES) {
                    // 不具合発生時
                    icon = isSelected ? (
                        <SpDefErrSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDefErrSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpDefErr className={classname} />
                    );
                    break;
                } else if (speakerInfo.isMute && mode === MODE.SET_SCHEDULES) {
                    // ミュート時
                    icon = isSelected ? (
                        <SpDefMuteSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDefMuteSel
                            className={`${classname} group_selected`}
                        />
                    ) : (
                        <SpDefMute className={classname} />
                    );
                    break;
                } else {
                    // 正常
                    icon = isSelected ? (
                        <SpDefSel className={classname} />
                    ) : isGroupSelected ? (
                        <SpDefSel className={`${classname} group_selected`} />
                    ) : (
                        <SpDef className={classname} />
                    );
                    break;
                }
            }
        }

        return (
            <>
                <style>{css}</style>
                {icon}
            </>
        );
    };

    return (
        <>
            <div
                title={mode === MODE.SET_SPEAKERS ? '' : speakerInfo.name}
                ref={drag}
                className="speaker_item"
                style={{
                    left: left,
                    top: top,
                }}
                onClick={(e) => {
                    /* スピーカー設定画面の場合、詳細設定モーダルを表示
              グループ設定画面の場合、スピーカーを選択状態にする */
                    if (mode === MODE.SET_SPEAKERS) openDetailSpeaker(e);
                    else if (mode === MODE.SET_GROUPS) select(e);
                    else if (mode === MODE.SET_SCHEDULES)
                        schedulTargetselect(e);
                }}
                onDragStart={(e) => {
                    /* スピーカー設定画面でない場合、ドラッグを無効化 */
                    if (mode !== MODE.SET_SPEAKERS) e.preventDefault();
                }}
            >
                {/*動的画像*/}
                <Icon />
            </div>
        </>
    );
};

export default ItemAreaSpeaker;
