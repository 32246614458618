import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import DISP_RESOURCE from '../../../common/dispResource';

// 画像ドロップボックス(react-dnd：useDrop)
const DropboxArea = (props) => {
    const { onDrop, tempAreaImage } = props;
    const [{ canDrop, isOver }, drop] = useDrop(
        () => ({
            // ドロップ対象
            accept: [NativeTypes.FILE],
            // ボックスへドロップで発火
            drop(item) {
                if (onDrop) {
                    onDrop(item);
                }
            },
            // ボックスの状態監視
            collect: (monitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                };
            },
        }),
        [props]
    );
    // 表示文字切替用フラグ
    const isActive = canDrop && isOver;
    const parse = require('html-react-parser');

    const mapStyle = {
        backgroundImage: `url(${tempAreaImage})`,
    };

    return (
        <div ref={drop} className="map_drop" style={mapStyle}>
            {isActive && !tempAreaImage && DISP_RESOURCE.AREA_DND_DRAGGING}
            {!isActive &&
                !tempAreaImage &&
                parse(DISP_RESOURCE.AREA_DND_DESCRIPTION)}
        </div>
    );
};

export default DropboxArea;
