import React, { createRef } from 'react';
import update from 'immutability-helper';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter,
    Label,
    Col,
    Row,
    FormFeedback,
} from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';
import HTMLReactParser from 'html-react-parser';

import {
    EXTERNAL_FILE_CONTENTS,
    IP_DEVICE_TYPE,
    MODAL,
    QRCODE_DOWNLOAD_SETTING,
    BASE_SPEAKER,
    FORM_MAX_LENGTH,
    CREATE_LOG_IDENTIFIER,
} from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';
import {
    checkSpeakerConnection,
    playSpeaker,
    stopSpeaker,
    settingSpeaker,
} from '../../../api/speakerApi';
import { postCreateManagerLog } from '../../../api/logApi';

import { ReactComponent as Recyclebin } from '../../../img/recycle_bin.svg';
import { ReactComponent as SpDef } from '../../../img/spDef_s_u.svg';
import { ReactComponent as DownloadImg } from '../../../img/download.svg';
import ModalMessage from './ModalMessage';
import classNames from 'classnames';

/**
 * スピーカー詳細設定モーダル
 */
class ModalSpeaker extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} updateSpeaker - フロアマップに紐づくスピーカーの設定情報更新処理
     * @param {function} deleteSpeaker - フロアマップに紐づくスピーカーの設定情報削除処理
     * @param {function} modalOpen - モーダル画面表示処理
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * モーダル表示有無
             * @type {boolean}
             */
            modal: false,
            /**
             * フロアマップ情報に紐づいているスピーカーID
             * @type {number}
             */
            id: 0,
            /**
             * 選択中の拠点スピーカー名
             * @type {string}
             */
            selectSpeaker: '',
            /**
             * 選択中の拠点スピーカーID
             */
            selectSpeakerId: -1,
            /**
             * 選択中のスピーカーのIPアドレス
             * @type {string}
             */
            ipAddress: '',
            /**
             * 選択中のスピーカーに自身で付ける名前
             * @type {string}
             */
            name: '',
            /**
             * 選択中のスピーカーのスピーカータイプ
             * @type {string}
             */
            speakerDirectionType: '',
            /**
             * 選択中のスピーカーの音量
             * @type {number}
             */
            basicVolume: EXTERNAL_FILE_CONTENTS.DEFAULT_VOLUME,
            /**
             * 選択中のスピーカーの角度（指向性スピーカーのみ使用、0度で右向き）
             * @type {number}
             */
            degree: -1,
            /**
             * スピーカーとの接続確認結果
             * @type {string | null}
             */
            testAccess: '',
            /**
             * スピーカーがテスト再生中かどうか
             * @type {boolean}
             */
            testPlay: false,
            /**
             * 個室設置のスピーカーかどうか
             * @type {boolean}
             */
            isPrivateRoom: false,
            /**
             * オフィスSS利用者GUIにアクセスするためのQRコード
             * @type {string}
             */
            qrcode: '',
            /**
             * スピーカー種別ID(植栽型、テーブル型、パネル型等の種別)
             * @type {number}
             */
            speakerTypeId: -1,
            /**
             * 拠点スピーカーリスト
             * @type {object}
             */
            speakerList: this.props.baseSpeakers,
            /**
             * 初回表示(スピーカー追加時にモーダル表示している)かどうかのフラグ
             */
            isFirst: false,
            /**
             * stateのselectSpeakerに関するエラー
             * @type {string}
             */
            errorSelectSpeaker: null,
            /**
             * stateのnameに関するエラー
             * @type {string}
             */
            errorSpeakerName: null,
            /**
             * テスト再生・停止APIの結果
             */
            testPlayText: '',
        };

        // 連打防止用
        this.isTest = false;

        this[MODAL.DELETE_SPEAKER] = createRef();
        this[MODAL.CHOICE_SAVE_FLOOR] = createRef();
    }

    // ------------------------------state更新------------------------------
    /**
     * 選択中のスピーカーを更新
     * @param {number} selectSpeakerId - 選択したスピーカー機器ID
     */
    setSelectSpeaker = (selectSpeakerId) => {
        // 拠点スピーカーリストから選択された拠点スピーカーのインデックスを取得
        const idx = this.state.speakerList.findIndex(
            (row) => row[BASE_SPEAKER.BASE_SPEAKER_ID] == selectSpeakerId
        );

        // テスト再生中なら止める
        if (this.state.testPlay) {
            this.setTestPlay();
        }

        if (idx !== '' && idx !== -1) {
            // 拠点スピーカー情報をもとにスピーカー情報設定
            this.setState({
                selectSpeakerId:
                    this.state.speakerList[idx][BASE_SPEAKER.BASE_SPEAKER_ID],
                selectSpeaker:
                    this.state.speakerList[idx][BASE_SPEAKER.UNIT_NAME],
                ipAddress: this.state.speakerList[idx][BASE_SPEAKER.IP_ADDRESS],
                qrcode:
                    QRCODE_DOWNLOAD_SETTING.URL +
                    QRCODE_DOWNLOAD_SETTING.QUERY.format(
                        this.state.id,
                        this.props.baseId
                    ),
            });
            this.setSpeakerDirectionType(
                this.state.speakerList[idx][BASE_SPEAKER.SPEAKER_TYPE]
            );

            if (
                this.props.checkDuplicateSpeaker(
                    this.state.speakerList[idx][BASE_SPEAKER.BASE_SPEAKER_ID],
                    this.state.id
                )
            ) {
                // 選択したスピーカー機器が別のスピーカーに設定されている場合
                this.setState({
                    errorSelectSpeaker: DISP_RESOURCE.DEPLICATE_SELECT,
                });
                return;
            } else {
                this.setState({
                    errorSelectSpeaker: null,
                });

                // 新しいスピーカーに基準音量設定を送信
                const settingData = {
                    userId: this.props.userId,
                    baseSpeakerId: selectSpeakerId,
                    basicVolume: this.state.basicVolume,
                };
                settingSpeaker(settingData);
            }
        } else {
            // スピーカー選択欄で空欄を選択した時
            this.setState({
                selectSpeakerId: -1,
                selectSpeaker: '',
                ipAddress: '',
                speakerDirectionType: '',
                qrcode: '',
                errorSelectSpeaker: null,
            });
        }
    };
    /**
     * スピーカータイプ更新
     * @param {number} speakerDirectionType - スピーカータイプ
     */
    setSpeakerDirectionType = (speakerDirectionType) => {
        this.setState({
            speakerDirectionType: IP_DEVICE_TYPE[speakerDirectionType],
        });

        if (
            IP_DEVICE_TYPE[speakerDirectionType] ===
                DISP_RESOURCE.DIRECTIONAL_SPEAKER &&
            this.state.degree === -1
        ) {
            // 前方スピーカーかつ角度が設定されていない場合、0度に設定
            this.setDegree(0);
        }
    };
    /**
     * スピーカーにつける名前の更新
     * @param {string} name - スピーカー名
     */
    setName = (name) => {
        this.setState({ name: name });
    };
    /**
     * スピーカーの音量更新
     * @param {number} volume - 音量
     */
    setVolume = (volume) => {
        // 基準音量設定をスピーカー機器に送信
        const settingData = {
            userId: this.props.userId,
            baseSpeakerId: this.state.selectSpeakerId,
            basicVolume: volume,
        };
        settingSpeaker(settingData);

        this.setState({ basicVolume: volume });
    };
    /**
     * スピーカーの角度更新
     * @param {number} degree - 角度
     */
    setDegree = (degree) => {
        this.setState({ degree: degree });
    };
    /**
     * スピーカーの接続チェック結果更新
     */
    setTestAccess = async () => {
        this.setState({
            testAccess: DISP_RESOURCE.SPEAKER_CONNECTING,
        });
        // 接続チェックAPIを呼び出し、結果を反映
        const response = await checkSpeakerConnection(
            this.props.userId,
            this.props.baseId,
            this.state.selectSpeakerId
        );
        this.setState({
            testAccess:
                response && response.result
                    ? DISP_RESOURCE.ACCESS_SUCCESS
                    : DISP_RESOURCE.ACCESS_DENIDE,
        });
    };
    /**
     * スピーカーのテスト再生有無更新
     */
    setTestPlay = async () => {
        // テスト再生・停止処理中は何もしない
        if (this.isTest) {
            return;
        }

        // 接続チェックに失敗している場合、テスト再生も失敗とする
        if (this.state.testAccess === DISP_RESOURCE.ACCESS_DENIDE) {
            this.setState({ testPlayText: DISP_RESOURCE.LOG_RESULT_FAILED });
            return;
        }

        this.isTest = true;
        this.setState({ testPlayText: DISP_RESOURCE.SPEAKER_CONNECTING });

        if (!this.state.testPlay) {
            // テスト音源再生
            const data = {
                baseSpeakerId: this.state.selectSpeakerId,
                soundSourceNo: EXTERNAL_FILE_CONTENTS.TEST_SOUND_SOURCE_ID,
                userType: 1,
                userId: this.props.userId,
            };
            // 音源再生API呼び出し
            const response = await playSpeaker(data);

            // ログ保存
            const result = response.status === 200 ? true : false;
            const logData = {
                baseId: this.props.baseId,
                baseSpeakerId: this.state.selectSpeakerId,
                identifier: CREATE_LOG_IDENTIFIER.TEST_PLAY_ON,
                result: result,
                data: '-',
            };
            postCreateManagerLog(logData);

            if (result) {
                // テスト再生成功
                this.setState({
                    testPlay: true,
                    testPlayText: DISP_RESOURCE.LOG_RESULT_SUCCESS,
                });
            } else {
                // テスト再生失敗
                this.setState({
                    testPlayText: DISP_RESOURCE.LOG_RESULT_FAILED,
                });
            }
        } else {
            // テスト音源停止
            const data = {
                baseSpeakerId: this.state.selectSpeakerId,
                soundSourceNo: EXTERNAL_FILE_CONTENTS.TEST_SOUND_SOURCE_ID,
                userId: this.props.userId,
            };
            // 音源停止API呼び出し
            const response = await stopSpeaker(data);

            // ログ保存
            const result = response.status === 200 ? true : false;
            const logData = {
                baseId: this.props.baseId,
                baseSpeakerId: this.state.selectSpeakerId,
                identifier: CREATE_LOG_IDENTIFIER.TEST_PLAY_OFF,
                result: result,
                data: '-',
            };
            postCreateManagerLog(logData);

            if (result) {
                // テスト音源停止成功
                this.setState({
                    testPlay: false,
                    testPlayText: DISP_RESOURCE.LOG_RESULT_SUCCESS,
                });
            } else {
                // テスト音源停止失敗
                this.setState({
                    testPlayText: DISP_RESOURCE.LOG_RESULT_FAILED,
                });
            }
        }
        this.isTest = false;
    };
    /**
     * 個室設置のチェックボックス更新
     */
    setisPrivateRoom = (flag) => {
        this.setState({ isPrivateRoom: flag });
    };
    /**
     * スピーカー詳細設定のモーダル表示有無更新
     * @param {boolean} flag - モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };
    /**
     * スピーカー機器リスト更新
     * @param {object} speakers - csvから取得したスピーカー機器
     */
    setSpeakerList = (speakers) => {
        speakers.map((row) => {
            // スピーカー機器の情報をリストに追加
            return this.state.speakerList.push(row);
        });
    };

    /**
     * 画面更新後処理（propsやstateの値が更新されると発動する、画面更新の後の処理）
     * @param {object} prevProps - 更新前のpropsの値(未使用)
     * @param {object} prevState - 更新前のstateの値
     */
    componentDidUpdate(prevProps, prevState) {
        if (
            this.state.modal &&
            this.state.modal !== prevState.modal &&
            this.state.selectSpeakerId !== null &&
            this.state.selectSpeakerId !== -1
        ) {
            // モーダル初期化の後、selectSpeakerが設定されている場合にスピーカー選択時の処理を行う
            this.setSelectSpeaker(this.state.selectSpeakerId);
        }
    }

    /**
     * モーダル初期化
     * @param {object} speakerInfo - スピーカー設定画面でクリックしたスピーカーの設定情報
     * @param {boolean} isFirst - 初回表示(スピーカー追加時にモーダル表示している)かどうかのフラグ
     */
    init = (speakerInfo, isFirst) => {
        // スピーカー設定画面上でクリックしたスピーカーの情報を設定
        this.setState(
            update(this.state, {
                $merge: {
                    ...speakerInfo,
                    speakerList: this.props.baseSpeakers,
                    isFirst: isFirst,
                    testPlayText: '',
                },
            })
        );

        // モーダル表示
        this.setModal(true);

        // スピーカーが選択されている場合
        if (this.state.selectSpeakerId > 0) {
            // 基準音量設定をスピーカー機器に送信
            const settingData = {
                userId: this.props.userId,
                baseSpeakerId: this.state.selectSpeakerId,
                basicVolume: this.state.basicVolume,
            };
            settingSpeaker(settingData);
        }
    };

    /**
     * モーダル閉じ
     */
    close = () => {
        // モーダル閉じる場合、再生中のテスト再生停止
        if (this.state.testPlay) {
            this.setTestPlay();
        }
        this.setState({
            modal: false,
            selectSpeaker: '',
            selectSpeakerId: -1,
            ipAddress: '',
            name: '',
            speakerDirectionType: '',
            basicVolume: EXTERNAL_FILE_CONTENTS.DEFAULT_VOLUME,
            degree: -1,
            testAccess: '',
            testPlay: false,
            isPrivateRoom: false,
            qrcode: '',
            speakerTypeId: -1,
            isFirst: false,
            errorSelectSpeaker: null,
            errorSpeakerName: null,
            testPlayText: '',
        });
    };

    /**
     * モーダルの変更反映
     */
    save = () => {
        if (this.validation()) {
            return;
        }

        // モーダル閉じる場合、再生中のテスト再生停止
        if (this.state.testPlay) {
            this.setTestPlay();
        }

        // 基準音量設定をスピーカー機器に送信
        const settingData = {
            userId: this.props.userId,
            baseSpeakerId: this.state.selectSpeakerId,
            basicVolume: this.state.basicVolume,
        };
        settingSpeaker(settingData);

        // スピーカーの設定内容更新(サーバーへの保存処理はここでは実施しない)
        const data = {
            id: this.state.id,
            selectSpeakerId: this.state.selectSpeakerId,
            ipAddress: this.state.ipAddress,
            name: this.state.name,
            deviceType: this.state.speakerDirectionType,
            isPrivateRoom: this.state.isPrivateRoom,
            basicVolume: this.state.basicVolume,
            degree: this.state.degree,
        };
        this.props.updateSpeaker(data);
        this.close();
    };

    /**
     * スピーカー選択欄に表示するスピーカー機器の設定
     */
    selectSpeakerOption = () => {
        let option = [];
        for (let idx = 0; idx < this.state.speakerList.length; idx++) {
            if (
                this.state.speakerList[idx][BASE_SPEAKER.SPEAKER_TYPE] !== '0'
            ) {
                option.push(
                    <option
                        key={idx}
                        value={
                            this.state.speakerList[idx][
                                BASE_SPEAKER.BASE_SPEAKER_ID
                            ]
                        }
                    >
                        {this.state.speakerList[idx][BASE_SPEAKER.UNIT_NAME]}
                    </option>
                );
            }
        }
        return option;
    };

    /**
     * 音量設定欄に表示する数値の設定
     */
    volumeOptions = () => {
        let volumes = [];
        for (let idx = -12; idx >= -48; idx--) {
            volumes.push(
                <option key={`${idx}-volume`} value={idx}>
                    {idx}
                    {DISP_RESOURCE.DECIBEL}
                </option>
            );
        }
        return volumes;
    };

    /**
     * 角度設定欄に表示する数値の設定
     */
    degreeOptions = () => {
        // 前方スピーカーでない場合return
        if (
            this.state.speakerDirectionType !==
            DISP_RESOURCE.DIRECTIONAL_SPEAKER
        )
            return;

        let degrees = [];
        for (let idx = 0; idx <= 315; idx = idx + 45) {
            degrees.push(
                <option key={`${idx}-degree`} value={idx}>
                    {idx}
                    {DISP_RESOURCE.ANGLE}
                </option>
            );
        }
        return degrees;
    };

    /**
     * QRコードアイコン押下時処理、QRコードをダウンロードする
     */
    onQrCodeDownload = () => {
        // サーバー未登録のスピーカーの場合、先にフロア情報を保存する
        if (this.state.id <= 0) {
            this.qrCodeDownloadUnregistSpeaker();
            return;
        }

        // スピーカー情報が変更されている場合、先にフロア情報を保存する
        for(let s of this.props.speakers) {
            if (s.id === this.state.id) {
                console.log(s);
                // スピーカー設定ダイアログで保持する情報(変更後)とフロアマップで保持する情報(変更前)を比較
                // チェック対象は、スピーカー、名前、基準音量、個室設定、設定角度
                if (s.selectSpeakerId !== this.state.selectSpeakerId
                    || s.name !== this.state.name
                    || s.basicVolume !== parseInt(this.state.basicVolume)
                    || s.isPrivateRoom !== this.state.isPrivateRoom
                    || s.degree !== parseInt(this.state.degree)) {
                    this.qrCodeDownloadUnregistSpeaker();
                    return;
                }
            }
        }

        this.qrCodeDownload();

    };

    /**
     * QRコードをダウンロードする
     */
    qrCodeDownload = () => {
        // QRコードの画像データをbase64形式で取得
        const canvas = document.getElementById(this.state.qrcode);
        const pngUrl = canvas.toDataURL('image/png');

        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    /**
     * DBへの未登録スピーカーに対するQRコードダウンロード処理
     */
    qrCodeDownloadUnregistSpeaker = () => {
        // スピーカー機器未選択、スピーカー機器の重複エラー、スピーカー名未入力の場合処理を中断
        if (this.validation()) {
            return;
        }

        // 設定保存のモーダル表示
        this.modalOpen(MODAL.CHOICE_SAVE_FLOOR);
    }

    /**
     * 設定内容のバリデーションチェック
     * @returns バリデーション結果
     */
    validation = () => {
        var errorFlag = false;
        if (!this.state.selectSpeaker) {
            // 選択したスピーカー機器が別のスピーカーに設定されている場合
            this.setState({
                errorSelectSpeaker: DISP_RESOURCE.SELECT_REQUIRED,
            });
            errorFlag = true;
        } else if (
            this.props.checkDuplicateSpeaker(
                this.state.selectSpeakerId,
                this.state.id
            )
        ) {
            // 選択したスピーカー機器が別のスピーカーに設定されている場合
            this.setState({
                errorSelectSpeaker: DISP_RESOURCE.DEPLICATE_SELECT,
            });
            errorFlag = true;
        } else {
            // 重複なく選択されている場合
            this.setState({
                errorSelectSpeaker: null,
            });
        }

        if (!this.state.name || this.state.name === '') {
            this.setState({
                // スピーカー名未入力の場合
                errorSpeakerName: DISP_RESOURCE.INPUT_REQUIRED,
            });
            errorFlag = true;
        } else {
            this.setState({
                errorSpeakerName: null,
            });
        }

        return errorFlag;
    }

    /**
     * モーダル表示
     * @param {string} modalName - モーダルに紐づけた名前(refで指定)
     */
    modalOpen = (modalName) => {
        this[modalName].current.init();
    };

    /**
     * スピーカー情報削除
     */
    deleteSpeaker = () => {
        this.props.deleteSpeaker(this.state.id);
        this.close();
    };

    /**
     * フロア情報保存を保存し二次元コードをDLする
     */
    saveFloorAndQrDownload = async() => {
        // 基準音量設定をスピーカー機器に送信
        const settingData = {
            userId: this.props.userId,
            baseSpeakerId: this.state.selectSpeakerId,
            basicVolume: this.state.basicVolume,
        };
        settingSpeaker(settingData);

        // スピーカーの設定内容更新
        const data = {
            id: this.state.id,
            selectSpeakerId: this.state.selectSpeakerId,
            ipAddress: this.state.ipAddress,
            name: this.state.name,
            deviceType: this.state.speakerDirectionType,
            isPrivateRoom: this.state.isPrivateRoom,
            basicVolume: this.state.basicVolume,
            degree: this.state.degree,
        };
        this.props.updateSpeaker(data);
        const result = await this.props.saveFloorAndQrDownload(data);

        if (result === null) return;

        const selectSpeakerId = this.state.selectSpeakerId;

        // プロパティの反映を待ってQRコードダウンロード
        setTimeout(function() {
            for(let s of this.props.speakers) {
                if (s.selectSpeakerId === selectSpeakerId) {
                    this.setState({
                        id: s.id,
                        isFirst: false,
                        qrcode:
                            QRCODE_DOWNLOAD_SETTING.URL +
                            QRCODE_DOWNLOAD_SETTING.QUERY.format(
                                s.id,
                                this.props.baseId
                            ),
                    });
                    setTimeout(function() {
                        this.qrCodeDownload();
                    }.bind(this), 50);
                    break;
                }
            }
        }.bind(this), 50);
    }

    render() {
        return (
            <div className="div_modal_speaker">
                <Modal
                    className="modal_speaker"
                    isOpen={this.state.modal}
                    centered
                >
                    <ModalHeader
                        toggle={() => {
                            if (this.state.isFirst) {
                                // スピーカー追加時のモーダル表示の場合スピーカー削除
                                this.deleteSpeaker();
                            } else {
                                this.close();
                            }
                        }}
                    ></ModalHeader>
                    <ModalBody>
                        {/* スピーカー選択 */}
                        <Row>
                            <Label for="device_type" sm={3}>
                                {DISP_RESOURCE.SPEAKER_SELECT}
                            </Label>
                            <Col sm={6} className="form_with_valid">
                                <Input
                                    id="device_type"
                                    name="select"
                                    type="select"
                                    value={this.state.selectSpeakerId ?? ''}
                                    onChange={(e) =>
                                        this.setSelectSpeaker(e.target.value)
                                    }
                                    invalid={
                                        this.state.errorSelectSpeaker !== null
                                    }
                                >
                                    <option
                                        key="null-ip-speaker"
                                        value=""
                                    ></option>
                                    {this.selectSpeakerOption()}
                                </Input>
                                <FormFeedback
                                    className={classNames(
                                        this.state.errorSelectSpeaker !== null
                                            ? 'text-danger'
                                            : 'invisible'
                                    )}
                                >
                                    {this.state.errorSelectSpeaker ?? ''}
                                </FormFeedback>
                            </Col>
                            <Col className="check_access_result" sm={3}>
                                {this.state.testAccess !== ''
                                    ? this.state.testAccess
                                    : ''}
                            </Col>
                        </Row>
                        {/* IPアドレス */}
                        <Row>
                            <Label for="ip_address" sm={3}>
                                {DISP_RESOURCE.IP_ADDRESS}
                            </Label>
                            <Col
                                sm={6}
                                id="ip_address"
                                className="d-flex align-items-center flex-nowrap"
                            >
                                {this.state.ipAddress}
                            </Col>
                            {/* 接続チェック */}
                            <Col sm={3}>
                                <Button
                                    color="secondary"
                                    onClick={this.setTestAccess}
                                    disabled={this.state.selectSpeaker === ''}
                                >
                                    {DISP_RESOURCE.CHECK_ACCESS}
                                </Button>
                            </Col>
                        </Row>
                        {/* スピーカー名 */}
                        <Row>
                            <Label for="speaker_name" sm={3}>
                                {DISP_RESOURCE.SPEAKER_NAME}
                            </Label>
                            <Col sm={6} className="form_with_valid">
                                <Input
                                    id="speaker_name"
                                    type="text"
                                    value={this.state.name ?? ''}
                                    maxLength={FORM_MAX_LENGTH.SPEAKER_NAME}
                                    onChange={(e) =>
                                        this.setName(e.target.value)
                                    }
                                    disabled={this.state.selectSpeaker === ''}
                                    invalid={
                                        this.state.errorSpeakerName !== null
                                    }
                                ></Input>
                                <FormFeedback
                                    className={classNames(
                                        'form_message',
                                        this.state.errorSpeakerName !== null
                                            ? 'text-danger'
                                            : 'invisible'
                                    )}
                                >
                                    {this.state.errorSpeakerName ?? ''}
                                </FormFeedback>
                            </Col>
                        </Row>
                        {/* スピーカータイプ */}
                        <Row>
                            <Label for="speaker_type" sm={3}>
                                {DISP_RESOURCE.SPEAKER_TYPE}
                            </Label>
                            <Col
                                sm={6}
                                id="speaker_type"
                                className="d-flex align-items-center flex-nowrap"
                            >
                                {this.state.speakerDirectionType}
                            </Col>
                        </Row>
                        {/* 個室設置 */}
                        <Row>
                            <Label for="private_room" sm={3}>
                                {DISP_RESOURCE.PRIVATE_ROOM}
                            </Label>
                            <Col sm={6}>
                                <Input
                                    type="checkbox"
                                    id="private_room"
                                    checked={this.state.isPrivateRoom}
                                    onChange={() => {
                                        this.setisPrivateRoom(
                                            !this.state.isPrivateRoom
                                        );
                                    }}
                                    disabled={this.state.selectSpeaker === ''}
                                />
                            </Col>
                        </Row>
                        {/* 基準音量設定 */}
                        <Row>
                            <Label for="basic_volume" sm={3}>
                                {DISP_RESOURCE.BASIC_VOLUME}
                            </Label>
                            <Col sm={6}>
                                <Input
                                    id="basic_volume"
                                    name="select"
                                    type="select"
                                    value={this.state.basicVolume}
                                    onChange={(e) =>
                                        this.setVolume(e.target.value)
                                    }
                                    disabled={this.state.selectSpeaker === ''}
                                >
                                    {this.volumeOptions()}
                                </Input>
                            </Col>
                            <Col sm={2}>
                                {/* テスト再生トグル */}
                                <div className="custom_switch volume_test_checkbox">
                                    <Input
                                        id="volume_test"
                                        type="checkbox"
                                        checked={this.state.testPlay}
                                        onChange={this.setTestPlay}
                                        disabled={
                                            this.state.selectSpeakerId === -1 ||
                                            this.state.errorSelectSpeaker !==
                                                null
                                        }
                                    ></Input>
                                    <label htmlFor="volume_test">
                                        <span
                                            className={
                                                this.state.testPlay
                                                    ? 'checked switch_label'
                                                    : 'switch_label'
                                            }
                                        >
                                            {' '}
                                            {DISP_RESOURCE.TEST_PLAY}
                                        </span>
                                    </label>
                                </div>
                            </Col>
                            <Col
                                sm={1}
                                className={
                                    'd-flex align-items-center text-danger text-nowrap justify-content-center col-sm-1'
                                }
                            >
                                {this.state.testPlayText !== ''
                                    ? this.state.testPlayText
                                    : ''}
                            </Col>
                        </Row>
                        {/* 設定角度 + 前方スピーカー表示 + QRコード */}
                        <Row>
                            <Col sm={3}>
                                {/* 設定角度ラベル */}
                                {this.state.speakerDirectionType ===
                                    DISP_RESOURCE.DIRECTIONAL_SPEAKER && (
                                    <Label for="set_degree" sm={12}>
                                        {DISP_RESOURCE.SET_DEGREE}
                                    </Label>
                                )}
                                {/* QRコードラベル */}
                                <Label
                                    for="disp_qrcode"
                                    sm={12}
                                    className="mt-2"
                                >
                                    {HTMLReactParser(DISP_RESOURCE.DISP_QRCODE)}
                                </Label>
                            </Col>
                            <Col sm={6}>
                                {this.state.speakerDirectionType ===
                                    DISP_RESOURCE.DIRECTIONAL_SPEAKER && (
                                    <Col sm={12}>
                                        {/* 設定角度リスト */}
                                        <Input
                                            id="set_degree"
                                            name="select"
                                            type="select"
                                            value={this.state.degree}
                                            onChange={(e) =>
                                                this.setDegree(e.target.value)
                                            }
                                            disabled={
                                                this.state.selectSpeaker ===
                                                    '' ||
                                                this.state
                                                    .speakerDirectionType !==
                                                    DISP_RESOURCE.DIRECTIONAL_SPEAKER
                                            }
                                        >
                                            {this.degreeOptions()}
                                        </Input>
                                    </Col>
                                )}
                                <Col sm={12}>
                                    {/* QRコードダウンロードボタン */}
                                    <div className="div_qrcode mt-2">
                                        <Button
                                            color="secondary"
                                            onClick={() =>
                                                this.onQrCodeDownload()
                                            }
                                        >
                                            {DISP_RESOURCE.DOWNLOAD}
                                            <DownloadImg className="download_qrcode" />
                                        </Button>
                                        {/* QRコード(非表示) */}
                                        <QRCodeCanvas
                                            className={'d-none'}
                                            id={this.state.qrcode}
                                            value={this.state.qrcode}
                                            size={QRCODE_DOWNLOAD_SETTING.SIZE}
                                        />
                                    </div>
                                </Col>
                            </Col>
                            {this.state.speakerDirectionType ===
                                DISP_RESOURCE.DIRECTIONAL_SPEAKER && (
                                <Col sm={3}>
                                    {/* 前方スピーカー表示確認 */}
                                    <div className="div_degree d-flex justify-content-center align-items-center">
                                        {this.state.speakerDirectionType ===
                                            DISP_RESOURCE.DIRECTIONAL_SPEAKER && (
                                            <>
                                                {/* スピーカーアイコン */}
                                                <SpDef
                                                    className={
                                                        'spicon_setting z-2'
                                                    }
                                                />
                                                {/* 音圧表示（前方型） */}
                                                <svg
                                                    key={'spkpressures'}
                                                    className={`sound_pressures z-1`}
                                                    viewBox="0 0 240 240"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    style={{
                                                        transform:
                                                            'rotate(-' +
                                                            this.state.degree +
                                                            'deg)',
                                                        width: 140,
                                                        height: 140,
                                                    }}
                                                >
                                                    <defs>
                                                        <radialGradient
                                                            key={`gradient_spk_setting`}
                                                            id={`radial-gradient_spk_setting`}
                                                            cx="0"
                                                            cy="0.5"
                                                            r="0.5"
                                                            gradientTransform="matrix(2, 0, 0, 1.14, 0, -0.07)"
                                                            gradientUnits="objectBoundingBox"
                                                        >
                                                            <stop
                                                                offset="0"
                                                                stopColor={
                                                                    '#38b8ab'
                                                                }
                                                            />
                                                            <stop
                                                                offset="1"
                                                                stopColor={
                                                                    '#38b8ab'
                                                                }
                                                                stopOpacity="0"
                                                            />
                                                        </radialGradient>
                                                    </defs>
                                                    <path
                                                        id="volume_front"
                                                        d="M120,125,226.143,63.968a120.153,120.153,0,0,1-4.329,119.575Z"
                                                        transform="translate(0 -5)"
                                                        opacity="0.395"
                                                        fill={`url(#radial-gradient_spk_setting)`}
                                                    />
                                                </svg>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </ModalBody>
                    <ModalFooter className="with_cancel">
                        <Button
                            outline
                            color="success"
                            onClick={() => {
                                if (this.state.isFirst) {
                                    // スピーカー追加時のモーダル表示の場合スピーカー削除
                                    this.deleteSpeaker();
                                } else {
                                    this.close();
                                }
                            }}
                        >
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.save}>
                            {DISP_RESOURCE.SETTING}
                        </Button>
                        <Recyclebin
                            className="recycle_bin speaker_recycle"
                            onClick={() => {
                                if (this.state.isFirst) {
                                    // スピーカー追加時のモーダル表示の場合スピーカー削除
                                    this.deleteSpeaker();
                                } else {
                                    // それ以外の場合スピーカーの場合削除確認をする
                                    this.modalOpen(MODAL.DELETE_SPEAKER);
                                }
                            }}
                        ></Recyclebin>
                    </ModalFooter>
                </Modal>
                {/* スピーカー削除MODAL */}
                <ModalMessage
                    ref={this[MODAL.DELETE_SPEAKER]}
                    title={DISP_RESOURCE.DELETE_SPEAKER_TITLE}
                    content={DISP_RESOURCE.DELETE_SPEAKER_CONTENT}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.deleteSpeaker}
                    isCancel={true}
                ></ModalMessage>
                {/* フロア保存モーダル */}
                <ModalMessage
                    ref={this[MODAL.CHOICE_SAVE_FLOOR]}
                    title={DISP_RESOURCE.CHOICE_SAVE_FLOOR_TITLE}
                    content={DISP_RESOURCE.CHOICE_SAVE_FLOOR_QR_DL_CONTENT}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.saveFloorAndQrDownload}
                ></ModalMessage>
            </div>
        );
    }
}

export default ModalSpeaker;
