import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, Label } from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import parser from 'html-react-parser';
import { MODE } from '../../../common/constants';

/**
 * アンケート回答確認モーダル
 */
class ModalSurveyConfirm extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} props.setMode - 画面状態の設定変更
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} アンケート回答確認モーダル表示有無
             */
            modal: false,
        };
    }

    /**
     * アンケート回答確認モーダル表示有無の設定
     * @param {boolean} flag -アンケート回答確認モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * 回答するボタン押下時処理
     */
    onClickAnswerHandler = () => {
        this.props.setMode(MODE.USER_SURVEY);
        this.close();
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        return (
            <div className="div_modal_commons">
                <Modal
                    className="modal_commons"
                    isOpen={this.state.modal}
                    centered
                >
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Label for="modal_commons_content">
                            {parser(DISP_RESOURCE.SURVEY_CONFIRM_CONTENT)}
                        </Label>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="without_cancel">
                        <Button
                            color="success"
                            onClick={this.onClickAnswerHandler}
                            className="mb-2"
                        >
                            {DISP_RESOURCE.SURVEY_ANSWER}
                        </Button>
                        <Button
                            outline
                            color="success"
                            onClick={this.close}
                            className="mb-2"
                        >
                            {DISP_RESOURCE.SURVEY_NOT_ANSWER}
                        </Button>
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalSurveyConfirm;
