import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AreaSetting from './components/AreaSetting';
import Login from './components/Login';
import User from './components/User';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} exact></Route>
                    <Route path="/area" element={<AreaSetting />} exact></Route>
                    <Route path="/user" element={<User />} exact></Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
