import { useDrop } from 'react-dnd';
import {
    EXTERNAL_FILE_CONTENTS,
    IP_DEVICE_TYPE,
    ITEM_TYPES,
    MAP_COORDINATE,
    SCHEDULE_COLORS,
    SOUND_PRESSURE,
    SPEAKER_ICON_SIZE,
    SPEAKER_STATUS,
} from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';

import ItemAreaSpeaker from './ItemAreaSpeaker';

/**
 * スピーカードロップボックス
 * @param {object} props コンポーネント呼び出し側から渡される値
 */
const DropBoxSpeaker = (props) => {
    const [, drop] = useDrop(
        () => ({
            // ドロップ対象
            accept: [ITEM_TYPES.SIDEBAR_SPEAKER, ITEM_TYPES.AREA_SPEAKER],
            // ボックスへドロップで発火
            drop(item, monitor) {
                // スピーカー新規配置の場合
                if (item.id === undefined) {
                    // ドロップしたマウスの座標取得
                    const delta = monitor.getClientOffset();
                    const left =
                        Math.round(delta.x) -
                        SPEAKER_ICON_SIZE / 2 -
                        MAP_COORDINATE.LEFT;
                    const top =
                        Math.round(delta.y) -
                        SPEAKER_ICON_SIZE / 2 -
                        MAP_COORDINATE.TOP;
                    const speakerTypeId = item.speakerTypeId;
                    const status = SPEAKER_STATUS.STOP;

                    const data = {
                        left:
                            left < MAP_COORDINATE.MIN_LEFT
                                ? MAP_COORDINATE.MIN_LEFT
                                : left > MAP_COORDINATE.MAX_LEFT
                                ? MAP_COORDINATE.MAX_LEFT
                                : left,
                        top:
                            top < MAP_COORDINATE.MIN_TOP
                                ? MAP_COORDINATE.MIN_TOP
                                : top > MAP_COORDINATE.MAX_TOP
                                ? MAP_COORDINATE.MAX_TOP
                                : top,
                        speakerTypeId,
                        status,
                        groupId: undefined,
                        selectSpeakerId: undefined,
                        ipAddress: undefined,
                        name: undefined,
                        speakerDirectionType: undefined,
                        basicVolume: EXTERNAL_FILE_CONTENTS.DEFAULT_VOLUME,
                        degree: 0,
                        isPrivateRoom: false,
                        isDelete: false,
                    };

                    // 座標(left, top)、およびスピーカー情報登録（push)
                    props.createSpeaker(data);
                } else {
                    // 既存スピーカー移動
                    // ドラッグで移動した距離を取得
                    const delta = monitor.getDifferenceFromInitialOffset();
                    const left = Math.round(item.left + delta.x);
                    const top = Math.round(item.top + delta.y);
                    const id = item.id;

                    const data = {
                        id,
                        left:
                            left < MAP_COORDINATE.MIN_LEFT
                                ? MAP_COORDINATE.MIN_LEFT
                                : left > MAP_COORDINATE.MAX_LEFT
                                ? MAP_COORDINATE.MAX_LEFT
                                : left,
                        top:
                            top < MAP_COORDINATE.MIN_TOP
                                ? MAP_COORDINATE.MIN_TOP
                                : top > MAP_COORDINATE.MAX_TOP
                                ? MAP_COORDINATE.MAX_TOP
                                : top,
                    };
                    //  スピーカーの座標のみ更新
                    props.updateSpeaker(data);
                }

                return undefined;
            },
        }),
        [props.speakers]
    );

    const mapStyle = {
        backgroundImage: `url(${props.areaImage})`,
    };

    return (
        <div
            ref={drop}
            className="speaker_drop"
            style={mapStyle}
            onClick={() => {
                props.selectSpeaker(null);
            }}
        >
            {props.soundPressureFlag &&
                // スピーカー配列の数分、スピーカーアイテム配置
                props.speakers.map((item) => {
                    const { id, left, top, colorId, volume, selectSpeakerId } =
                        item;

                    // 再生中かつ非ミュート状態以外はパスする
                    if (colorId === null || colorId === undefined || item.isMute) {
                        return null;
                    }

                    const baseSpeaker = props.baseSpeakers.find(
                        (basespk) => basespk.baseSpeakerId === selectSpeakerId
                    );

                    const isFront =
                        baseSpeaker !== undefined &&
                        IP_DEVICE_TYPE[baseSpeaker.speakerTypeId] ===
                            DISP_RESOURCE.DIRECTIONAL_SPEAKER;

                    const iconColor = SCHEDULE_COLORS.find(
                        (color) => color.id === colorId
                    ).hex;
                    const classname = isFront
                        ? `volume_front_${id}`
                        : `volume_round_${id}`;

                    const distance = SOUND_PRESSURE.find(
                        (item) => item.volume === volume
                    ).distance;
                    const radius = Math.round(
                        (1280 / props.areaWidth) * distance
                    );

                    const style = {
                        width: radius * 2,
                        height: radius * 2,
                        left: left - (radius * 2 - SPEAKER_ICON_SIZE) / 2,
                        top: top - (radius * 2 - SPEAKER_ICON_SIZE) / 2,
                        transform: 'rotate(-' + item.degree + 'deg)',
                    };

                    /* スピーカーアイテム */
                    return (
                        <svg
                            key={classname}
                            className={`sound_pressures ${classname}`}
                            viewBox="0 0 240 240"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            style={style}
                        >
                            {isFront ? (
                                <>
                                    <defs>
                                        <radialGradient
                                            key={`gradient_${classname}`}
                                            id={`radial-gradient_${classname}`}
                                            cx="0"
                                            cy="0.5"
                                            r="1"
                                            gradientTransform="matrix(1, 0, 0, 1.14, 0, -0.07)"
                                            gradientUnits="objectBoundingBox"
                                        >
                                            <stop
                                                offset="0"
                                                stopColor={iconColor}
                                            />
                                            <stop
                                                offset="1"
                                                stopColor={iconColor}
                                                stopOpacity="0"
                                            />
                                        </radialGradient>
                                    </defs>
                                    <path
                                        id="volume_front"
                                        d="M120,125,226.143,63.968a120.153,120.153,0,0,1-4.329,119.575Z"
                                        transform="translate(0 -2)"
                                        opacity="0.395"
                                        fill={`url(#radial-gradient_${classname})`}
                                    />
                                </>
                            ) : (
                                <>
                                    <defs>
                                        <radialGradient
                                            key={`gradient_${classname}`}
                                            id={`radial-gradient_${classname}`}
                                            cx="0.5"
                                            cy="0.5"
                                            r="0.5"
                                            gradientUnits="objectBoundingBox"
                                        >
                                            <stop
                                                offset="0"
                                                stopColor={iconColor}
                                            />
                                            <stop
                                                offset="1"
                                                stopColor={iconColor}
                                                stopOpacity="0"
                                            />
                                        </radialGradient>
                                    </defs>
                                    <circle
                                        id="volume_round"
                                        cx="120"
                                        cy="120"
                                        r="120"
                                        opacity="0.395"
                                        fill={`url(#radial-gradient_${classname})`}
                                    />
                                </>
                            )}
                        </svg>
                    );
                })}
            {
                // スピーカー配列の数分、スピーカーアイテム配置
                props.speakers.map((item) => {
                    const { id, left, top } = item;

                    /* スピーカーアイテム */
                    return (
                        <ItemAreaSpeaker
                            key={id}
                            id={id}
                            left={left}
                            top={top}
                            speakerInfo={item}
                            selectSpeaker={props.selectSpeaker}
                            currentSpeaker={props.currentSpeaker}
                            currentGroup={props.currentGroup}
                            modalOpen={props.modalOpen}
                            mode={props.mode}
                            scheduleInfo={props.scheduleInfo}
                        ></ItemAreaSpeaker>
                    );
                })
            }
        </div>
    );
};

export default DropBoxSpeaker;
