const DISP_RESOURCE = {
    // ログイン画面
    LOGIN_ERROR_CONTRACT_LIMIT:
        '契約期限切れのお知らせ<br />' +
        'sound veil システムの契約期限が切れております。<br />' +
        'TOA担当者または以下までお問い合わせ下さい。<br />' +
        'メールアドレス: toa@cresnect.com<br />' +
        '　　　　　　　&ensp;toa123@cresnect.com<br />' +
        '電話番号: 0x-xxxx-xxxx',
    LOGIN_ERROR_FAIL_AUTH:
        'ログイン情報が正しくありません。<br />ログインできません。',

    LOGIN_FORM_USER_ID: 'ユーザーID',
    LOGIN_FORM_PASSWORD: 'パスワード',
    LOGIN_BUTTON: 'ログイン',

    CLIENT_VERSION_TEXT: 'クライアントバージョン：',
    SERVER_VERSION_TEXT: 'サーバーバージョン：',

    // ログイン画面 契約期限切れお知らせモーダル
    CONTRACT_LIMIT_TITLE: '重要なお知らせ',
    CONTRACT_LIMIT_CONTENT:
        'sound veil システムの契約期限が<br />' +
        'まもなく終了します。<br /><br />' +
        'サービス継続は以下へお問い合わせください。<br /><br />' +
        '********************************<br />' +
        'メールアドレス: toa@cresnect.com<br />' +
        '　　　　　　　&ensp;toa123@cresnect.com<br />' +
        '電話番号: 0x-xxxx-xxxx<br />' +
        '********************************<br />' +
        'サービス有効期限: {0}年{1}月{2}日',

    // トップバー
    TOP_FLOOR_SETTING: 'フロア設定',
    TOP_SPEAKER_SETTING: 'スピーカー設定',
    TOP_GROUP_SETTING: 'グループ設定',
    TOP_SCHEDULE_SETTING: 'スケジュール設定',
    TOP_LOG_SETTING: 'ログ表示',
    TOP_CHANGE_OPERATION_MODE: '運用モード切替',
    TOP_CHANGE_SETTING_MODE: '設定モード切替',
    TOP_OPERATION_MODE: '運用開始',
    TOP_SETTING_MODE: '設定',
    TOP_SETTING_TO_OPERATION: '運用モードに移行します。<br />よろしいですか？',
    TOP_OPERATION_TO_SETTING:
        '設定モードに移行します。<br />再生中のスピーカーは全て停止状態となります。<br />よろしいですか？',
    TOP_DIFF_INFO:
        '拠点情報ファイルに差分があります。更新しますか？<br />スピーカー削除の場合、紐づくスケジュールも<br />削除されます。',

    // 上段ガイド
    AREA_REAL_WIDTH: 'マップ横方向の実サイズ：',
    AREA_REAL_WIDTH_UNIT: 'M',
    SPEAKER_GUIDE:
        'スピーカーをドラッグ＆ドロップで配置し、スピーカー情報を入力して下さい。',
    GROUP_GUIDE:
        ' をクリックしスピーカーグループを作成して下さい。<br />フロアマップ上のスピーカーをクリックし「追加」ボタンでグループに追加します。',

    // フロア設定初期画面
    SAVE: '保存',
    SAVE_FLOOR_NAME: 'フロア名を入力してください。',
    AREA_DND_DESCRIPTION: `新しいフロア作成は<br />ここにフロア画像ファイルを<br />ドラッグ＆ドロップしてください`,
    AREA_DND_DRAGGING: 'ドロップする',
    AREA_DROP_NOT_ACCEPT:
        '画像は .jpg、.pngのいずれかの形式である必要があります。',

    // サイドバー
    NEW_FLOOR: '新規フロアマップ<br />読み込み',
    LOAD_FLOOR: '保存フロア情報の<br />呼び出し',
    DELETE_FLOOR: '保存フロア情報の<br />削除',
    SPEAKER_DEFAULT: '通常型',
    SPEAKER_FAKEGREEN: 'フェイクグリーン 植栽型',
    SPEAKER_TABLE: 'テーブル',
    SPEAKER_DESC: 'デスク下',
    SPEAKER_PANEL: 'パネル',
    SPEAKER_SPOTLIGHT: 'スポットライト',
    GROUP_LIST: 'グループリスト',
    GROUP_ADD: 'グループ追加',
    GROUP_EDIT: 'グループ名を編集',
    GROUP_NAME: 'グループ名',
    GROUP_DELETE: 'グループを削除',

    // 保存ボタン押下時
    SAVE_FLOOR_ERR_NO_AREA_IMAGE:
        'マップが配置されていないため保存できません。',
    SAVE_FLOOR_ERR_WIDTH_RANGE:
        'マップの横方向の実サイズを「{0}～{1}m」の範囲で入力して下さい。',
    SAVE_FLOOR_CONFIRM_OVERWRITE:
        'フロア情報が設定されています。<br />上書きしてもよろしいでしょうか？',

    // 新規フロアマップ読込モーダル
    NEW_FLOOR_BUTTON: '読み込み',

    // フロアマップ読込モーダル
    LOAD_FLOOR_BUTTON: '呼び出し',
    LOAD_FLOOR_MESSAGE: 'フロア名を選択してください。',
    LOAD_FLOOR_NO_FLOOR: 'フロア情報が保存されていません。',

    // フロア編集情報保存確認
    CHOICE_SAVE_FLOOR_TITLE: 'フロア編集情報保存確認',
    CHOICE_SAVE_FLOOR_CONTENT: '変更されたフロア情報を保存しますか？',
    CHOICE_SAVE_FLOOR_FIRST_OPT_NEW: '保存して新規フロアマップを読み込む',
    CHOICE_SAVE_FLOOR_SECOND_OPT_NEW: '保存せず新規フロアマップを読み込む',
    CHOICE_SAVE_FLOOR_FIRST_OPT_LOAD: '保存してフロア情報を呼び出す',
    CHOICE_SAVE_FLOOR_SECOND_OPT_LOAD: '保存せずフロア情報を呼び出す',
    CHOICE_SAVE_FLOOR_FIRST_OPT_OPERATION: '保存して運用モードに移行する',
    CHOICE_SAVE_FLOOR_SECOND_OPT_OPERATION: '保存せず運用モードに移行する',
    CHOICE_SAVE_FLOOR_QR_DL_CONTENT: '変更されたフロア情報を保存し、二次元コードをダウンロードします。<br />よろしいですか？',

    // スピーカーモダール
    SPEAKER_SELECT: 'スピーカー選択',
    IP_ADDRESS: 'IPアドレス',
    SPEAKER: 'スピーカー',
    SPEAKER_NAME: 'スピーカー名',
    SPEAKER_TYPE: 'スピーカータイプ',
    CHECK_ACCESS: '接続チェック',
    ACCESS_SUCCESS: '接続成功',
    ACCESS_DENIDE: '接続失敗',
    PRIVATE_ROOM: '個室設置',
    BASIC_VOLUME: '基準音量設定',
    TEST_PLAY: 'テスト再生',
    SET_DEGREE: '設定角度',
    DISP_QRCODE: '二次元コード',
    DOWNLOAD: 'ダウンロード',
    DECIBEL: 'dB',
    ANGLE: '度',
    SPEAKER_CONNECTING: '接続中...',

    CHECK_GROUP_TITLE: 'グループ確認',
    CHECK_GROUP_CONTENT: `選択した「{0}」はすでに「{1}グループ」に登録しています。<br />２つのグループに登録する事はできません。解除しますか？`,

    // フロア削除モダール
    DELETE_FLOOR_TITLE: 'フロア削除',
    DELETE_FLOOR_CONTENT:
        'フロアマップを削除します。<br />フロアマップ上の配置スピーカーやスケジュールも<br />削除されます。よろしいですか？',

    // スピーカー削除モダール
    DELETE_SPEAKER_TITLE: 'スピーカー削除',
    DELETE_SPEAKER_CONTENT:
        'スピーカーに設定されているスケジュールも削除されます。<br />スピーカー情報を削除してもよろしいですか？',

    // グループ削除モダール
    DELETE_GROUP_TITLE: 'グループ削除',
    DELETE_GROUP_CONTENT:
        'グループを削除しますか？<br />所属しているスピーカが存在する場合、所属が解除されます。',

    // グループ名編集モーダル
    EDIT_GROUP_TITLE: 'グループ名編集',

    // グループ解除モーダル
    UNGROUP_SPEAKER_TITLE: 'グループ解除',
    UNGROUP_SPEAKER_CONTENT: 'スピーカーをグループから解除しますか？',

    // スピーカーの種類
    NOT_SPEAKER: '',
    DIRECTIONAL_SPEAKER: '前方スピーカー',
    OMNIDIRECTIONAL_SPEAKER: '全方位スピーカー',

    // スケジュール設定
    SPEAKER_SCHEDULE: 'スピーカースケジュール',
    TWELVE_HOURS: '12時間',
    ONE_DAY: '1日間',
    SEVEN_DAYS: '7日間',
    ONE_WEEK: '1週間',
    SCHEDULE_POP_CONTENT: '設定した時間から１２時間表示します。',
    SCHEDULE_TOOLTIP_TITLE: 'タイトル：',
    SCHEDULE_TOOLTIP_DATETIME: '日  時：',
    SCHEDULE_TOOLTIP_SOUNDSOURCE: '音  源：',
    VOLUME_CONTROL: '音量調節',
    MUTE: 'ミュート',
    MUTE_OFF: 'ミュート解除',
    SOUND_PRESSURE: '音圧表示',
    TEST_SOUND_TITLE: 'テスト再生',
    TEST_SOUND_CONTENT:
        '選択中のすべてのスピーカーがテスト再生します。<br />それ以外のスピーカーは再生を停止します。<br />よろしいですか？',
    TESTING_SOUND: 'テスト再生中',
    HOUR: '時',
    REPEAT: '繰返し',
    SCHEDULE_GROUP_PREFIX: '[G]',
    SCHEDULE_DATE_LABEL_FORMAT: 'YYYY年MM月DD日(ddd)',
    SCHEDULE_YEAR_LABEL_FORMAT: 'YYYY年',
    SCHEDULE_DAY_LABEL_FORMAT: 'MM月DD日(ddd)',
    CHOICE_DELETE_GROUP_TITLE: 'スケジュール削除　グループ確認',
    CHOICE_DELETE_GROUP_CONTENT:
        'このスケジュールは複数のスピーカーに設定されています。<br />スケジュール削除の範囲を選択して下さい。',
    CHOICE_DELETE_GROUP_FIRST_OPT: '複数スピーカーのスケジュールを削除',
    CHOICE_DELETE_GROUP_SECOND_OPT:
        '選択した[{0}]スピーカーのスケジュールのみ削除',
    CHOICE_DELETE_REPEAT_TITLE: 'スケジュール削除　繰返し確認',
    CHOICE_DELETE_REPEAT_CONTENT:
        'このスケジュールは繰返し設定されています。<br />スケジュール削除の範囲を選択して下さい。',
    CHOICE_DELETE_REPEAT_FIRST_OPT: '選択したスケジュールのみ削除',
    CHOICE_DELETE_REPEAT_SECOND_OPT: '選択以降の全てのスケジュールを削除',
    CHOICE_UPDATE_GROUP_TITLE: 'スケジュール編集　グループ確認',
    CHOICE_UPDATE_GROUP_CONTENT:
        'このスケジュールは複数のスピーカーに設定されています。<br />スケジュールの設定範囲を選択して下さい。',
    CHOICE_UPDATE_GROUP_FIRST_OPT: '複数のスピーカーに設定を反映',
    CHOICE_UPDATE_GROUP_SECOND_OPT: '選択した[{0}]スピーカーにのみ設定を反映',
    CHOICE_UPDATE_REPEAT_TITLE: 'スケジュール編集　繰返し確認',
    CHOICE_UPDATE_REPEAT_CONTENT:
        'このスケジュールは繰返し設定されています。<br />スケジュールの設定範囲を選択して下さい。',
    CHOICE_UPDATE_REPEAT_FIRST_OPT: '選択したスケジュールにのみ設定を反映',
    CHOICE_UPDATE_REPEAT_SECOND_OPT: '選択以降の全てのスケジュールに設定を反映',
    CHOICE_DUPLICATE_TYPE_TITLE: 'スケジュール重複確認',
    CHOICE_DUPLICATE_TYPE_CONTENT:
        'スピーカー名{0}の{1}開始のスケジュールが重複しています。<br />以下から操作を選択してください。',
    CHOICE_DUPLICATE_TYPE_FIRST_OPT:
        '旧スケジュールを削除して新スケジュールを反映',
    CHOICE_DUPLICATE_TYPE_SECOND_OPT: '重複する時間帯は新スケジュールを反映',
    CHOICE_DUPLICATE_TYPE_THIRD_OPT: '重複しない時間帯のみ新スケジュールを反映',
    CHOICE_DUPLICATE_TYPE_FOURTH_OPT: '新スケジュールを反映しない',
    CHOICE_DUPLICATE_TYPE_CHECK: '以後同様の確認は行わない',
    SOUND_TEST_TITLE: 'テスト再生',
    SOUND_TEST_CONTENT1:
        '選択中の全てのスピーカーがテスト再生します。<br />それ以外のスピーカーは再生を停止します。',
    SOUND_TEST_CONTENT2: 'テスト再生中',
    ON_MUTE: '音量ミュート中',
    SCHEDULE_NONE_TARGET_ALERT:
        'スケジュールを設定するためのスピーカーを選択してください。',
    SCHEDULE_FAILED_GET_SOUND_SOURCES_ALERT:
        '&emsp;&emsp;音源情報取得に失敗しました。',

    // スケジュール詳細設定
    SCHEDULE_DETAIL: 'スケジュール詳細',
    SCHEDULE_NAME: 'スケジュールタイトル',
    SCHEDULE_PERIOD: '期間',
    REPEAT_ON: '繰返す',
    REPEAT_OFF: '繰返さない',
    SOUND_SOURCE: '音源',
    VOLUME: '音量',
    COLOR: 'カラー',

    REPEAT_CYCLE: '繰返し周期',
    EVERY_DAY: '毎日',
    EVERY_WEEK: '毎週',
    PICK_DATE: '毎月(日付指定)',
    PICK_DAY: '毎月(曜日指定)',
    EVERY_YEAR: '周年',
    CUSTOM: 'カスタム',
    REPEAT_DAY_COUNT: '繰返し日数',
    REPEAT_END_DATE: '終了日',
    FOR_DAYS: '日間',
    FOR_MONTHS: 'ヶ月毎',
    FOR_YEARS: 'ヶ年毎',

    FIRST_WEEK: '第一',
    SECOND_WEEK: '第二',
    THIRD_WEEK: '第三',
    FOURTH_WEEK: '第四',
    FIFTH_WEEK: '第五',

    // 入力チェックエラー文
    INPUT_REQUIRED: '入力が必要です',
    PERIOD_ERROR_START_LATE_THEN_END:
        '終了日時は開始日時より後に設定してください',
    PERIOD_ERROR_NOT_ONE_DAY: '期間は24時間未満で設定してください',
    SELECT_REQUIRED: '選択が必要です',
    DEPLICATE_SELECT: '他スピーカーと重複できません',
    NOT_FOUND_SOUND_SOURCES: '設定可能な音源情報がありません',

    // セッション有効期限切れアラート
    SESSION_EXPIRED_CONTENT:
        'セッションの有効期限切れのためアクセスできません。',

    // 停止時間選択モーダル
    SELECT_STOP_TIME_TITLE: '停止時間',
    SELECT_STOP_TIME_CONTENT:
        'システム管理者によって音源再生が再開する事があります。',

    // 音源選択モーダル
    SELECT_SOUND_SOURCE_TITLE: '音源選択',

    // オフィス利用者向けの再生失敗モーダルメッセージ
    USER_CANNOT_PLAY: '現在スピーカーを再生できません',
    USER_FAILED_PLAY: 'スピーカーの再生に失敗しました',
    USER_FAILED_GET_SOUND_SOURCES: '音源情報取得に失敗しました。',

    // アンケート回答確認モーダル
    SURVEY_CONFIRM_CONTENT:
        'sound veil<br />' +
        'アンケートのお願い<br />' +
        '<br />' +
        'ご利用いただきありがとうございます。<br />' +
        'サービスの品質向上を目的とし、アンケートを実施しております。<br />' +
        'ご協力をお願いいたします。',
    SURVEY_ANSWER: 'アンケートに回答する',
    SURVEY_NOT_ANSWER: 'アンケートに回答しない',

    // アンケート回答画面
    LOAD_SURVEY: '読み込んでいます…',
    TO_USER_APP: 'オフィスサウンドシステムへ戻る',

    // 共通
    OK: 'OK',
    ADD: '追加',
    DELETE: '削除',
    UPDATE: '更新',
    CANCEL: 'キャンセル',
    SETTING: '設定',
    CLOSE: '閉じる',
    ON: 'ON',
    OFF: 'OFF',
    STOP: '停止',
    FULL_WIDTH_COLON: '：',
    HOURS: '時間',
    MINUTES: '分',
    TODAY: '今日',

    // 共通 処理結果出力用
    SAVE_SUCCESS: '保存しました。',
    SAVE_FAILED: '保存に失敗しました。',
    LOAD_SUCCESS: '読み込みました。',
    LOAD_FAILED: '読み込みに失敗しました。',
    DELETE_SUCCESS: '削除しました。',
    DELETE_FAILED: '削除に失敗しました。',
    UPDATE_SUCCESS: '更新しました。',
    UPDATE_FAILED: '更新に失敗しました。',
    SETTING_SUCCESS: '設定しました。',
    SETTING_FAILED: '設定に失敗しました。',

    // ログ
    LOG_DATE: '日付',
    LOG_TIME: '時間',
    LOG_ACCOUNT: 'アカウント',
    LOG_SPEAKER_NAME: 'スピーカー名',
    LOG_LOG: 'ログ',
    LOG_RESULT: '結果',
    LOG_IP_ADDRESS: 'IPアドレス',
    LOG_PERIOD: '期間：',
    LOG_UPDATE: '表示更新',
    LOG_RESULT_SUCCESS: '成功',
    LOG_RESULT_FAILED: '失敗',
    LOG_RESULT_NOTHING: '-',

    // スピーカーリスト
    SPEAKER_LIST: 'スピーカーリスト',
    HASH: '#',
    STATUS: 'ステータス',
    VOLUME_DECIBEL: '音量(dB)',

    // 共通エラー
    EXCEPTION_OCCURED:
        'システムエラーが発生しました。<br />管理者に連絡してください。',

    MAP: 'マップ',
    LIST: 'リスト',
};
export default DISP_RESOURCE;
