import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';

/**
 * 音源再生失敗アラートモーダル
 */
class ModalPlayFailure extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} props.setMode - 画面状態の設定変更
     * @param {string} props.content - メッセージ
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} 音源再生失敗アラートモーダル表示有無
             */
            modal: false,
        };

        this.parser = require('html-react-parser');
    }

    /**
     * 音源再生失敗アラートモーダル表示有無の設定
     * @param {boolean} flag -音源再生失敗アラートモーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = () => {
        this.close();
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const { content } = this.props;

        return (
            <div className="div_modal_commons">
                <Modal
                    className="modal_commons"
                    isOpen={this.state.modal}
                    centered
                >
                    {/* モーダルヘッダー タイトル表示欄*/}
                    <ModalHeader />
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Label for="modal_commons_content">
                            {this.parser(content)}
                        </Label>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="without_cancel">
                        <Button color="success" onClick={this.onClickOkHandler}>
                            {DISP_RESOURCE.OK}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalPlayFailure;
