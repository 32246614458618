import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Nav } from 'reactstrap';
import {
    MODE,
    SIDEBAR_SPEAKER_TYPE,
    GROUP_MODAL_TYPE,
    MODAL,
    EXTERNAL_FILE_CONTENTS,
} from '../../common/constants';
import DISP_RESOURCE from '../../common/dispResource';
import HTMLReactParser from 'html-react-parser';

import SideBarSpeakerItem from './sidebar/SideBarSpeakerItem';
import SideBarGroupMenu from './sidebar/SideBarGroupMenu';
import SideBarScheduler from './sidebar/SideBarScheduler';
import ModalGroup from './modal/ModalGroup';

/**
 * サイドバー
 */
class SideBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // スケジュールエリア高さは設定ファイルから参照
            scheduleSideHeight: `calc(100vh - ${EXTERNAL_FILE_CONTENTS.SCHEDULER_DEFAULT_HEIGHT} - 200px)`,
        };
    }

    /**
     * スケジュール設定画面のサイドバーの高さをセット
     * @param {string} scheduleSideHeight
     */
    setScheduleMaxHeight = (scheduleSideHeight) => {
        this.setState({ scheduleSideHeight });
    };

    /**
     * コンポネントの更新時の処理
     * @param {object} prevProps
     */
    componentDidUpdate = (prevProps) => {
        // スケジュール設定画面表示時、サイドバーの高さを初期化する
        if (
            this.props.mode === MODE.SET_SCHEDULES &&
            prevProps.mode !== MODE.SET_SCHEDULES
        ) {
            // スケジュールエリア高さは設定ファイルから参照
            this.setScheduleMaxHeight(
                `calc(100vh - ${EXTERNAL_FILE_CONTENTS.SCHEDULER_DEFAULT_HEIGHT} - 200px)`
            );
        }
    };
    /**
     * 画面描画
     */
    render() {
        const { scheduleSideHeight } = this.state;
        const { mode, setMode, groupsInfo, modalOpen, scheduleInfo } =
            this.props;
        return (
            <div className={`sidebar is-open`}>
                <Contents
                    mode={mode}
                    setMode={setMode}
                    modalOpen={modalOpen}
                    groupsInfo={groupsInfo}
                    scheduleInfo={scheduleInfo}
                    scheduleSideHeight={scheduleSideHeight}
                ></Contents>
            </div>
        );
    }
}
export default SideBar;

/**
 * 画面の表示状態に応じたサイドバー表示の判断を行う
 * @param {string} mode - 画面の表示状態
 * @param {function} modalOpen - モーダル表示処理
 * @param {object} groupsInfo - グループ情報
 * @param {object} scheduleInfo - スケジュール情報
 * @returns サイドバー表示
 */
const Contents = ({
    mode,
    modalOpen,
    groupsInfo,
    scheduleInfo,
    scheduleSideHeight,
}) => {
    switch (mode) {
        // フロアマップ設定(初期表示)
        case MODE.SET_FLOOR:
            return <Init modalOpen={modalOpen} />;
        //スピーカー設定
        case MODE.SET_SPEAKERS:
            return <Speakers modalOpen={modalOpen} />;
        // グループ設定
        case MODE.SET_GROUPS:
            return (
                <Groups modalOpen={modalOpen} groupsInfo={groupsInfo}></Groups>
            );
        // スケジュール設定
        case MODE.SET_SCHEDULES:
            return (
                <Scheduler
                    scheduleInfo={scheduleInfo}
                    scheduleSideHeight={scheduleSideHeight}
                ></Scheduler>
            );
        default:
            return <Init modalOpen={modalOpen} />;
    }
};

/**
 * フロアマップ設定用サイドバー（初期画面）
 * @param {function} modalOpen - モーダル表示処理
 * @returns 画面表示
 */
const Init = ({ modalOpen }) => {
    return (
        <div>
            <div className="sidebar-load-btn">
                {/* 新規フロアマップ読み込みボタン */}
                <Button
                    className="mt-3 mb-3"
                    color="secondary"
                    onClick={() => modalOpen(MODAL.NEW_FLOOR)}
                >
                    {HTMLReactParser(DISP_RESOURCE.NEW_FLOOR)}
                </Button>
                {/* 保存済フロア情報ロードボタン */}
                <Button
                    className="mt-3 mb-3"
                    color="secondary"
                    onClick={() => modalOpen(MODAL.LOAD_FLOOR)}
                >
                    {HTMLReactParser(DISP_RESOURCE.LOAD_FLOOR)}
                </Button>
                {/* 保存フロア情報の削除ボタン */}
                <Button
                    className="mt-3 mb-3"
                    color="secondary"
                    onClick={() => modalOpen(MODAL.DELETE_FLOOR)}
                >
                    {HTMLReactParser(DISP_RESOURCE.DELETE_FLOOR)}
                </Button>
            </div>
        </div>
    );
};

/**
 * スピーカー配置サイドバー
 * @returns 画面表示
 */
const Speakers = () => {
    return (
        <div className="sidebar_speakers">
            <Nav vertical>
                {
                    // サイドバースピーカーアイテムセット
                    SIDEBAR_SPEAKER_TYPE.map((item) => {
                        const { name, url, speakerTypeId } = item;
                        return (
                            <div
                                key={name}
                                className="speaker_type"
                                draggable="true"
                            >
                                <SideBarSpeakerItem
                                    name={name}
                                    url={url}
                                    speakerTypeId={speakerTypeId}
                                ></SideBarSpeakerItem>
                            </div>
                        );
                    })
                }
            </Nav>
        </div>
    );
};

/**
 * グループ設定サイドバー
 * @param {function} modalOpen - モーダル表示処理
 * @param {object} groupsInfo - グループ情報
 * @returns 画面表示
 */
const Groups = ({ modalOpen, groupsInfo }) => {
    const { groups, speakers } = groupsInfo;

    return (
        <div className="sidebar_groups">
            <Nav vertical>
                {/* グループ作成ボタン */}
                <ModalGroup
                    saveGroups={groupsInfo.createGroups}
                    type={GROUP_MODAL_TYPE.ADD}
                ></ModalGroup>
                <br></br>
                <div className="sidebar_groups_items">
                    {groups.map((item) => {
                        const { id, isDelete } = item;

                        if (isDelete) return;
                        const target = speakers.filter(
                            (speaker) => speaker.groupId === id
                        );

                        /* スピーカーアイテム */
                        return (
                            <SideBarGroupMenu
                                key={id}
                                group={item}
                                speakers={target}
                                modalOpen={modalOpen}
                                selectGroup={groupsInfo.selectGroup}
                                updateGroup={groupsInfo.updateGroup}
                                currentGroup={groupsInfo.currentGroup}
                                currentDelGroup={groupsInfo.currentDelGroup}
                                selectDelGroup={groupsInfo.selectDelGroup}
                                deleteGroup={groupsInfo.deleteGroup}
                                setSelectedUngroupSpeakerId={groupsInfo.setSelectedUngroupSpeakerId}
                                checkGroupSpeaker={groupsInfo.checkGroupSpeaker}
                            ></SideBarGroupMenu>
                        );
                    })}
                </div>
            </Nav>
        </div>
    );
};

/**
 * スケジューラーサイドバー
 * @param {object} scheduleInfo <SideBarScheduler>向けのprops群
 * @returns
 */
const Scheduler = ({ scheduleInfo, scheduleSideHeight }) => {
    const {
        groups,
        speakers,
        schedulTargets,
        speakerListFlag,
        toggleSpeakerListFlag,
        setSchedulTargets,
        putScheduleTargets,
        removeScheduleTargets,
    } = scheduleInfo;

    const changeScheduleTargetAll = () => {
        if (schedulTargets.length === speakers.length) {
            // 全選択されている場合のみ全解除
            setSchedulTargets([]);
        } else {
            // 全選択
            const speakerIds = speakers.map((speaker) => speaker.id);
            setSchedulTargets(speakerIds);
        }
    };
    return (
        <div className="sidebar_scheduler">
            <Nav vertical>
                <div className="sidebar_schedule_group_label">
                    <span>{DISP_RESOURCE.GROUP_LIST}</span>
                    <ButtonGroup className="show_speaker_list_btn">
                        <Button
                            color="success"
                            outline
                            active={!speakerListFlag}
                            onClick={() => toggleSpeakerListFlag(false)}
                        >
                            {DISP_RESOURCE.MAP}
                        </Button>
                        <Button
                            color="success"
                            outline
                            active={speakerListFlag}
                            onClick={() => toggleSpeakerListFlag(true)}
                        >
                            {DISP_RESOURCE.LIST}
                        </Button>
                    </ButtonGroup>
                </div>
                <Button
                    className="all_select_btn"
                    color="success"
                    outline
                    onClick={changeScheduleTargetAll}
                >
                    ALL
                </Button>
                <div
                    className="sidebar_schedule_group_items"
                    style={{ maxHeight: scheduleSideHeight }}
                >
                    {groups.map((item) => {
                        const { id } = item;

                        const target = speakers.filter(
                            (speaker) => speaker.groupId === id
                        );

                        /* スピーカーアイテム */
                        return (
                            <SideBarScheduler
                                key={id}
                                group={item}
                                speakers={target}
                                schedulTargets={schedulTargets}
                                putScheduleTargets={putScheduleTargets}
                                removeScheduleTargets={removeScheduleTargets}
                            ></SideBarScheduler>
                        );
                    })}
                </div>
            </Nav>
        </div>
    );
};
