import React, { createRef } from 'react';
import update from 'immutability-helper';
import classNames from 'classnames';
import moment from 'moment';
import DISP_RESOURCE from '../../../common/dispResource';
import {
    Button,
    Col,
    Input,
    Label,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    FormFeedback,
    Popover,
} from 'reactstrap';
import {
    CHOICE_MODAL_OPT,
    EXTERNAL_FILE_CONTENTS as EX_FILE,
    PERIOD_ERR_TYPE,
    REPEAT_TYPE,
    SCHEDULE_COLORS,
    SCHEDULE_GROUP_SPEAKER_ID_PREFIX,
} from '../../../common/constants';

import RepeatSettings from './RepeatSettings';
import ModalChoice from '../modal/ModalChoice';
import ModalScheduleDuplicate from '../modal/ModalScheduleDuplicate';
import * as SCHEDULE_UTILS from './scheduleUtils';
import { ReactComponent as Recyclebin } from '../../../img/recycle_bin.svg';

export default class ScheduleSetting extends React.Component {
    /**
     * スケジュール詳細設定
     * @param {object}   props                - コンポーネント呼び出し側から渡される値
     * @param {object[]} props.speakers       - スピーカーリスト
     * @param {object[]} props.rows           - グループリスト
     * @param {number[]} props.targets        - 選択中のスピーカIDs
     * @param {object[]} props.schedules      - スケジュールリスト
     * @param {object[]} props.soundSources   - 音源リスト
     * @param {function} props.createSchedules- スケジュール追加処理
     * @param {function} props.updateSchedule - スケジュール更新処理
     * @param {function} props.deleteSchedule - スケジュール削除処理
     * @param {function} props.close          - 当画面を非表示処理
     */
    constructor(props) {
        super(props);

        this.state = {
            startTimePop: false,
            endTimePop: false,
            colorPallet: false,
            scheduleInfo: {
                id: null, // スケジュールID
                scheduleRepeatId: null, // スケジュール繰返しID
                speakerId: null, // スピーカーID
                groupScheduleId: null, // グループスケジュール親ID
                scheduleName: '', // スケジュールタイトル
                periodStartDate: moment().format('YYYY-MM-DD'), // 開始日付
                periodStartTime: moment().format('HH:mm'), // 開始時刻
                periodEndDate: moment().add(1, 'hour').format('YYYY-MM-DD'), // 終了日付
                periodEndTime: moment().add(1, 'hour').format('HH:mm'), // 終了時刻
                repeatEndDate: moment().add(1, 'years').format('YYYY-MM-DD'), // 繰返し終了日付
                soundSource: 1, // 音源
                volume: 0, // 音量
                colorId: 0, // カラー
                repeat: false, // 繰返しフラグ
                repeatType: null, // 繰返しタイプ
                repeatOption1: '', // 繰返しオプション(テキスト、プルダウン)
                repeatOption2: [], // 繰返しオプション(チェックボックス１)
                repeatOption3: [], // 繰返しオプション(チェックボックス２)
                repeatCustom: [], // 繰返しカスタムオプション(日付文字列)
            },
            invalid: {
                title: false,
                period: null,
                repeat: false,
                repeatEndDate: null,
                soundSource: false,
            },
        };

        /**
         * @type {object} 削除オプション（グループ削除、繰返し削除）
         */
        this.deleteOptions = {
            groupDeleteFlag: null,
            repeatDeleteFlag: null,
        };
        /**
         * @type {object} 編集更新オプション（グループ更新、繰返し更新）
         */
        this.updateOptions = {
            periodChangeFlag: false,
            repeatChangeFlag: false,
            repeatEndChangeFlag: false,
            groupUpdateFlag: null,
            repeatUpdateFlag: null,
        };
        /**
         * @type {object[]} 期間重複検索結果リスト
         */
        this.duplicateList = [
            //{speakerId: ?, scheduleId: ?, updateType: 0 or 1 or 2 or 3}
        ];

        /**
         * 操作中かどうか（登録・更新・削除ボタンの連打防止用）
         */
        this.isOperating = false;

        this.RepeatSettings = createRef();
        this.DeleteGroupChoiceModal = createRef();
        this.DeleteRepeatChoiceModal = createRef();
        this.UpdateGroupChoiceModal = createRef();
        this.UpdateRepeatChoiceModal = createRef();
        this.DuplicateTypeChoiceModal = createRef();
    }

    /**
     * スケジュール情報更新
     * @param {Object} target スケジュール情報 更新情報
     */
    setScheduleInfo = (target) => {
        this.setState({
            scheduleInfo: update(this.state.scheduleInfo, { $merge: target }),
        });
    };

    setDuplicateList = (duplicateList) => {
        return new Promise((resolve) =>
            this.setState({ duplicateList }, resolve)
        );
    };

    /**
     * カラーパレット表示
     */
    showColorPallet = () => {
        this.setState({ colorPallet: !this.state.colorPallet });
    };

    setTimeSelector = (time, isStartTime) => {
        if (isStartTime) {
            this.setScheduleInfo({ periodStartTime: time });
            this.showStartTimeSelector();
        } else {
            this.setScheduleInfo({ periodEndTime: time });
            this.showEndTimeSelector();
        }

        setTimeout(function() {
            this.adjustSchduleRange();
        }.bind(this), 100);
    };

    /**
     * 開始日設定
     * @param {*} e 
     */
    setStartPeriodDate = (e) => {
        this.setScheduleInfo({
            periodStartDate: e.target.value,
        });
        
        setTimeout(function() {
            this.adjustSchduleRange();
        }.bind(this), 100);
    }

    /**
     * 開始時刻設定
     * @param {*} e 
     */
    setStartPeriodTime = (e) => {
        const time = e.target.value;
        this.setScheduleInfo({
            periodStartTime: time,
        });

        if (time !== '') {
            this.setTimePopHeight(true, time);
        }
        
        setTimeout(function() {
            this.adjustSchduleRange();
        }.bind(this), 100);
    };

    /**
     * 終了時刻設定
     * @param {*} e 
     */
    setEndPeriodTime = (e) => {
        const time = e.target.value;

        this.setScheduleInfo({
            periodEndTime: time,
        });

        if (time !== '') {
            this.setTimePopHeight(false, time);
        }

        setTimeout(function() {
            this.adjustSchduleRange();
        }.bind(this), 100);
    };

    /**
     * 期間開始時刻セレクト表示
     */
    showStartTimeSelector = () => {
        const { startTimePop } = this.state;
        this.setState({ startTimePop: !startTimePop });

        setTimeout(() => {
            this.setTimePopHeight(true);
        }, 10);
    };

    /**
     * 期間終了時刻セレクト表示
     */
    showEndTimeSelector = () => {
        const { endTimePop } = this.state;
        this.setState({ endTimePop: !endTimePop });

        setTimeout(() => {
            this.setTimePopHeight();
        }, 10);
    };

    /**
     * 期間時間のセレクター表示時の高さ調整
     * @param {boolean} isStart 開始時間のセレクター
     */
    setTimePopHeight = (isStart = false, inputTime = null) => {
        const { scheduleInfo } = this.state;

        const timeType = isStart ? 'start' : 'end';

        const time =
            inputTime !== null
                ? inputTime
                : isStart
                ? scheduleInfo.periodStartTime
                : scheduleInfo.periodEndTime;

        let timeName = `00:00_${timeType}`;

        if (
            time.endsWith(':15') ||
            time.endsWith(':30') ||
            time.endsWith(':45')
        ) {
            timeName = `${time}_${timeType}`;
        } else {
            timeName = `${time.substring(0, 2)}:00_${timeType}`;
        }
        const timeElement = document.getElementsByClassName(timeName);

        if (timeElement.length > 0) {
            const offsetHeight = timeElement[0].offsetTop;
            document
                .getElementsByClassName(`${timeType}_time_inner`)[0]
                .scrollTo(0, offsetHeight);
        }
    };

    /**
     * 選択済みカラー表示制御
     * @param {number} colorId // カラーID
     * @returns
     */
    checkColor = (colorId) => {
        return this.state.scheduleInfo.colorId === colorId;
    };

    /**
     * スケジュールのカラーを更新
     * @param {string} colorId
     */
    setColor = (colorId) => {
        this.setScheduleInfo({ colorId });
        this.showColorPallet();
    };

    /**
     * スケジュール詳細設定画面初期化
     * @param {object} schedule 更新対象のスケジュール情報（初期値はnull）
     */
    init = (schedule = null) => {
        let scheduleInfo = {
            id: null, // スケジュールID
            scheduleRepeatId: null, // スケジュール繰返しID
            speakerId: null, // スピーカーID
            groupScheduleId: null, // グループスケジュール親ID
            scheduleName: '', // スケジュールタイトル
            periodStartDate: moment().format('YYYY-MM-DD'), // 開始日付
            periodStartTime: moment().format('HH:mm'), // 開始時刻
            periodEndDate: moment().add(1, 'hour').format('YYYY-MM-DD'), // 終了日付
            periodEndTime: moment().add(1, 'hour').format('HH:mm'), // 終了時刻
            repeatEndDate: moment().add(1, 'years').format('YYYY-MM-DD'), // 繰返し終了日付
            soundSource: 1, // 音源
            volume: 0, // 音量
            colorId: 0, // カラー
            repeat: false, // 繰返しフラグ
            repeatType: null, // 繰返しタイプ
            repeatOption1: '', // 繰返しオプション
            repeatOption2: [], // 繰返しオプション
            repeatOption3: [], // 繰返しオプション
            repeatCustom: [], // 繰返しオプション
        };

        // 既存スケジュール選択時、初期値を上書き
        if (schedule) {
            scheduleInfo = {
                id: schedule.scheduleId, // スケジュールID
                scheduleRepeatId: schedule.scheduleRepeatId,
                speakerId: schedule.speakerId,
                groupScheduleId: schedule.scheduleGroupId, // グループスケジュール親ID
                scheduleName: schedule.scheduleName, // スケジュールタイトル
                periodStartDate: schedule.periodStartDate, // 開始日付
                periodStartTime: schedule.periodStartTime, // 開始時刻
                periodEndDate: schedule.periodEndDate, // 終了日付
                periodEndTime: schedule.periodEndTime, // 終了時刻
                repeatEndDate: schedule.repeatEndDate, // 繰返し終了日付
                soundSource: schedule.soundSource, // 音源
                volume: schedule.volume, // 音量
                colorId: schedule.colorId, // カラー
                repeat: schedule.repeatFlag, // 繰返しフラグ
                repeatType: schedule.repeatType, // 繰返しタイプ
                repeatOption1: schedule.repeatOption1, // 繰返しオプション
                repeatOption2: schedule.repeatOption2, // 繰返しオプション
                repeatOption3: schedule.repeatOption3, // 繰返しオプション
                repeatCustom: schedule.repeatCustom, // 繰返しオプション
            };
        }

        // バリデーションチェックリセット
        const invalid = {
            title: false,
            period: null,
            repeat: false,
            repeatEndDate: null,
            soundSource: false,
        };

        this.setState({ scheduleInfo, invalid });

        // 削除オプションリセット
        this.deleteOptions = {
            groupDeleteFlag: null,
            repeatDeleteFlag: null,
        };

        // 更新オプションリセット
        this.updateOptions = {
            periodChangeFlag: false,
            repeatChangeFlag: false,
            groupUpdateFlag: null,
            repeatUpdateFlag: null,
        };

        // 重複スケジュールリストリセット
        this.duplicateList = [];
    };

    /**
     * 保存前処理（バリデーション、重複スケジュール確認）
     * @returns
     */
    beforeSave = async () => {
        if (this.isOperating) {
            // 操作中の場合何もしない
            return;
        }
        const { schedules, close } = this.props;
        const { scheduleInfo: newSch } = this.state;

        // バリデーションチェック
        if (this.validation()) return;
        this.isOperating = true;

        // スケジュールIDがない場合、新規追加
        if (newSch.id === null) {
            await this.checkDuplicateSchedule(true);
        } else {
            // 変更確認
            const oldSch = schedules.find(
                (sch) =>
                    !sch.speakerId.startsWith(
                        SCHEDULE_GROUP_SPEAKER_ID_PREFIX
                    ) && sch.scheduleRepeatId === newSch.scheduleRepeatId
            );

            const changedInfo = SCHEDULE_UTILS.diffScheduleData(newSch, oldSch);

            // 変更なし、設定画面を閉じる
            if (
                !changedInfo.periodChangeFlag &&
                !changedInfo.repeatChangeFlag &&
                !changedInfo.repeatEndChangeFlag &&
                !changedInfo.othersChangeFlag
            ) {
                close();
                this.isOperating = false;
                return;
            }

            this.updateOptions.periodChangeFlag = changedInfo.periodChangeFlag;
            this.updateOptions.repeatChangeFlag = changedInfo.repeatChangeFlag;
            this.updateOptions.repeatEndChangeFlag = changedInfo.repeatEndChangeFlag;

            // 対象のスケジュールがグループに属している場合
            if (newSch.groupScheduleId !== null) {
                if (changedInfo.isGroupHeader) {
                    // グループ行から更新の場合、グループモーダルを表示せず、クループ処理('0')として扱う
                    this.updateOptions.groupUpdateFlag = CHOICE_MODAL_OPT.FIRST;

                    if (
                        oldSch.repeatFlag &&
                        newSch.repeat &&
                        changedInfo.periodChangeFlag
                    ) {
                        // 既に「繰返しスケジュール」で期間が変わった場合、処理範囲を選択
                        this.UpdateRepeatChoiceModal.current.init();
                    } else {
                        await this.checkDuplicateSchedule();
                    }
                } else {
                    // グループモーダルを表示
                    this.UpdateGroupChoiceModal.current.init();
                }
            } else if (
                oldSch.repeatFlag &&
                newSch.repeat &&
                changedInfo.periodChangeFlag
            ) {
                this.UpdateRepeatChoiceModal.current.init();
            } else {
                await this.checkDuplicateSchedule();
            }
        }
        this.isOperating = false;
    };

    /**
     * スケジュール追加、更新時時間重複を確認
     * @returns boolean
     */
    checkDuplicateSchedule = async (isNew = false) => {
        const { schedules, targets, rows } = this.props;
        const { scheduleInfo } = this.state;

        // 更新の場合
        if (!isNew) {
            // 期間変更無し、繰返し変更無しは重複確認を省略する
            if (
                !this.updateOptions.periodChangeFlag &&
                !this.updateOptions.repeatChangeFlag
            ) {
                this.save(isNew);
                return;
            }
        }

        let newTargets = [];
        if (
            this.updateOptions.groupUpdateFlag !== null &&
            this.updateOptions.groupUpdateFlag === CHOICE_MODAL_OPT.FIRST &&
            targets.length === 1
        ) {
            // 単体のスピーカーのスケジュール編集後、変更内容をスケジュールグループ全体に反映する場合
            // 選択中のスケジュールを含むスケジュールグループを検索
            const targetGroupSch = schedules.filter(
                (sch) =>
                    sch.speakerId.includes('group') &&
                    sch.scheduleGroupId === scheduleInfo.groupScheduleId
            );
            const targetRowIdx = targetGroupSch.map((sch) => {
                return rows.findIndex((row) => row.speakerId === sch.speakerId);
            });

            // 検索したスケジュールグループに含まれるスピーカーを選択中のスピーカーとして設定
            newTargets = rows[targetRowIdx[0]].speakerIds.map((id) =>
                Number.parseInt(id)
            );
        }

        const newScheduleList = SCHEDULE_UTILS.getNewScheduleList(
            newTargets.length !== 0 ? newTargets : targets,
            scheduleInfo
        );

        const duplicateList = SCHEDULE_UTILS.getDuplicateSchedules(
            newTargets.length !== 0 ? newTargets : targets,
            newScheduleList,
            schedules,
            scheduleInfo
        );

        if (duplicateList.length !== 0) {
            this.duplicateList = duplicateList;
            this.DuplicateTypeChoiceModal.current.init(this.duplicateList[0]);
        } else {
            await this.save(isNew);
        }
    };

    /**
     * スケジュール追加又は更新
     */
    save = async (isNew) => {
        const { scheduleInfo } = this.state;
        const { createSchedules, updateSchedule } = this.props;

        if (isNew) {
            // 新規
            await createSchedules(scheduleInfo, this.duplicateList);
        } else {
            // 更新
            await updateSchedule(
                scheduleInfo,
                this.updateOptions,
                this.duplicateList
            );
        }
    };

    /**
     * スケジュール削除
     */
    beforeDelete = async () => {
        if (this.isOperating) {
            // 操作中の場合何もしない
            return;
        }
        this.isOperating = true;

        const { scheduleInfo: newSch } = this.state;
        const { schedules, close } = this.props;

        if (newSch.id !== null) {
            const oldSch = schedules.find(
                (sch) => sch.scheduleRepeatId === newSch.scheduleRepeatId
            );

            // グループスケジュールの場合
            if (newSch.groupScheduleId !== null) {
                // 既存スケジュールの場合
                const isGroupHeader = newSch.speakerId.startsWith(
                    SCHEDULE_GROUP_SPEAKER_ID_PREFIX
                );

                if (isGroupHeader) {
                    // グループ行のスケジュール選択した場合、グループ処理
                    this.deleteOptions.groupDeleteFlag = CHOICE_MODAL_OPT.FIRST;

                    // 繰返しありのスケジュールなら繰返し処理モーダル表示
                    if (oldSch.repeatFlag) {
                        this.DeleteRepeatChoiceModal.current.init();
                    } else {
                        // 単体スケジュールの場合
                        await this.delete();
                    }
                } else {
                    this.DeleteGroupChoiceModal.current.init();
                }
            } else if (oldSch.repeatFlag) {
                // 繰返しありのスケジュールなら繰返し処理モーダル表示
                this.DeleteRepeatChoiceModal.current.init();
            } else {
                await this.delete();
            }
        } else {
            // 新規の場合、削除なし
            close();
        }
        this.isOperating = false;
    };

    /**
     * スケジュール削除
     */
    delete = async () => {
        const { scheduleInfo } = this.state;
        const { deleteSchedule } = this.props;

        await deleteSchedule(scheduleInfo, this.deleteOptions);
    };

    /**
     * スケジュール編集モーダル（グループ編集確認）
     * @param {*} result
     */
    choiceUpdateGroup = async (result) => {
        this.isOperating = true;
        const { scheduleInfo: newSch } = this.state;
        const { schedules } = this.props;

        const oldSch = schedules.find(
            (sch) => sch.scheduleRepeatId === newSch.scheduleRepeatId
        );

        // result = 0 グループ反映
        // result = 1 単体編集
        this.updateOptions.groupUpdateFlag = result;

        if (
            oldSch.repeatFlag &&
            newSch.repeat &&
            this.updateOptions.periodChangeFlag
        ) {
            // 既に「繰返しスケジュール」で期間が変わった場合、処理範囲を選択
            this.UpdateRepeatChoiceModal.current.init();
        } else {
            await this.checkDuplicateSchedule();
        }
        this.isOperating = false;
    };

    /**
     * スケジュール編集モーダル（繰返し更新確認）
     * @param {*} result
     */
    choiceUpdateRepeat = async (result) => {
        this.isOperating = true;
        // result = 0 単体更新
        // result = 1 以降スケジュール更新
        this.updateOptions.repeatUpdateFlag = result;
        await this.checkDuplicateSchedule();
        this.isOperating = false;
    };

    /**
     * 重複スケジュール更新処理選択
     * @param {*} result
     */
    choiceDuplicateType = async (result) => {
        this.isOperating = true;
        const { scheduleRepeatId, choice, applyAll } = result;

        const isNew = this.state.scheduleInfo.id === null;

        // 以降重複同じ処理
        if (applyAll) {
            for (let i = 0; i < this.duplicateList.length; i++) {
                if (this.duplicateList[i].applyType === null) {
                    this.duplicateList[i].applyType = choice;
                }
            }
            await this.save(isNew);
            this.isOperating = false;
        } else {
            // 次の重複処理選択モーダル表示
            const item = this.duplicateList.find(
                (sch) => sch.scheduleRepeatId === scheduleRepeatId
            );
            item.applyType = choice;

            const next = this.duplicateList.find(
                (sch) => sch.applyType === null
            );
            if (next === undefined) {
                await this.save(isNew);
                this.isOperating = false;
            } else {
                setTimeout(() => {
                    this.DuplicateTypeChoiceModal.current.init(next);
                    this.isOperating = false;
                }, 100);
            }
        }
    };

    /**
     * スケジュール削除モーダル（グループ削除確認）
     * @param {*} result
     */
    choiceDeleteGroup = async (result) => {
        this.isOperating = true;
        const { scheduleInfo: newSch } = this.state;
        const { schedules } = this.props;

        const oldSch = schedules.find(
            (sch) => sch.scheduleRepeatId === newSch.scheduleRepeatId
        );

        // result = 0 グループ削除
        // result = 1 単体削除
        this.deleteOptions.groupDeleteFlag = result;

        if (oldSch.repeatFlag) {
            this.DeleteRepeatChoiceModal.current.init();
        } else {
            await this.delete();
        }
        this.isOperating = false;
    };

    /**
     * スケジュール削除モーダル（繰返し削除確認）
     * @param {*} result
     */
    choiceDeleteRepeat = async (result) => {
        this.isOperating = true;
        // result = 0 単体削除
        // result = 1 以降スケジュール削除
        this.deleteOptions.repeatDeleteFlag = result;

        await this.delete();
        this.isOperating = false;
    };

    /**
     * バリデーションチェック
     */
    validation = () => {
        const {
            scheduleName,
            periodStartDate,
            periodStartTime,
            periodEndDate,
            periodEndTime,
            repeatEndDate,
            repeat,
            repeatType,
            repeatOption2,
            repeatOption3,
            repeatCustom,
        } = this.state.scheduleInfo;
        const { soundSources } = this.props;

        let isInvalid = false;
        let invalid = {
            title: false,
            period: null,
            repeat: false,
            repeatEndDate: null,
        };

        // スケジュール名
        invalid.title = scheduleName === '';

        const periodEmpty =
            periodStartDate === '' ||
            periodStartTime === '' ||
            periodEndDate === '' ||
            periodEndTime === '';
        const start = moment(
            periodStartDate + ' ' + periodStartTime,
            'YYYY-MM-DD HH:mm'
        );
        const end = moment(
            periodEndDate + ' ' + periodEndTime,
            'YYYY-MM-DD HH:mm'
        );

        // スケジュール期間
        invalid.period = periodEmpty
            ? PERIOD_ERR_TYPE.PERIOD_EMPTY
            : start.diff(end) >= 0
            ? PERIOD_ERR_TYPE.AFTER_START
            : end.diff(start, 'days') >= 1
            ? PERIOD_ERR_TYPE.NOT_ONE_DAY
            : null;

        // 繰返しオプション入力
        if (repeat) {
            switch (repeatType) {
                // 毎週、週月（日付指定）
                case REPEAT_TYPE.EVERY_WEEK:
                case REPEAT_TYPE.PICK_DATE:
                    invalid.repeat = repeatOption2.length === 0;
                    invalid.repeatEndDate = repeatEndDate === ''
                        ? PERIOD_ERR_TYPE.PERIOD_EMPTY
                        : periodStartDate >= repeatEndDate
                        ? PERIOD_ERR_TYPE.AFTER_START
                        : null;
                    break;
                // 週月（曜日指定）
                case REPEAT_TYPE.PICK_DAY:
                    invalid.repeat =
                        repeatOption2.length === 0 ||
                        repeatOption3.length === 0;
                    invalid.repeatEndDate = repeatEndDate === ''
                        ? PERIOD_ERR_TYPE.PERIOD_EMPTY
                        : periodStartDate >= repeatEndDate
                        ? PERIOD_ERR_TYPE.AFTER_START
                        : null;
                    break;
                // 周年
                case REPEAT_TYPE.EVERY_YEAR:
                    invalid.repeatEndDate = repeatEndDate === ''
                        ? PERIOD_ERR_TYPE.PERIOD_EMPTY
                        : periodStartDate >= repeatEndDate
                        ? PERIOD_ERR_TYPE.AFTER_START
                        : null;
                    break;
                // カスタム
                case REPEAT_TYPE.CUSTOM:
                    invalid.repeat = repeatCustom.length === 0;
                    break;
                default:
                    break;
            }
        }

        // 音源取得失敗
        invalid.soundSource = soundSources.length === 0;

        this.setState({ invalid });

        isInvalid =
            invalid.title ||
            invalid.period !== null ||
            invalid.repeat ||
            invalid.repeatEndDate !== null ||
            invalid.soundSource;
        return isInvalid;
    };

    /**
     * 音源プルダウンのオプション
     */
    soundSourceOptions = () => {
        const { soundSources } = this.props;

        const sources = [];
        for (const source of soundSources) {
            if (source.soundSourceNo === EX_FILE.TEST_SOUND_SOURCE_ID || source.soundSource === '') continue;

            sources.push(
                <option
                    key={`${source.soundSourceNo}-sound-source`}
                    value={source.soundSourceNo}
                >
                    {source.soundSourceName}
                </option>
            );
        }

        if (sources.length === 0) {
            sources.push(<option key="empty_sound_source"></option>);
        }

        return sources;
    };

    /**
     * 音量プルダウンのオプション
     */
    volumeOptions = () => {
        const max = EX_FILE.SCHEDULE_MAX_VOLUME;
        const min = EX_FILE.SCHEDULE_MIN_VOLUME;

        const volumes = [];
        for (let idx = min; idx <= max; idx = idx + 3) {
            volumes.push(
                <option key={`${idx}-schedule-volume`} value={idx}>
                    {idx}dB
                </option>
            );
        }
        return volumes;
    };

    /**
     * 繰返し領域制御
     */
    showRepeat = () => {
        const { scheduleInfo } = this.state;
        const { schedules } = this.props;

        // 繰返しなしの場合、繰返し用パラメータクリア
        if (scheduleInfo.repeat) {
            this.setScheduleInfo({
                repeat: false,
                repeatType: null,
                repeatOption1: '',
                repeatOption2: [],
                repeatOption3: [],
                repeatCustom: [],
            });
        } else {
            // 繰返しありの場合
            let repeatInfo = {
                repeatType: REPEAT_TYPE.EVERY_DAY, // 繰返しタイプ
                repeatOption1: '5', // 繰返しオプション
                repeatOption2: [], // 繰返しオプション
                repeatOption3: [], // 繰返しオプション
                repeatCustom: [], // 繰返しオプション
            };

            // 既存スケジュール取得（更新時のみ取得される）
            const oldSch = schedules.find(
                (sch) => sch.scheduleRepeatId === scheduleInfo.scheduleRepeatId
            );

            // 既存スケジュールの繰返し情報がある場合、繰返し情報をセット
            if (typeof oldSch !== 'undefined' && oldSch.repeatFlag) {
                repeatInfo = {
                    repeatType: oldSch.repeatType, // 繰返しタイプ
                    repeatOption1: oldSch.repeatOption1, // 繰返しオプション
                    repeatOption2: oldSch.repeatOption2, // 繰返しオプション
                    repeatOption3: oldSch.repeatOption3, // 繰返しオプション
                    repeatCustom: oldSch.repeatCustom, // 繰返しオプション
                };
            }

            // 繰返し編集領域表示
            this.setScheduleInfo({
                repeat: true,
                ...repeatInfo,
            });
        }
        
        setTimeout(function() {
            this.adjustSchduleRange();
        }.bind(this), 100);
    };

    /**
     * スケジュール終了日を調整する
     */
    adjustSchduleRange() {
        const {
            periodStartDate,
            periodStartTime,
            periodEndTime,
        } = this.state.scheduleInfo;

        // パラメータ不足の場合は終了
        const periodEmpty =
            periodStartDate === '' ||
            periodStartTime === '' ||
            periodEndTime === '';
        if (periodEmpty) return;

        let start = moment(
            periodStartDate + ' ' + periodStartTime,
            'YYYY-MM-DD HH:mm'
        );
        let end = moment(
            periodStartDate + ' ' + periodEndTime,
            'YYYY-MM-DD HH:mm'
        );
        
        // start >= end の場合、1日加算
        if (start.diff(end) >= 0) {
            end.add(1, 'd');
        }

        // パラメータ更新
        this.setScheduleInfo({periodEndDate: end.format('YYYY-MM-DD')});
    };

    /**
     * 画面描画
     */
    render() {
        const { startTimePop, endTimePop, colorPallet, scheduleInfo, invalid } =
            this.state;
        const { speakers, schedules, close } = this.props;

        return (
            <div className="schedule_settings">
                <div className="schedule_setting_content">
                    <Row className="schdule_detail">
                        <Col sm={12}>{DISP_RESOURCE.SCHEDULE_DETAIL}</Col>
                    </Row>
                    <Row>
                        <Col sm={2}>{DISP_RESOURCE.SPEAKER_NAME}</Col>
                        <Col sm={8} className="schedule_speakers">
                            <this.SpeakerNames />
                        </Col>
                    </Row>
                    <Row>
                        <Label for="title" sm={2}>
                            {DISP_RESOURCE.SCHEDULE_NAME}
                        </Label>
                        <Col sm={6}>
                            <Input
                                id="title"
                                type="text"
                                maxLength={20}
                                invalid={invalid.title}
                                value={scheduleInfo.scheduleName}
                                onChange={(e) =>
                                    this.setScheduleInfo({
                                        scheduleName: e.target.value,
                                    })
                                }
                            />
                            {invalid.title && (
                                <FormFeedback>
                                    {DISP_RESOURCE.INPUT_REQUIRED}
                                </FormFeedback>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Label for="period" sm={2}>
                            {DISP_RESOURCE.SCHEDULE_PERIOD}
                        </Label>
                        <Col sm={2} style={{ display: 'flex' }}>
                            {/* 開始日付 */}
                            <Input
                                id="period"
                                type="date"
                                className={classNames(
                                    invalid.period !== null
                                        ? 'invalid_period'
                                        : ''
                                )}
                                value={scheduleInfo.periodStartDate}
                                onChange={this.setStartPeriodDate}
                            ></Input>
                        </Col>
                        {/* 開始時間*/}
                        <Col
                            className="time_with_tilde"
                            sm={2}
                            style={{ display: 'flex' }}
                        >
                            <Input
                                id="startTimePop"
                                type="time"
                                step={900}
                                className={classNames(
                                    'start_time_input',
                                    invalid.period !== null
                                        ? 'invalid_period'
                                        : ''
                                )}
                                value={scheduleInfo.periodStartTime}
                                onChange={this.setStartPeriodTime}
                            ></Input>
                            <Popover
                                popperClassName="start_time_pop"
                                innerClassName="start_time_inner"
                                fade={false}
                                hideArrow={true}
                                offset={[0, 0]}
                                placement="bottom-end"
                                target="startTimePop"
                                trigger="legacy"
                                isOpen={startTimePop}
                                toggle={this.showStartTimeSelector}
                            >
                                <div className="start_time_selector">
                                    {(() => {
                                        const times = [];
                                        for (let i = 0; i < 24; i++) {
                                            const time =
                                                i < 10 ? `0${i}` : i.toString();
                                            times.push(
                                                <p
                                                    key={`start_time_${i}_0`}
                                                    className={classNames(
                                                        `${time}:00_start`,
                                                        `${time}:00` ===
                                                            scheduleInfo.periodStartTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:00`,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {time}:00
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`start_time_${i}_1`}
                                                    className={classNames(
                                                        `${time}:15_start`,
                                                        `${time}:15` ===
                                                            scheduleInfo.periodStartTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:15`,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {time}:15
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`start_time_${i}_2`}
                                                    className={classNames(
                                                        `${time}:30_start`,
                                                        `${time}:30` ===
                                                            scheduleInfo.periodStartTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:30`,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {time}:30
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`start_time_${i}_3`}
                                                    className={classNames(
                                                        `${time}:45_start`,
                                                        `${time}:45` ===
                                                            scheduleInfo.periodStartTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:45`,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {time}:45
                                                </p>
                                            );
                                        }
                                        return times;
                                    })()}
                                </div>
                            </Popover>
                        </Col>
                        {/* 終了日付 */}
                        {/* <Col sm={2} style={{ display: 'flex' }}>
                            <Input
                                id="period"
                                type="date"
                                style={{ marginLeft: 5 }}
                                className={classNames(
                                    invalid.period !== null
                                        ? 'invalid_period'
                                        : ''
                                )}
                                value={scheduleInfo.periodEndDate}
                                onChange={(e) =>
                                    this.setScheduleInfo({
                                        periodEndDate: e.target.value,
                                    })
                                }
                            ></Input>
                        </Col> */}
                        {/* 終了時間 */}
                        <Col
                            className="time"
                            sm={2}
                            style={{ display: 'flex' }}
                        >
                            <Input
                                id="endTimePop"
                                type="time"
                                step={900}
                                className={classNames(
                                    'start_time_input',
                                    invalid.period !== null
                                        ? 'invalid_period'
                                        : ''
                                )}
                                value={scheduleInfo.periodEndTime}
                                onChange={this.setEndPeriodTime}
                            ></Input>
                            <Popover
                                popperClassName="end_time_pop"
                                innerClassName="end_time_inner"
                                fade={false}
                                hideArrow={true}
                                offset={[0, 0]}
                                placement="bottom-end"
                                target="endTimePop"
                                trigger="legacy"
                                isOpen={endTimePop}
                                toggle={this.showEndTimeSelector}
                            >
                                <div className="end_time_selector">
                                    {(() => {
                                        const times = [];
                                        for (let i = 0; i < 24; i++) {
                                            const time =
                                                i < 10 ? `0${i}` : i.toString();
                                            times.push(
                                                <p
                                                    key={`end_time_${i}_0`}
                                                    className={classNames(
                                                        `${time}:00_end`,
                                                        `${time}:00` ===
                                                            scheduleInfo.periodEndTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:00`
                                                        )
                                                    }
                                                >
                                                    {time}:00
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`end_time_${i}_1`}
                                                    className={classNames(
                                                        `${time}:15_end`,
                                                        `${time}:15` ===
                                                            scheduleInfo.periodEndTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:15`
                                                        )
                                                    }
                                                >
                                                    {time}:15
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`end_time_${i}_2`}
                                                    className={classNames(
                                                        `${time}:30_end`,
                                                        `${time}:30` ===
                                                            scheduleInfo.periodEndTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:30`
                                                        )
                                                    }
                                                >
                                                    {time}:30
                                                </p>
                                            );
                                            times.push(
                                                <p
                                                    key={`end_time_${i}_3`}
                                                    className={classNames(
                                                        `${time}:45_end`,
                                                        `${time}:45` ===
                                                            scheduleInfo.periodEndTime
                                                            ? 'active'
                                                            : ''
                                                    )}
                                                    onClick={() =>
                                                        this.setTimeSelector(
                                                            `${time}:45`
                                                        )
                                                    }
                                                >
                                                    {time}:45
                                                </p>
                                            );
                                        }
                                        return times;
                                    })()}
                                </div>
                            </Popover>
                        </Col>
                        {/* 繰返しスイッチ */}
                        <Col
                            className={classNames(
                                invalid.period !== null ? 'is-invalid' : ''
                            )}
                            sm={1}
                        >
                            <div className="custom_switch schedule_setting_switch">
                                <Input
                                    id="switch"
                                    type="checkbox"
                                    checked={scheduleInfo.repeat}
                                    onChange={this.showRepeat}
                                />
                                <label htmlFor="switch">
                                    {scheduleInfo.repeat && (
                                        <span className="checked switch_label">
                                            {DISP_RESOURCE.REPEAT_ON}
                                        </span>
                                    )}
                                    {!scheduleInfo.repeat && (
                                        <span className="switch_label">
                                            {DISP_RESOURCE.REPEAT_OFF}
                                        </span>
                                    )}
                                </label>
                            </div>
                        </Col>
                        {invalid.period !== null && (
                            <FormFeedback className="period_err_message">
                                {(() => {
                                    switch (invalid.period) {
                                        case PERIOD_ERR_TYPE.NOT_ONE_DAY:
                                            return DISP_RESOURCE.PERIOD_ERROR_NOT_ONE_DAY;
                                        case PERIOD_ERR_TYPE.PERIOD_EMPTY:
                                            return DISP_RESOURCE.INPUT_REQUIRED;
                                        case PERIOD_ERR_TYPE.AFTER_START:
                                            return DISP_RESOURCE.PERIOD_ERROR_START_LATE_THEN_END;
                                        default:
                                            return '';
                                    }
                                })()}
                            </FormFeedback>
                        )}
                    </Row>
                    {/* 音源 */}
                    <Row>
                        <Label for="sound_source" sm={2}>
                            {DISP_RESOURCE.SOUND_SOURCE}
                        </Label>
                        <Col sm={5}>
                            <Input
                                id="sound_source"
                                type="select"
                                invalid={invalid.soundSource}
                                value={scheduleInfo.soundSource}
                                onChange={(e) =>
                                    this.setScheduleInfo({
                                        soundSource: e.target.value,
                                    })
                                }
                            >
                                {this.soundSourceOptions()}
                            </Input>
                            {invalid.soundSource && (
                                <FormFeedback>
                                    {DISP_RESOURCE.NOT_FOUND_SOUND_SOURCES}
                                </FormFeedback>
                            )}
                        </Col>
                    </Row>
                    {/* 音量 */}
                    <Row>
                        <Label for="schedule_volume" sm={2}>
                            {DISP_RESOURCE.VOLUME}
                        </Label>
                        <Col sm={2}>
                            <Input
                                id="schedule_volume"
                                type="select"
                                value={scheduleInfo.volume}
                                onChange={(e) =>
                                    this.setScheduleInfo({
                                        volume: e.target.value,
                                    })
                                }
                            >
                                {this.volumeOptions()}
                            </Input>
                        </Col>
                    </Row>
                    {/* カラー */}
                    <Row>
                        <Label for="color" sm={2}>
                            {DISP_RESOURCE.COLOR}
                        </Label>
                        <Col sm={2}>
                            <Dropdown
                                className="color_dropdown"
                                isOpen={colorPallet}
                                toggle={() =>
                                    this.showColorPallet(
                                        (prevState) => !prevState
                                    )
                                }
                            >
                                <DropdownToggle
                                    tag="button"
                                    className="form-select"
                                    caret
                                    color="none"
                                >
                                    <span
                                        className={'color_selected'}
                                        style={{
                                            background:
                                                SCHEDULE_COLORS[
                                                    scheduleInfo.colorId
                                                ].hex,
                                        }}
                                    >
                                        &emsp;
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <div className="color_pallet">
                                        {(() => {
                                            let idx = 0;
                                            const rows = [];

                                            for (let row = 0; row < 2; row++) {
                                                const cols = [];
                                                for (
                                                    let col = 0;
                                                    col < 6;
                                                    col++
                                                ) {
                                                    const colorId = idx;
                                                    cols.push(
                                                        <Col
                                                            key={
                                                                'color_pallet_col_' +
                                                                colorId
                                                            }
                                                            sm={2}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    {
                                                                        color_checked:
                                                                            this.checkColor(
                                                                                colorId
                                                                            ),
                                                                    }
                                                                )}
                                                                style={{
                                                                    background:
                                                                        SCHEDULE_COLORS[
                                                                            colorId
                                                                        ].hex,
                                                                }}
                                                                onClick={() =>
                                                                    this.setColor(
                                                                        colorId
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    );
                                                    idx++;
                                                }
                                                rows.push(
                                                    <Row
                                                        key={
                                                            'color_pallet_row_' +
                                                            row
                                                        }
                                                    >
                                                        {cols}
                                                    </Row>
                                                );
                                            }

                                            return rows;
                                        })()}
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <hr></hr>
                    {scheduleInfo.repeat && (
                        <div className="schedule_setting_repeat_area">
                            <RepeatSettings
                                ref={this.RepeatSettings}
                                scheduleInfo={scheduleInfo}
                                setScheduleInfo={this.setScheduleInfo}
                                invalid={invalid.repeat}
                                repeatEndInvalid={invalid.repeatEndDate}
                                repeat={{
                                    repeatType: scheduleInfo.repeatType,
                                    repeatOption1: scheduleInfo.repeatOption1,
                                    repeatOption2: scheduleInfo.repeatOption2,
                                    repeatOption3: scheduleInfo.repeatOption3,
                                    repeatCustom: scheduleInfo.repeatCustom,
                                }}
                            />
                        </div>
                    )}
                    <div className="setting_btns">
                        <Button color="success" outline onClick={close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.beforeSave}>
                            {DISP_RESOURCE.SETTING}
                        </Button>
                        <Recyclebin
                            className="recycle_bin"
                            onClick={this.beforeDelete}
                        ></Recyclebin>
                    </div>
                </div>
                <ModalChoice
                    ref={this.DeleteGroupChoiceModal}
                    title={DISP_RESOURCE.CHOICE_DELETE_GROUP_TITLE}
                    content={DISP_RESOURCE.CHOICE_DELETE_GROUP_CONTENT}
                    firstOpt={DISP_RESOURCE.CHOICE_DELETE_GROUP_FIRST_OPT}
                    secondOpt={DISP_RESOURCE.CHOICE_DELETE_GROUP_SECOND_OPT.format(
                        this.getSpeakerName()
                    )}
                    className={'modal_scheudle_choice'}
                    okProc={this.choiceDeleteGroup}
                />
                <ModalChoice
                    ref={this.DeleteRepeatChoiceModal}
                    title={DISP_RESOURCE.CHOICE_DELETE_REPEAT_TITLE}
                    content={DISP_RESOURCE.CHOICE_DELETE_REPEAT_CONTENT}
                    firstOpt={DISP_RESOURCE.CHOICE_DELETE_REPEAT_FIRST_OPT}
                    secondOpt={DISP_RESOURCE.CHOICE_DELETE_REPEAT_SECOND_OPT}
                    className={'modal_scheudle_choice'}
                    okProc={this.choiceDeleteRepeat}
                />
                <ModalChoice
                    ref={this.UpdateGroupChoiceModal}
                    title={DISP_RESOURCE.CHOICE_UPDATE_GROUP_TITLE}
                    content={DISP_RESOURCE.CHOICE_UPDATE_GROUP_CONTENT}
                    firstOpt={DISP_RESOURCE.CHOICE_UPDATE_GROUP_FIRST_OPT}
                    secondOpt={DISP_RESOURCE.CHOICE_UPDATE_GROUP_SECOND_OPT.format(
                        this.getSpeakerName()
                    )}
                    className={'modal_scheudle_choice'}
                    okProc={this.choiceUpdateGroup}
                />
                <ModalChoice
                    ref={this.UpdateRepeatChoiceModal}
                    title={DISP_RESOURCE.CHOICE_UPDATE_REPEAT_TITLE}
                    content={DISP_RESOURCE.CHOICE_UPDATE_REPEAT_CONTENT}
                    firstOpt={DISP_RESOURCE.CHOICE_UPDATE_REPEAT_FIRST_OPT}
                    secondOpt={DISP_RESOURCE.CHOICE_UPDATE_REPEAT_SECOND_OPT}
                    className={'modal_scheudle_choice'}
                    okProc={this.choiceUpdateRepeat}
                />
                <ModalScheduleDuplicate
                    ref={this.DuplicateTypeChoiceModal}
                    speakers={speakers}
                    schedules={schedules}
                    okProc={this.choiceDuplicateType}
                />
            </div>
        );
    }

    /**
     * スピーカー名取得
     * @returns
     */
    getSpeakerName = () => {
        const { speakerId } = this.state.scheduleInfo;
        const { speakers } = this.props;

        if (
            speakerId !== null &&
            !speakerId.startsWith(SCHEDULE_GROUP_SPEAKER_ID_PREFIX)
        ) {
            const speaker = speakers.find(
                (speaker) => speaker.id === Number(speakerId)
            );
            if (typeof speaker === 'undefined') return '';
            return speaker.name;
        }
        return '';
    };
    /**
     * スケジュール設定画面 スピーカー名表示
     */
    SpeakerNames = () => {
        const { speakers, groups, targets } = this.props;
        return (
            <>
                {(() => {
                    let nonGroupNames = '';
                    const speakerNames = {};
                    for (let i = 0; i < targets.length; i++) {
                        const speaker = speakers.find(
                            (speaker) => speaker.id === targets[i]
                        );

                        if (speaker.groupId === null) {
                            nonGroupNames += speaker.name + ' ';
                            continue;
                        }

                        const group = groups.find(
                            (group) => group.id === speaker.groupId
                        );

                        if (group === undefined) {
                            nonGroupNames += speaker.name + ' ';
                            continue;
                        } else if (speakerNames[group.name] === undefined) {
                            speakerNames[group.name] = [];
                        }

                        speakerNames[group.name].push(speaker.name);
                    }

                    let idx = 0;
                    const item = [];
                    for (let group of Object.keys(speakerNames)) {
                        let speakers = '';
                        for (let i = 0; i < speakerNames[group].length; i++) {
                            speakers += speakerNames[group][i] + ' ';
                        }
                        item.push(
                            <div
                                key={'schedule_target_' + idx}
                                title={speakers}
                            >
                                <b>{group}</b> {speakers}
                                <br />
                            </div>
                        );
                        idx++;
                    }

                    if (nonGroupNames !== '') {
                        item.push(
                            <div
                                key={'schedule_target_' + idx}
                                title={nonGroupNames}
                            >
                                {nonGroupNames}
                                <br />
                            </div>
                        );
                    }
                    return <span className="setting_speaker_name">{item}</span>;
                })()}
            </>
        );
    };
}
