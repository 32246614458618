import axios from 'axios';
import {
    API_DOMAIN,
    API_URL,
    REQUEST_QUERY,
    RESPONSE_STATUS,
} from './apiContants';
import { createHeaders } from '../common/utils.js';

/**
 * ログ取得API
 * */
export const getUserLogs = async (params) => {
    const reqQuery = REQUEST_QUERY.GET_LOG.format(
        params.baseId,
        params.startDate,
        params.endDate,
        params.sortKey,
        params.sortType
    );
    // API呼び出し
    const response = await axios.get(
        API_DOMAIN + API_URL.GET_USER_LOGS + reqQuery
    );

    if (response.status === RESPONSE_STATUS.OK) {
        // 成功
        return response.data;
    } else {
        // 失敗
        return {};
    }
};

/**
 * オフィス利用者の操作ログを登録する
 * @param {object} info 登録するログ情報
 */
export async function postCreateUserLog(info) {
    // API呼び出し
    await axios.post(API_DOMAIN + API_URL.CREATE_USER_LOG, info, {
        headers: createHeaders(),
    });
}

/**
 * オフィス管理者の操作ログを登録する
 * @param {object} info 登録するログ情報
 */
export async function postCreateManagerLog(info) {
    // API呼び出し
    await axios.post(API_DOMAIN + API_URL.CREATE_MANAGER_LOG, info);
}
