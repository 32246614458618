import React from 'react';
import { Table } from 'reactstrap';
import {
    EXTERNAL_FILE_CONTENTS,
    SPEAKER_LIST_STATUS,
    SPEAKER_STATUS,
} from '../../../common/constants';
import * as Utils from '../../../common/utils';
import DISP_RESOURCE from '../../../common/dispResource';

// スピーカーリスト画面
export default class SpeakerList extends React.Component {
    /**
     * @constructor
     * @param {object} props                            - コンポーネント呼び出し側から渡される値
     * @param {object[]} props.speakers                 - スピーカー
     * @param {object[]} props.groups                   - グループ
     * @param {boolean} props.speakerListFlag           - スピーカーリスト表示有無
     * @param {function} props.toggleSpeakerListFlag    - スピーカーリスト非表示処理
     * @param {function} incrementSpeakerVolume         - スピーカー音量増加処理
     * @param {function} decrementSpeakerVolume         - スピーカー音量減少処理
     */
    constructor(props) {
        super(props);

        // スケジュールエリア高さは設定ファイルから参照
        const maxHeight = `calc((100vh - ${EXTERNAL_FILE_CONTENTS.SCHEDULER_DEFAULT_HEIGHT}) - 120px)`;

        this.state = {
            maxHeight,
            sortDescGroup: false,
            sortDescSpeaker: false,
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.props.resize(
                document.getElementsByClassName('scheduler_div_parent')[0]
                    .firstChild.style.height
            );
        }, 500);
    };

    setMaxHeight = (height) => {
        this.setState({ maxHeight: height });
    };

    sortedSpeakers = () => {
        const { speakers, groups } = this.props;
        const { sortDescGroup, sortDescSpeaker } = this.state;

        const array = [];

        for (let speaker of speakers) {
            const { id, name, status, volume, isMute, isFailure } = speaker;

            const groupName = Utils.isEmpty(speaker.groupId)
                ? ''
                : groups.find((group) => group.id === speaker.groupId).name;
            array.push({
                id,
                groupName,
                name,
                status,
                volume,
                isMute,
                isFailure,
            });
        }

        array.sort((a, b) => {
            // グループ名を優先ソート
            if (a.groupName !== b.groupName) {
                if (sortDescGroup) {
                    // グループ名降順
                    if (b.groupName > a.groupName) return 1;
                    if (b.groupName < a.groupName) return -1;
                } else {
                    // グループ名昇順
                    if (a.groupName > b.groupName) return 1;
                    if (a.groupName < b.groupName) return -1;
                }
            }
            if (sortDescSpeaker) {
                // スピーカー名降順
                if (b.name > a.name) return 1;
                if (b.name < a.name) return -1;
            } else {
                // スピーカー名昇順
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
            }
            return 0;
        });

        return array;
    };

    render() {
        const {
            toggleSpeakerListFlag,
            incrementSpeakerVolume,
            decrementSpeakerVolume,
        } = this.props;
        const { sortDescGroup, sortDescSpeaker } = this.state;

        const groupSortArrow = sortDescGroup
            ? require('../../../img/down_arrow_d.svg').default
            : require('../../../img/up_arrow.svg').default;
        const speakerSortArrow = sortDescSpeaker
            ? require('../../../img/down_arrow_d.svg').default
            : require('../../../img/up_arrow.svg').default;
        return (
            <div
                className="speaker_list"
                style={{ maxHeight: this.state.maxHeight }}
            >
                <div className="speaker_list_top">
                    {DISP_RESOURCE.SPEAKER_LIST}
                    <div onClick={() => toggleSpeakerListFlag(false)}>
                        <img
                            alt="close"
                            src={
                                require('../../../img/close_black.svg').default
                            }
                            width={15}
                        ></img>
                    </div>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>{DISP_RESOURCE.HASH}</th>
                            <th>
                                {DISP_RESOURCE.GROUP_NAME}
                                <img
                                    alt="sort_arrow"
                                    src={groupSortArrow}
                                    onClick={() =>
                                        this.setState({
                                            sortDescGroup:
                                                !this.state.sortDescGroup,
                                        })
                                    }
                                />
                            </th>
                            <th>
                                {DISP_RESOURCE.SPEAKER_NAME}
                                <img
                                    alt="sort_arrow"
                                    src={speakerSortArrow}
                                    onClick={() =>
                                        this.setState({
                                            sortDescSpeaker:
                                                !this.state.sortDescSpeaker,
                                        })
                                    }
                                />
                            </th>
                            <th>{DISP_RESOURCE.STATUS}</th>
                            <th>{DISP_RESOURCE.VOLUME_DECIBEL}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.sortedSpeakers().map((speaker, idx) => {
                            const {
                                id,
                                groupName,
                                name,
                                status,
                                volume,
                                isMute,
                                isFailure,
                            } = speaker;
                            let state = SPEAKER_LIST_STATUS.STOP;

                            if (isFailure) state = SPEAKER_LIST_STATUS.FAIL;
                            else if (isMute) state = SPEAKER_LIST_STATUS.MUTE;
                            else if (status === SPEAKER_STATUS.START)
                                state = SPEAKER_LIST_STATUS.PLAY;

                            return (
                                <tr key={'spk_list_' + idx}>
                                    <th scope="row">{idx}</th>
                                    <td>
                                        {Utils.isEmpty(groupName)
                                            ? ''
                                            : groupName}
                                    </td>
                                    <td>{name}</td>
                                    <td>{state}</td>
                                    <td>
                                        <div className="speaker_list_btns">
                                            <div 
                                                className="volume_down"
                                                alt="volume_down"
                                                onClick={() =>
                                                    decrementSpeakerVolume(
                                                        id,
                                                        volume
                                                    )
                                                }
                                                >
                                            </div>
                                            <span>{volume}</span>
                                            <div 
                                                className="volume_up"
                                                alt="volume_up"
                                                onClick={() =>
                                                    incrementSpeakerVolume(
                                                        id,
                                                        volume
                                                    )
                                                }
                                                >
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
}
