import classNames from 'classnames';
import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter,
    Label,
    FormGroup,
} from 'reactstrap';
import { CHOICE_MODAL_OPT } from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';

/**
 * 操作選択モーダル
 * モーダル内にラジオボタンを用意し、ユーザーに選択を促す
 */
class ModalChoice extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {string} props.title - モーダルタイトル(必須でない)
     * @param {string} props.content - メッセージ
     * @param {function} props.okProc - OKボタン押下時の処理
     * @param {string} props.firstOpt - ラジオボタン1つ目用の文字列
     * @param {string} props.secondOpt - ラジオボタン2つ目用の文字列
     * @param {string} props.className - ダイアログカスタムclass
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} フロア名設定モーダルの表示有無
             */
            modal: false,

            /**
             * @type {number} 選択中のラジオボタン
             */
            choice: 0,
        };

        this.parser = require('html-react-parser');
    }

    /**
     * フロア名設定モーダル表示有無の設定
     * @param {boolean} flag -フロア名設定モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * 選択したラジオボタンを設定
     * @param {number} choice - 選択したラジオボタン
     */
    setChoice = (choice) => {
        this.setState({ choice: choice });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        // 1つ目のラジオボタンを選択
        this.setChoice(CHOICE_MODAL_OPT.FIRST);
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * OKボタン押下時処理
     */
    onClickOkHandler = () => {
        // 選択したラジオボタンの文字列を利用して親コンポーネントの処理を行う
        this.props.okProc(this.state.choice);

        // モーダル閉じる
        this.setModal(false);
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        const {
            title,
            content,
            firstOpt,
            secondOpt,
            className = null,
        } = this.props;
        return (
            <>
                <Modal
                    className={classNames(
                        'modal_commons',
                        className !== null ? className : ''
                    )}
                    isOpen={this.state.modal}
                    centered
                    backdrop="static"
                >
                    {/* モーダルヘッダー タイトル表示欄 */}
                    <ModalHeader toggle={this.close}>{title}</ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Label for="modal_commons_content">
                            {content !== undefined ? this.parser(content) : ''}
                        </Label>
                        <FormGroup className="mt-4 mx-3" tag="fieldset">
                            {/* ラジオボタン */}
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.FIRST}
                                        onClick={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                        defaultChecked
                                    />
                                    {firstOpt}
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                    <Input
                                        type="radio"
                                        name="radio1"
                                        value={CHOICE_MODAL_OPT.SECOND}
                                        onClick={(e) => {
                                            this.setChoice(e.target.value);
                                        }}
                                    />
                                    {secondOpt}
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="with_cancel">
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.onClickOkHandler}>
                            {DISP_RESOURCE.OK}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ModalChoice;
