import React from 'react';
import update from 'immutability-helper';
import {
    Col,
    FormFeedback,
    Input,
    Label,
    ListGroup,
    ListGroupItem,
    Row,
} from 'reactstrap';
import moment from 'moment';
import {
    CALENDAR_LOCALE,
    REPEAT_TYPE,
    WEEKS_COUNT as weekcount,
    PERIOD_ERR_TYPE,
} from '../../../common/constants';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import DISP_RESOURCE from '../../../common/dispResource';

// 曜日リスト（日月火水木金土）
const weekdays = moment.weekdaysShort();

/**
 * 繰返し設定領域
 * @param {object}   props                  - コンポーネント呼び出し側から渡される値
 * @param {object}   props.scheduleInfo     - スケジュール情報
 * @param {object}   props.repeat           - スケジュール繰返し設定値
 * @param {boolean}  props.invalid          - 入力チェック確認フラグ
 * @param {function} props.setScheduleInfo  - セット値更新メソッド
 */
export default class RepeatSettings extends React.Component {
    /**
     * 繰返し周期タイプ更新・オプション初期化
     * @param {Event} e イベントオブジェクト
     */
    changeType = (e) => {
        const value = e.target.value;
        let repeatOption1 = '';

        switch (value) {
            case REPEAT_TYPE.EVERY_DAY:
                repeatOption1 = '5';
                break;
            case REPEAT_TYPE.PICK_DATE:
            case REPEAT_TYPE.PICK_DAY:
            case REPEAT_TYPE.EVERY_YEAR:
                repeatOption1 = '1';
                break;
            default:
                break;
        }

        const newState = {
            repeatType: value,
            repeatOption1,
            repeatOption2: [],
            repeatOption3: [],
            repeatCustom: [],
        };
        this.props.setScheduleInfo({ ...newState });
    };

    /**
     * 繰返しオプション１変更（テキスト入力 or プルダウンセレクト）
     * @param {string} value
     */
    changeOption1 = (value) => {
        this.props.setScheduleInfo({ repeatOption1: value });
    };

    /**
     * 繰返しオプション2チェックボックスのチェック済み確認
     * @param {string} value
     * @returns {boolean}
     */
    checkedOption2 = (value) => {
        return this.props.repeat.repeatOption2.includes(value);
    };

    /**
     * 繰返しオプション2 チェック対象の更新
     * @param {string} value
     */
    changeOption2 = (value) => {
        const { repeat, setScheduleInfo } = this.props;
        let checked = this.checkedOption2(value);

        if (checked) {
            // 既にチェック済みならチェック解除
            const idx = repeat.repeatOption2.findIndex((val) => val === value);
            setScheduleInfo({
                repeatOption2: update(repeat.repeatOption2, {
                    $splice: [[idx, 1]],
                }),
            });
        } else {
            // 未チェックならチェックリストにプッシュ
            setScheduleInfo({
                repeatOption2: update(repeat.repeatOption2, { $push: [value] }),
            });
        }
    };

    /**
     * 繰返しオプション3チェックボックスのチェック済み確認
     * @param {string} value
     * @returns {boolean}
     */
    checkedOption3 = (value) => {
        return this.props.repeat.repeatOption3.includes(value);
    };

    /**
     * 繰返しオプション2 チェック対象の更新
     * @param {string} value
     */
    changeOption3 = (value) => {
        const { repeat, setScheduleInfo } = this.props;
        let checked = this.checkedOption3(value);

        if (checked) {
            const idx = repeat.repeatOption3.findIndex((val) => val === value);
            setScheduleInfo({
                repeatOption3: update(repeat.repeatOption3, {
                    $splice: [[idx, 1]],
                }),
            });
        } else {
            setScheduleInfo({
                repeatOption3: update(repeat.repeatOption3, { $push: [value] }),
            });
        }
    };

    /**
     * 画面描画
     */
    render() {
        const { repeatType } = this.props.repeat;

        return (
            <>
                <Row>
                    <Label sm={2}>{DISP_RESOURCE.REPEAT_CYCLE}</Label>
                    <Col sm={3}>
                        <Input
                            type="select"
                            value={repeatType}
                            onChange={this.changeType}
                        >
                            <option value={REPEAT_TYPE.EVERY_DAY}>
                                {DISP_RESOURCE.EVERY_DAY}
                            </option>
                            <option value={REPEAT_TYPE.EVERY_WEEK}>
                                {DISP_RESOURCE.EVERY_WEEK}
                            </option>
                            <option value={REPEAT_TYPE.PICK_DATE}>
                                {DISP_RESOURCE.PICK_DATE}
                            </option>
                            <option value={REPEAT_TYPE.PICK_DAY}>
                                {DISP_RESOURCE.PICK_DAY}
                            </option>
                            <option value={REPEAT_TYPE.EVERY_YEAR}>
                                {DISP_RESOURCE.EVERY_YEAR}
                            </option>
                            <option value={REPEAT_TYPE.CUSTOM}>
                                {DISP_RESOURCE.CUSTOM}
                            </option>
                        </Input>
                    </Col>
                    {repeatType === REPEAT_TYPE.EVERY_WEEK && (
                        <this.EveryWeek />
                    )}
                    {(repeatType === REPEAT_TYPE.PICK_DATE ||
                        repeatType === REPEAT_TYPE.PICK_DAY) && (
                        <this.EveryMonth />
                    )}
                    {repeatType === REPEAT_TYPE.EVERY_YEAR && (
                        <this.EveryYear />
                    )}
                    {repeatType === REPEAT_TYPE.CUSTOM && <this.Custom />}
                </Row>
                {repeatType === REPEAT_TYPE.EVERY_DAY && <this.EveryDay />}
                {repeatType === REPEAT_TYPE.EVERY_WEEK && (
                    <this.EveryWeekPickDay />
                )}
                {repeatType === REPEAT_TYPE.PICK_DATE && (
                    <this.EveryMonthPickDate />
                )}
                {repeatType === REPEAT_TYPE.PICK_DAY && (
                    <this.EveryMonthPickDay />
                )}
            </>
        );
    }

    /**
     * 繰返し周期（毎日）の表示(repeatOption1)
     */
    EveryDay = () => {
        const { repeat } = this.props;

        return (
            <Row>
                <Label sm={2}>{DISP_RESOURCE.REPEAT_DAY_COUNT}</Label>
                <Col sm={1}>
                    <Input
                        type="text"
                        value={repeat.repeatOption1}
                        maxLength={3}
                        onChange={(e) =>
                            this.changeOption1(
                                e.target.value.replace(/[^0-9]/g, '')
                            )
                        }
                        onBlur={(e) =>
                            e.target.value === '' ||
                            parseInt(e.target.value) === 0
                                ? this.changeOption1(5)
                                : null
                        }
                    />
                </Col>
                <Col className="pt-1" sm={1}>
                    {DISP_RESOURCE.FOR_DAYS}
                </Col>
            </Row>
        );
    };

    /**
     * 繰返し周期（毎週）の表示
     */
    EveryWeek = () => {
        const { repeatEndInvalid, setScheduleInfo, scheduleInfo } = this.props;

        return (
            <>
                <Label sm={1}>{DISP_RESOURCE.REPEAT_END_DATE}</Label>
                <Col sm={2}>
                    <Input
                        id="period"
                        type="date"
                        className={classNames(
                            repeatEndInvalid !== null
                                ? 'invalid_period is-invalid'
                                : ''
                        )}
                        value={scheduleInfo.repeatEndDate}
                        onChange={(e) =>
                            setScheduleInfo({
                                repeatEndDate: e.target.value,
                            })
                        }
                    ></Input>
                    <FormFeedback>
                        {(() => {
                            switch (repeatEndInvalid) {
                                case PERIOD_ERR_TYPE.NOT_ONE_DAY:
                                    return DISP_RESOURCE.PERIOD_ERROR_NOT_ONE_DAY;
                                case PERIOD_ERR_TYPE.PERIOD_EMPTY:
                                    return DISP_RESOURCE.INPUT_REQUIRED;
                                case PERIOD_ERR_TYPE.AFTER_START:
                                    return DISP_RESOURCE.PERIOD_ERROR_START_LATE_THEN_END;
                                default:
                                    return '';
                            }
                        })()}
                    </FormFeedback>
                </Col>
            </>
        );
    };

    /**
     * 繰返し周期（毎週）の表示(repeatOption2)
     */
    EveryWeekPickDay = () => {
        const { invalid } = this.props;
        const days = [];
        const rows = [];
        
        for (let i in weekdays) {
            const dayNum = Number.parseInt(i);
            days.push(
                <Col key={'weekday_' + dayNum} className="scheduleCheckBox">
                    <Label for={'weekday_' + dayNum}>{weekdays[dayNum]}</Label>
                    <Input
                        id={'weekday_' + dayNum}
                        type="checkbox"
                        invalid={invalid}
                        checked={this.checkedOption2(dayNum)}
                        onChange={() => this.changeOption2(dayNum)}
                    />
                </Col>
            );
        }

        rows.push(<Row>{days}</Row>);
        return (
            <>
                <div
                    className={classNames(
                        'repeat_pick_day',
                        invalid ? 'is-invalid' : ''
                    )}
                >
                    {rows}
                </div>
                <FormFeedback className='repeat_options_err'>{DISP_RESOURCE.SELECT_REQUIRED}</FormFeedback>
            </>
        );
    };

    /**
     * 繰返し周期（週月）の共通表示(repeatOption1)
     */
    EveryMonth = () => {
        const { repeat, repeatEndInvalid, setScheduleInfo, scheduleInfo } = this.props;
        return (
            <>
                <Label sm={1}>{DISP_RESOURCE.REPEAT_END_DATE}</Label>
                <Col sm={2}>
                    <Input
                        id="period"
                        type="date"
                        className={classNames(
                            repeatEndInvalid !== null
                                ? 'invalid_period is-invalid'
                                : ''
                        )}
                        value={scheduleInfo.repeatEndDate}
                        onChange={(e) =>
                            setScheduleInfo({
                                repeatEndDate: e.target.value,
                            })
                        }
                    ></Input>
                    <FormFeedback>
                        {(() => {
                            switch (repeatEndInvalid) {
                                case PERIOD_ERR_TYPE.NOT_ONE_DAY:
                                    return DISP_RESOURCE.PERIOD_ERROR_NOT_ONE_DAY;
                                case PERIOD_ERR_TYPE.PERIOD_EMPTY:
                                    return DISP_RESOURCE.INPUT_REQUIRED;
                                case PERIOD_ERR_TYPE.AFTER_START:
                                    return DISP_RESOURCE.PERIOD_ERROR_START_LATE_THEN_END;
                                default:
                                    return '';
                            }
                        })()}
                    </FormFeedback>
                </Col>
                <Col sm={1}>
                    <Input
                        type="select"
                        value={repeat.repeatOption1}
                        onChange={(e) =>
                            this.changeOption1(String(e.target.value))
                        }
                    >
                        {(() => {
                            const options = [];
                            for (let i = 1; i <= 12; i++) {
                                options.push(
                                    <option
                                        key={'month_' + i}
                                        value={String(i)}
                                    >
                                        {i}
                                    </option>
                                );
                            }
                            return options;
                        })()}
                    </Input>
                </Col>
                <Col className="pt-1" sm={1}>
                    {DISP_RESOURCE.FOR_MONTHS}
                </Col>
            </>
        );
    };

    /**
     * 繰返し周期（週月(日付指定)）の表示(repeatOption2)
     */
    EveryMonthPickDate = () => {
        const { invalid } = this.props;
        const rows = [];

        for (let i = 0; i <= 2; i++) {
            const dates = [];
            for (let j = 1; j <= 10; j++) {
                const day = j + i * 10;
                dates.push(
                    <Col key={'day_' + day} className="scheduleCheckBox">
                        <Label for={'day_' + day}>{day}</Label>
                        <Input
                            id={'day_' + day}
                            type="checkbox"
                            invalid={this.props.invalid}
                            checked={this.checkedOption2(day)}
                            onChange={() => this.changeOption2(day)}
                        />
                    </Col>
                );
            }
            if (i === 2) {
                const lastDay = 31;
                dates.push(
                    <Col key={'day_' + lastDay} className="scheduleCheckBox">
                        <Label for={'day_' + lastDay}>{lastDay}</Label>
                        <Input
                            id={'day_' + lastDay}
                            type="checkbox"
                            invalid={this.props.invalid}
                            checked={this.checkedOption2(lastDay)}
                            onChange={() => this.changeOption2(lastDay)}
                        />
                    </Col>
                );
            } /**<Col sm={5}/> */
            rows.push(<Row key={i}>{dates}</Row>);
        }

        return (
            <>
                <div
                    className={classNames(
                        'repeat_pick_date',
                        invalid ? 'is-invalid' : ''
                    )}
                >
                    {rows}
                </div>
                <FormFeedback className="repeat_options_err">
                    {DISP_RESOURCE.SELECT_REQUIRED}
                </FormFeedback>
            </>
        );
    };

    /**
     * 繰返し周期（週月(曜日指定)）の表示(repeatOption2, repeatOption3)
     */
    EveryMonthPickDay = () => {
        const { invalid } = this.props;
        const rows = [];
        const weeks = [];
        for (let i in weekcount) {
            const weekNum = Number(i) + 1;
            weeks.push(
                <Col key={'week_' + weekNum} className="scheduleWeekCheckBox">
                    <Label for={'week_' + weekNum}>{weekcount[i]}</Label>
                    <Input
                        id={'week_' + weekNum}
                        type="checkbox"
                        invalid={this.props.invalid}
                        checked={this.checkedOption2(weekNum)}
                        onChange={() => this.changeOption2(weekNum)}
                    />
                </Col>
            );
        }
        rows.push(<Row key="row_1">{weeks}</Row>);

        const days = [];
        for (let i in weekdays) {
            const dayNum = Number(i);
            days.push(
                <Col key={'day_' + dayNum} className="scheduleCheckBox">
                    <Label for={'day_' + dayNum}>{weekdays[i]}</Label>
                    <Input
                        id={'day_' + dayNum}
                        type="checkbox"
                        invalid={this.props.invalid}
                        checked={this.checkedOption3(dayNum)}
                        onChange={() => this.changeOption3(dayNum)}
                    />
                </Col>
            );
        }

        rows.push(<Row key="row_2">{days}</Row>);
        return (
            <>
                <div
                    className={classNames(
                        'repeat_pick_day',
                        invalid ? 'is-invalid' : ''
                    )}
                >
                    {rows}
                </div>
                <FormFeedback className="repeat_options_err">
                    {DISP_RESOURCE.SELECT_REQUIRED}
                </FormFeedback>
            </>
        );
    };

    /**
     * 繰返し周期（周年）の表示(repeatOption1)
     */
    EveryYear = () => {
        const { repeat, repeatEndInvalid, setScheduleInfo, scheduleInfo } = this.props;
        return (
            <>
                <Label sm={1}>{DISP_RESOURCE.REPEAT_END_DATE}</Label>
                <Col sm={2}>
                    <Input
                        id="period"
                        type="date"
                        className={classNames(
                            repeatEndInvalid !== null
                                ? 'invalid_period is-invalid'
                                : ''
                        )}
                        value={scheduleInfo.repeatEndDate}
                        onChange={(e) =>
                            setScheduleInfo({
                                repeatEndDate: e.target.value,
                            })
                        }
                    ></Input>
                    <FormFeedback>
                        {(() => {
                            switch (repeatEndInvalid) {
                                case PERIOD_ERR_TYPE.NOT_ONE_DAY:
                                    return DISP_RESOURCE.PERIOD_ERROR_NOT_ONE_DAY;
                                case PERIOD_ERR_TYPE.PERIOD_EMPTY:
                                    return DISP_RESOURCE.INPUT_REQUIRED;
                                case PERIOD_ERR_TYPE.AFTER_START:
                                    return DISP_RESOURCE.PERIOD_ERROR_START_LATE_THEN_END;
                                default:
                                    return '';
                            }
                        })()}
                    </FormFeedback>
                </Col>
                <Col sm={1}>
                    <Input
                        type="select"
                        value={repeat.repeatOption1}
                        onChange={(e) => this.changeOption1(e.target.value)}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Input>
                </Col>
                <Col className="pt-1" sm={1}>
                    {DISP_RESOURCE.FOR_YEARS}
                </Col>
            </>
        );
    };

    /**
     * 繰返し周期（カスタム）の表示(repeatCustom)
     */
    Custom = () => {
        const { repeat, invalid, setScheduleInfo } = this.props;
        /**
         * カスタム日付リストから特定対処のインデックス取得
         * @param {string} date 日付(YYYY-MM-DD)
         * @returns   インデックス
         */
        const getListIdx = (date) => {
            const datestr = moment(date).format('YYYY-MM-DD');
            return repeat.repeatCustom.findIndex((d) => d === datestr);
        };

        /**
         * カスタム日付リストへ追加
         * @param {string} date 日付(YYYY-MM-DD)
         */
        const pickDate = (date) => {
            if (getListIdx(date) >= 0) return;

            setScheduleInfo({
                repeatCustom: update(repeat.repeatCustom, {
                    $push: [moment(date).format('YYYY-MM-DD')],
                }),
            });
        };

        /**
         * カスタム日付リストから削除
         * @param {string} date 日付(YYYY-MM-DD)
         */
        const deleteDate = (date) => {
            const idx = getListIdx(date);
            setScheduleInfo({
                repeatCustom: update(repeat.repeatCustom, {
                    $splice: [[idx, 1]],
                }),
            });
        };

        let sortedList = repeat.repeatCustom.sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });

        return (
            <>
                <Col sm={4}>
                    <Calendar
                        calendarType="US"
                        locale={CALENDAR_LOCALE.JP}
                        minDetail="decade"
                        minDate={new Date()}
                        maxDate={moment()
                            .add(30, 'year')
                            .set('month', 11)
                            .set('date', 31)
                            .toDate()}
                        formatDay={(locale, date) => moment(date).format('D')}
                        onChange={pickDate}
                    />
                </Col>
                <Col sm={3}>
                    <div
                        className={classNames(
                            'custom_date_list',
                            invalid ? 'is-invalid' : ''
                        )}
                    >
                        <ListGroup>
                            {sortedList.map((date) => {
                                return (
                                    <ListGroupItem key={'custom_' + date}>
                                        <span>
                                            {moment(date).format('YYYY/MM/DD')}
                                        </span>
                                        <span
                                            className="custom_date_delete"
                                            onClick={() => deleteDate(date)}
                                        >
                                            {DISP_RESOURCE.DELETE}
                                        </span>
                                    </ListGroupItem>
                                );
                            })}
                        </ListGroup>
                    </div>
                    <FormFeedback>{DISP_RESOURCE.SELECT_REQUIRED}</FormFeedback>
                </Col>
            </>
        );
    };
}
