import React from 'react';
import moment from 'moment';
import { Row, Col, Label, Card } from 'reactstrap';
import {
    SESSION_STORAGE_KEY,
    VOLUME_SLIDER,
    MODAL,
    FLOOR_MAP_SIZE_BASIS,
    CREATE_LOG_IDENTIFIER,
} from '../../common/constants';
import { RESPONSE_STATUS } from '../../api/apiContants';
import DISP_RESOURCE from '../../common/dispResource';
import { postCreateUserLog } from '../../api/logApi';
import pin from '../../img/pin.svg';
import minSpeaker from '../../img/speaker_zero.svg';
import maxSpeaker from '../../img/speaker_normal.svg';
import { ReactComponent as PlayIcon } from '../../img/play.svg';
import stop from '../../img/stop.svg';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { getSpeakerInfo } from '../../api/userApi';

/**
 * スピーカー操作コンテンツ
 * @param {object} props - コンポーネント呼び出し側から渡される値
 */
class SpeakerOperation extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * ユーザーアクセス日付
             * @type {string}
             */
            userAccessDate: '',
            /**
             * スピーカー名
             * @type {string}
             */
            speakerName: '',
            /**
             * エリアマップ
             * @type {string}
             */
            areaImage: '',
            /**
             * icon表示位置（横）
             * @type {number}
             */
            left: 0,
            /**
             * icon表示位置（縦）
             * @type {number}
             */
            top: 0,
            /**
             * 音量
             * @type {number}
             */
            volume: 0,
            /**
             * 音源
             * @type {string}
             */
            soundSourceName: '',
            /**
             * マップ画像のスケール(拡大率)
             * @type {number}
             */
            mapScale: 1,
            /**
             * ピン画像の表示フラグ
             * @type {boolean}
             */
            displayPinIcon: false,
        };
    }

    componentDidMount() {
        window.history.pushState(null, '', window.location.href);
        const now = moment();
        if (!sessionStorage.getItem(SESSION_STORAGE_KEY.USER_ACCESS_DATE)) {
            this.setState({ userAccessDate: now.format('YYYY/MM/DD') });
            sessionStorage.setItem(
                SESSION_STORAGE_KEY.USER_ACCESS_DATE,
                now.format('YYYY/MM/DD')
            );
        } else {
            this.setState({
                userAccessDate: sessionStorage.getItem(
                    SESSION_STORAGE_KEY.USER_ACCESS_DATE
                ),
            });
        }
        this.setSpeakerInfo();
    }

    /**
     * 画面更新後に発動
     * @param {*} prevProps - 画面更新前のprops
     * @param {*} prevState - 画面更新前のstate
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.state.map && this.state.map !== prevState.map) {
            setTimeout(() => {
                const left =
                    (this.state.left / FLOOR_MAP_SIZE_BASIS.WIDTH -
                        FLOOR_MAP_SIZE_BASIS.PIN_SIZE /
                            2 /
                            document.getElementById('floor_map').clientWidth) *
                    100;
                const top =
                    (this.state.top / FLOOR_MAP_SIZE_BASIS.HEIGHT -
                        FLOOR_MAP_SIZE_BASIS.PIN_SIZE /
                            2 /
                            document.getElementById('floor_map').clientHeight) *
                    100;

                this.setState({
                    left,
                    top,
                    displayPinIcon: true,
                });
            }, 500);
        }
    }

    /**
     * スピーカー情報取得
     * @param {number} soundSourceNo 停止時間
     */
    setSpeakerInfo = async () => {
        const params = this.getParams();
        let result = false;
        try {
            const response = await getSpeakerInfo(params.speakerId);
            this.setState({
                speakerName: response.speakerName,
                map: response.mapImage,
                left: response.positionX < 0 ? 0 : response.positionX,
                top: response.positionY < 0 ? 0 : response.positionY,
                volume: response.volume,
            });
            this.props.soundSource(
                response.soundSourceNo,
                response.soundSourceName
            );
            this.props.setParam(params);
            result = true;
        } catch (e) {
            // セッションエラー
            if (e.response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                this.props.modalOpen(MODAL.SESSION_EXPIRED);
            }
            // それ以外
            else {
                this.props.modalOpen(MODAL.SYSTEM_ERROR);
            }
        }
        // Webページオープンのログ登録
        const body = {
            baseId: params.baseId,
            speakerId: params.speakerId,
            identifier: CREATE_LOG_IDENTIFIER.WEB_PAGE_OPEN,
            result: result,
            data: '-',
        };
        await postCreateUserLog(body);
    };

    /**
     * QRコード読み込みURLのパラメータを取得
     */
    getParams = () => {
        const urlParamStr = window.location.search.substring(1);
        let params = {};
        urlParamStr.split('&').forEach((param) => {
            const temp = param.split('=');
            params = {
                ...params,
                [temp[0]]: temp[1],
            };
        });

        return params;
    };

    /**
     * 音量セット
     * @param {number} volume 音量
     */
    setVolume = (volume) => {
        this.setState({ volume: volume.target.value });
    };

    /**
     * フロア画像拡大率セット
     * @param {number} mapScale - 拡大率
     */
    setMapScale = (mapScale) => {
        this.setState({ mapScale });
    };

    /**
     * スピーカー再生
     */
    playSpeaker = () => {
        this.props.audioPlay(this.props.soundSourceNo);
    };

    /**
     * テスト再生中の再生アイコンの状態
     * @param {boolean} testPlay テスト再生中の状態
     */
    playSpeakerShow = (testPlay) => {
        if (testPlay) {
            return (
                <PlayIcon className="disablePlay" onClick={this.playSpeaker} />
            );
        } else {
            return <PlayIcon onClick={this.playSpeaker} />;
        }
    };

    /**
     * 音量スライダーのスタイル設定
     */
    getBackgroundSize = () => {
        let value = Number(this.state.volume);
        switch (value) {
            case -12:
                value = 0;
                break;
            case -9:
                value = 12.5;
                break;
            case -6:
                value = 25;
                break;
            case -3:
                value = 37.5;
                break;
            case 0:
                value = 50;
                break;
            case 3:
                value = 62.5;
                break;
            case 6:
                value = 75;
                break;
            case 9:
                value = 87.5;
                break;
            case 12:
                value = 100;
                break;
            default:
                break;
        }
        return (
            'linear-gradient(to right, #5fc7bc 0%, #5fc7bc ' +
            value +
            '%, gray ' +
            value +
            '%, gray 100%)'
        );
    };

    /**
     * フロア画像の拡大縮小操作時に発動するイベント
     * @param {Event} event
     */
    handleScaleChange = (event) => {
        this.setMapScale(event.instance.transformState.scale);
    };

    /**
     * 画面描画
     * @returns
     */
    render() {
        return (
            <div className="mx-3">
                <Row>
                    <Col className="text-start user_info_text">
                        <Label>{this.state.userAccessDate}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-start user_info_text">
                        <Label
                            title={
                                DISP_RESOURCE.SPEAKER +
                                DISP_RESOURCE.FULL_WIDTH_COLON +
                                this.state.speakerName
                            }
                        >
                            {DISP_RESOURCE.SPEAKER}
                            {DISP_RESOURCE.FULL_WIDTH_COLON}
                            {this.state.speakerName}
                        </Label>
                    </Col>
                </Row>
                <Card body className="user_map_area">
                    <TransformWrapper
                        initialScale={this.state.mapScale}
                        onTransformed={(e) => this.handleScaleChange(e)}
                    >
                        <TransformComponent>
                            <div className="area_image">
                                <img
                                    src={this.state.map}
                                    alt="map"
                                    id="floor_map"
                                    style={{ width: '100%', height: '100%' }}
                                />
                                <div
                                    className="pin_icon"
                                    style={{
                                        left: this.state.left + '%',
                                        top: this.state.top + '%',
                                    }}
                                >
                                    {this.state.displayPinIcon && (
                                        <img
                                            alt="pin"
                                            src={pin}
                                            style={{
                                                width:
                                                    FLOOR_MAP_SIZE_BASIS.PIN_SIZE +
                                                    'px',
                                                transform: `scale(${
                                                    1 / this.state.mapScale
                                                })`,
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </TransformComponent>
                    </TransformWrapper>
                </Card>
                <Row className="mt-4">
                    <Col xs={3} style={{ margin: 'auto' }}>
                        {DISP_RESOURCE.SOUND_SOURCE}
                    </Col>
                    <Col xs={9}>
                        <button
                            className="sound_source_btn"
                            onClick={() =>
                                this.props.modalOpen(MODAL.SELECT_SOUND_SOURCE)
                            }
                        >
                            {this.props.soundSourceName}
                        </button>
                    </Col>
                </Row>
                <Row xs={3} className="mt-5 sound_control_btn">
                    <Col>
                        <img
                            alt="stop"
                            src={stop}
                            width="60"
                            height="60"
                            onClick={() =>
                                this.props.modalOpen(MODAL.SELECT_STOP_TIME)
                            }
                        />
                    </Col>
                    <Col>{this.playSpeakerShow(this.props.testPlay)}</Col>
                </Row>
                <Row className="volume_edit mt-4">
                    <Col xs={2} className="pt-4">
                        <img
                            alt="minSpeaker"
                            src={minSpeaker}
                            style={{ height: '95%' }}
                        />
                    </Col>
                    <Col xs={8}>
                        {this.state.volume}
                        {DISP_RESOURCE.DECIBEL}
                        <input
                            type="range"
                            className="input-range"
                            min={VOLUME_SLIDER.MIN}
                            max={VOLUME_SLIDER.MAX}
                            step={VOLUME_SLIDER.STEP}
                            value={this.state.volume}
                            onChange={this.setVolume}
                            style={{ background: this.getBackgroundSize() }}
                            onTouchEnd={() =>
                                this.props.soundSetting(this.state.volume)
                            }
                        />
                    </Col>
                    <Col xs={2} className="pt-4">
                        <img
                            alt="maxSpeaker"
                            src={maxSpeaker}
                            style={{ height: '95%' }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SpeakerOperation;
