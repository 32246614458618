import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Table,
    Input,
} from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import { STOP_TIME } from '../../../common/constants';

/**
 * 停止時間選択モーダル
 */
class ModalSelectStopTime extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     * @param {function} props.okProc - OKボタン押下時の処理
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} 停止時間選択モーダル表示有無
             */
            modal: false,
            /**
             * @type {number} 選択中の停止時間
             */
            stopTime: 0,
            /**
             * @type {Array} 停止時間リスト
             */
            stopTimeList: [],
        };
    }

    componentDidMount() {
        // 停止時間リストをセット
        const stopTimeList = [];
        for (
            let i = STOP_TIME.MIN;
            i <= STOP_TIME.MAX;
            i += STOP_TIME.INTERVAL
        ) {
            const hours =
                Math.floor(i / 60) > 0
                    ? Math.floor(i / 60).toString() + DISP_RESOURCE.HOURS
                    : '';
            const minutes =
                i % 60 > 0 ? (i % 60).toString() + DISP_RESOURCE.MINUTES : '';
            stopTimeList.push({ text: hours + minutes, value: i });
        }
        this.setState({ stopTimeList: stopTimeList });
    }

    /**
     * 停止時間選択モーダル表示有無の設定
     * @param {boolean} flag -停止時間選択モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * 選択した停止時間を設定
     * @param {number} stopTime - 選択した停止時間
     */
    setStopTime = (stopTime) => {
        this.setState({ stopTime: Number(stopTime) });
    };

    /**
     * モーダル初期化
     * @param {number} stopTime -停止時間
     */
    init = (stopTime) => {
        this.setState({ stopTime: stopTime });
        this.setModal(true);
    };

    /**
     * モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * 停止ボタン押下時処理
     */
    onClickStopHandler = () => {
        this.close();
        this.props.okProc(this.state.stopTime);
    };

    /**
     * 画面描画
     * @returns モーダル画面
     */
    render() {
        return (
            <div className="div_modal_commons">
                <Modal
                    className="modal_commons modal_select_stop_time"
                    isOpen={this.state.modal}
                    centered
                >
                    {/* モーダルヘッダー タイトル表示欄*/}
                    <ModalHeader>
                        {DISP_RESOURCE.SELECT_STOP_TIME_TITLE}
                    </ModalHeader>
                    {/* モーダルボディ */}
                    <ModalBody className="px-5">
                        {/* メッセージ表示欄 */}
                        <Label for="modal_commons_content">
                            {DISP_RESOURCE.SELECT_STOP_TIME_CONTENT}
                        </Label>
                        <Table className="mt-3">
                            <tbody>
                                {this.state.stopTimeList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Label
                                                    for={
                                                        'stopTimeRadio' +
                                                        index.toString()
                                                    }
                                                >
                                                    {item.text}
                                                </Label>
                                            </td>
                                            <td>
                                                <Input
                                                    id={
                                                        'stopTimeRadio' +
                                                        index.toString()
                                                    }
                                                    type="radio"
                                                    name="stopTimeRadio"
                                                    value={item.value}
                                                    onClick={(e) => {
                                                        this.setStopTime(
                                                            e.target.value
                                                        );
                                                    }}
                                                    defaultChecked={
                                                        item.value ===
                                                        this.state.stopTime
                                                    }
                                                ></Input>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </ModalBody>
                    {/* モーダルフッター */}
                    <ModalFooter className="with_cancel">
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button
                            color="success"
                            onClick={this.onClickStopHandler}
                        >
                            {DISP_RESOURCE.STOP}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalSelectStopTime;
