import React, { createRef } from 'react';
import classNames from 'classnames';
import { Button, Collapse, NavItem, Popover, PopoverBody } from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';
import ModalGroup from './../modal/ModalGroup';
import { GROUP_MODAL_TYPE, MODAL } from '../../../common/constants';

// サイドバーグループメニューアイテム
class SideBarGroupMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
        };

        // モーダル参照
        this.groupModal = createRef();
    }

    popOverToggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen,
        });
    };
    
    showDeleteBtn = (e) => {
        e.stopPropagation();
        // 選択グループにする
        if (this.props.group.id !== this.props.currentGroup) {
            this.props.selectGroup(this.props.group.id);
        }
        this.props.selectDelGroup(this.props.group.id);
        this.props.modalOpen(MODAL.DELETE_GROUP);
        this.popOverToggle();
    };
    delGroup = (e) => {
        e.stopPropagation();
        this.props.deleteGroup();
    };
    /**
     * 対象のスピーカーをグループから抜けさせるボタン(×)を押下
     * @param {*} speakerId 対象のスピーカーID
     */
    ungroupSpeaker = (speakerId) => {
        this.props.setSelectedUngroupSpeakerId(speakerId);
        this.props.modalOpen(MODAL.UNGROUP_SPEAKER);
    };
    // 保存モーダル開き
    groupModalOpen = (e) => {
        e.stopPropagation();
        this.groupModal.current.init();
        this.popOverToggle();
    };

    render() {
        const {
            group,
            currentGroup,
            selectGroup,
            updateGroup,
            speakers,
            checkGroupSpeaker,
        } = this.props;
        const collapsed = group.id === currentGroup;
        const arrowImg = collapsed
            ? require('../../../img/down_arrow_d.svg').default
            : require('../../../img/right_arrow_d.svg').default;
        return (
            <div>
                <NavItem
                    className={classNames({ 'menu-open': collapsed })}
                    onClick={() => {
                        selectGroup(group.id);
                    }}
                >
                    <span title={group.name} className="sidebar_group_name">
                        <img alt="arrow" src={arrowImg} />
                        {group.name}
                    </span>
                    <span className="sidebar_group_btns">
                        <Button
                            color="none"
                            className={'sidebar_popover'}
                            id={`detail-${group.id}`}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <img
                                alt="edit"
                                src={
                                    require('../../../img/three_way_v.svg')
                                        .default
                                }
                            />
                            <ModalGroup
                                ref={this.groupModal}
                                group={group}
                                saveGroups={updateGroup}
                                type={GROUP_MODAL_TYPE.EDIT}
                            ></ModalGroup>
                        </Button>
                    </span>
                    <Popover
                        isOpen={this.state.popoverOpen}
                        toggle={this.popOverToggle}
                        target={`detail-${group.id}`}
                        trigger="legacy"
                    >
                        <PopoverBody>
                            <NavItem onClick={this.groupModalOpen}>
                                {DISP_RESOURCE.GROUP_EDIT}
                            </NavItem>
                            <NavItem onClick={this.showDeleteBtn}>
                                {DISP_RESOURCE.GROUP_DELETE}
                            </NavItem>
                        </PopoverBody>
                    </Popover>
                </NavItem>
                <Collapse
                    isOpen={collapsed}
                    navbar
                    className={classNames({ 'mb-1': collapsed })}
                >
                    {speakers.map((item, index) => (
                        <NavItem
                            title={item.name ?? '-'}
                            key={index}
                            className="items_group_speaker"
                        >
                            <span className="sidebar_group_speaker_name">{item.name ?? '-'}</span>
                            <span className="sidebar_group_speaker_btns">
                                <Button
                                    color="none"
                                    className={'sidebar_popover'}
                                    id={`detail-${group.id}-${item.id}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.ungroupSpeaker(item.id);
                                    }}
                                >
                                    <img
                                        alt="edit"
                                        src={
                                            require('../../../img/delete_base.svg')
                                                .default
                                        }
                                    />
                                </Button>
                            </span>
                        </NavItem>
                    ))}
                    <Button
                        className="add_group_speaker_btn"
                        color="success"
                        onClick={() => {
                            checkGroupSpeaker();
                        }}
                    >
                        追加
                    </Button>
                </Collapse>
            </div>
        );
    }
}

export default SideBarGroupMenu;
