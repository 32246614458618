import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DISP_RESOURCE from '../../../common/dispResource';

import * as Constants from '../../../common/constants';

import DropboxArea from '../area/DropboxArea';
import { DndProvider } from 'react-dnd';
import ModalChoice from './ModalChoice';
import ModalMessage from './ModalMessage';

/**
 * 新規フロアマップ読込モーダル
 * @param {object} props - コンポーネント呼び出し側から渡される値
 * @param {boolean} props.modal - モーダル表示有無
 * @param {function} props.setModal - モーダル表示有無設定処理
 * @param {boolean} props.savedFlag - フロア情報が保存済みかどうか
 * @param {string} props.floorName - フロア名
 * @param {function} props.saveFloor - フロア情報保存処理
 * @param {function} props.settingAreaInfo - フロア情報設定処理
 * @param {function} props.modalOpen - モーダル表示処理
 */
function ModalNewFloor(props) {
    const { modal, setModal } = props;

    /**
     * 画像ドロップボックスにドロップされた画像
     * @type {[string[], React.Dispatch<React.SetStateAction<string[]>>]}
     */
    const [droppedFiles, setDroppedFiles] = useState([]);

    /**
     * モーダル内で表示する画像
     * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
     */
    const [tempAreaImage, setTempAreaImage] = useState(null);

    /**
     * 操作選択モーダルを使えるように宣言
     */
    const modalChoiceInstance = useRef();

    /**
     * メッセージ確認モーダルを使えるように宣言
     */
    const modalMessageInstance = useRef();

    /**
     * モーダル閉じる
     */
    function close() {
        // モーダル内の画像表示リセット
        setDroppedFiles([]);
        setTempAreaImage(null);
        setModal(!modal);
    }

    /**
     * OKボタン押下時処理
     */
    function onClickOkHandler() {
        if (tempAreaImage !== null && !props.savedFlag) {
            // 画像ドロップ後かつ、フロアマップ未保存状態の場合、操作選択モーダル表示
            modalChoiceInstance.current.init();
        } else if (tempAreaImage !== null) {
            // 画像ドロップ後かつ、フロアマップ保存済み状態の場合
            const floorInfo = {
                floorName: '',
                floorId: null,
                areaImage: tempAreaImage,
                realWidth: Constants.REAL_WIDTH.DEFAULT,
                mode: Constants.MODE.SET_FLOOR,
                speakers: [],
                groups: [],
                currentSpeaker: null,
                currentGroup: null,
                currentDelGroup: null,
                savedFlag: false,
            };
            replace(floorInfo);
        }
    }

    /**
     * 操作選択モーダルで選んだ選択肢の操作を実行
     * @param {number} choiceNumber モーダルで選択したラジオボタンの値
     */
    function choiceAction(choiceNumber) {
        // フロア画像だけ設定された、フロア情報を作成
        const floorInfo = {
            floorName: '',
            floorId: null,
            areaImage: tempAreaImage,
            realWidth: Constants.REAL_WIDTH.DEFAULT,
            mode: Constants.MODE.SET_FLOOR,
            speakers: [],
            groups: [],
            currentSpeaker: null,
            currentGroup: null,
            currentDelGroup: null,
            savedFlag: false,
        };

        if (choiceNumber === '0' && props.floorName === '') {
            // 未保存のフロアマップを保存する かつ フロア名未設定時
            props.modalOpen(Constants.MODAL.SAVE_FLOOR, floorInfo);
            close();
        } else if (choiceNumber === '0') {
            // 未保存のフロアマップを保存する場合
            props.saveFloor();
            replace(floorInfo);
        } else {
            // 未保存のフロアマップを保存しない場合
            replace(floorInfo);
        }
    }

    /**
     * 新規フロアマップ差し替え処理
     * @param {floorInfo} - フロア情報
     */
    function replace(floorInfo) {
        props.settingAreaInfo(floorInfo);
        close();
    }

    /**
     * ドロップイベント
     * @param {object} item
     * @param {string[]} item.files - 画像ドロップボックスにドロップされた画像
     */
    const handleFileDrop = useCallback(
        (item) => {
            if (item.files === undefined) {
                return;
            }
            if (item) {
                const files = item.files;
                setDroppedFiles(files);
            }
        },
        [setDroppedFiles]
    );

    /**
     * 画像ドロップボックス
     * @param {string[]} files - 画像ドロップボックスにドロップされた画像
     */
    const MapDropDiv = ({ files }) => {
        // ドロップ前の状態 return
        if (files === undefined || files.length === 0) {
            // 画像ドロップボックス
            return (
                <DropboxArea
                    onDrop={handleFileDrop}
                    tempAreaImage={tempAreaImage}
                />
            );
        }

        // 以下、ドロップ後
        const file = files[0];

        // ファイル拡張子を確認
        let isImage = false;
        for (let idx in Constants.IMG_EXTN) {
            const extn = Constants.IMG_EXTN[idx];

            isImage = file.name.endsWith(extn);
            if (isImage) {
                break;
            }
        }

        // 設定ファイルで指定した拡張子に当てはまらない場合 エラーメッセージ表示
        if (!isImage) {
            modalMessageInstance.current.init();
            return (
                <DropboxArea
                    onDrop={handleFileDrop}
                    tempAreaImage={tempAreaImage}
                />
            );
        }

        // エリア画像セット
        var reader = new FileReader();

        reader.onload = (e) => {
            // 画像オブジェクトに取り込んだ画像を設定する
            var img = new Image();
            img.src = e.target.result;

            // 画像の読み込み完了後
            img.onload = function () {
                // キャンバスのサイズを設定する
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // 横サイズをオリジナルサイズとして、1280x720(4:3)の比率となるよう縦サイズを確保する
                // (はみ出した分はカットとなる)
                canvas.width = img.width;
                canvas.height = img.width * (720 / 1280);

                // キャンバスに画像を描画する
                ctx.drawImage(img, 0, 0);

                // グレースケール用のrgb値変換倍率を設定する
                var persent = 60; // 60%の場合
                var range = 255 * (persent / 100);
                var offset = 255 - range;

                // 画像の各ピクセルをグレースケールに変換する
                var pixels = ctx.getImageData(
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
                for (var y = 0; y < pixels.height; y++) {
                    for (var x = 0; x < pixels.width; x++) {
                        var i = y * 4 * pixels.width + x * 4;
                        var rgb = parseInt(
                            (pixels.data[i] +
                                pixels.data[i + 1] +
                                pixels.data[i + 2]) /
                                3,
                            10
                        );
                        rgb = parseInt((rgb * range) / 255 + offset, 10);
                        pixels.data[i] = rgb;
                        pixels.data[i + 1] = rgb;
                        pixels.data[i + 2] = rgb;
                    }
                }
                ctx.putImageData(
                    pixels,
                    0,
                    0,
                    0,
                    0,
                    pixels.width,
                    pixels.height
                );

                // グレースケール化した画像をbase64で出力
                const grayScaleImg = canvas.toDataURL();

                // モーダル内で表示する画像の設定
                setTempAreaImage(grayScaleImg);
                setDroppedFiles([]);
            };
        };
        reader.readAsDataURL(file);

        return (
            <DropboxArea
                onDrop={handleFileDrop}
                tempAreaImage={tempAreaImage}
            />
        );
    };

    return (
        <>
            <Modal
                className="modal_new_floor"
                isOpen={modal}
                centered
                backdrop="static"
            >
                {/* モーダルヘッダー */}
                <ModalHeader toggle={close}></ModalHeader>
                {/* モーダルボディ */}
                <ModalBody className="m-auto">
                    <DndProvider>
                        {/* 画像ドロップボックス */}
                        <MapDropDiv files={droppedFiles} />
                    </DndProvider>
                </ModalBody>
                {/* モーダルフッター */}
                <ModalFooter className="without_cancel">
                    <Button
                        className="mx-auto w-75"
                        color="success"
                        onClick={onClickOkHandler}
                    >
                        {DISP_RESOURCE.NEW_FLOOR_BUTTON}
                    </Button>
                </ModalFooter>
            </Modal>

            {/* 操作選択モーダル */}
            <ModalChoice
                ref={modalChoiceInstance}
                title={DISP_RESOURCE.CHOICE_SAVE_FLOOR_TITLE}
                content={DISP_RESOURCE.CHOICE_SAVE_FLOOR_CONTENT}
                firstOpt={DISP_RESOURCE.CHOICE_SAVE_FLOOR_FIRST_OPT_NEW}
                secondOpt={DISP_RESOURCE.CHOICE_SAVE_FLOOR_SECOND_OPT_NEW}
                okProc={choiceAction}
            ></ModalChoice>

            {/* メッセージ確認モーダル */}
            <ModalMessage
                ref={modalMessageInstance}
                title={''}
                content={DISP_RESOURCE.AREA_DROP_NOT_ACCEPT}
                okString={DISP_RESOURCE.OK}
                okProc={() => {}}
                isCancel={false}
            ></ModalMessage>
        </>
    );
}

export default ModalNewFloor;
