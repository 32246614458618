import axios from 'axios';
import { API_DOMAIN, API_URL, REQUEST_QUERY } from './apiContants';
import { createHeaders } from '../common/utils.js';

const domain = API_DOMAIN;

/**
 * スピーカー情報を取得するAPIを実行する
 * @param {number} speakerId スピーカーID
 * @returns API実行結果
 */
export async function getSpeakerInfo(speakerId) {
    // API呼び出し
    const response = await axios.get(
        domain +
            API_URL.GET_SPEAKER +
            REQUEST_QUERY.GET_SPEAKER.format(speakerId),
        { headers: createHeaders() }
    );

    return response.data;
}

/**
 * 対象のスピーカーに対し音源再生を実施するAPIを実行する
 * @param {number} speakerId スピーカーID
 * @param {number} soundSourceNo 音源番号
 * @param {string} userType 実行者種別
 * @returns API実行結果
 */
export async function postPlaySpeaker(speakerId, soundSourceNo, userType) {
    // API呼び出し
    const response = await axios
        .post(
            domain + API_URL.PLAY_SPEAKER,
            {
                speakerId: speakerId,
                soundSourceNo: soundSourceNo,
                userType: userType,
            },
            { headers: createHeaders() }
        )
        .catch((err) => {
            return err.response;
        });

    return response;
}

/**
 * スピーカーに対し一時停止を実施するAPIを実行する
 * @param {number} speakerId スピーカーID
 * @param {number} stopTime 停止時間
 * @param {string} userType 実行者種別
 * @returns API実行結果
 */
export async function postMuteSpeaker(speakerId, stopTime, userType) {
    // API呼び出し
    const response = await axios
        .post(
            domain + API_URL.MUTE_SPEAKER,
            { speakerId: speakerId, stopTime: stopTime, userType: userType },
            { headers: createHeaders() }
        )
        .catch((err) => {
            return err.response;
        });

    return response;
}

/**
 * スピーカーに対し音量を変更するAPIを実行する
 * @param {number} speakerId スピーカーID
 * @param {number} volume 設定音量
 * @param {string} userType 実行者種別
 * @returns API実行結果
 */
export async function postChangeVolumeSpeaker(speakerId, volume, userType) {
    // API呼び出し
    const response = await axios
        .post(
            domain + API_URL.CHANGE_SPEAKER_VOLUME,
            { speakerId: speakerId, volume: volume, userType: userType },
            { headers: createHeaders() }
        )
        .catch((err) => {
            return err.response;
        });

    return response;
}

/**
 * スピーカー音源を取得するAPIを実行する
 * @param {number} speakerId スピーカーID
 * @param {string} userType 実行者種別
 * @returns API実行結果
 */
export async function getSpeakerSoundSources(speakerId, userType) {
    // API呼び出し
    const response = await axios
        .get(
            domain +
                API_URL.GET_SPEAKER_SOUND_SOURCE +
                REQUEST_QUERY.GET_SPEAKER_SOUND_SOURCE.format(
                    speakerId,
                    userType
                ),
            { headers: createHeaders() }
        )
        .catch((err) => {
            return err.response;
        });

    return response;
}

/**
 * アンケート情報を呼び出すAPIを実行する
 * @param {number} baseId 拠点ID
 * @param {number} speakerId スピーカーID
 * @returns API実行結果
 */
export async function getSurveyInfo(baseId, speakerId) {
    // API呼び出し
    const response = await axios
        .get(
            domain +
                API_URL.GET_SURVEY +
                REQUEST_QUERY.GET_SURVEY.format(baseId, speakerId),
            { headers: createHeaders() }
        )
        .catch((err) => {
            return err.response;
        });

    return response;
}
