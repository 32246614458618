import React from 'react';
import { Spinner } from 'reactstrap';

/**
 * ローディング画面
 */
class Loading extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * @type {boolean} ローディング画面表示有無
             */
            active: false,
        };
    }

    /**
     * ローディング画面表示有無の設定
     * @param {boolean} flag -ローディング画面表示有無
     */
    setActive = (flag) => {
        this.setState({ active: flag });
    };

    /**
     * ローディング表示
     */
    show = () => {
        this.setActive(true);
    };

    /**
     * ローディング非表示
     */
    close = () => {
        this.setActive(false);
    };

    /**
     * 画面描画
     * @returns ローディング画面
     */
    render() {
        return (
            <>
                {this.state.active && (
                    <div>
                        <div className="loading-backdrop" />
                        <div className="loading">
                            <Spinner color="primary" />
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default Loading;
