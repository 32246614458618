import axios from 'axios';
import { API_DOMAIN, API_URL, REQUEST_QUERY } from './apiContants';

// リクエストに付加するヘッダーの定義
const headers = {
    'Content-Type': 'application/json',
};

/**
 * フロア一覧情報取得API
 * @param {string} userId ユーザーID
 * @returns レスポンスデータ
 */
export async function getFloorList(userId) {
    // API呼び出し
    const response = await axios
        .get(
            API_DOMAIN +
                API_URL.GET_FLOOR_LIST +
                REQUEST_QUERY.GET_FLOOR_LIST.format(userId),
            { headers: headers }
        )
        .catch((e) => {
            throw e;
        });
    return response.data;
}

/**
 * フロアマップ情報取得
 * @param {string} userId ユーザーID
 * @param {number} floorId
 * @returns レスポンスデータ
 */
export async function loadFloor(userId, floorId) {
    // API呼び出し
    const response = await axios
        .get(
            API_DOMAIN +
                API_URL.LOAD_FLOOR +
                REQUEST_QUERY.LOAD_FLOOR.format(userId, floorId)
        )
        .catch((e) => {
            // エラー（ステータスコードが200系以外）の場合
            throw e;
        });
    return response.data;
}

/**
 * フロアマップ情報保存
 * @param {object} data  リクエスト用Json
 * @returns レスポンスデータ
 */
export async function saveFloor(data) {
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.SAVE_FLOOR, data)
        .catch((e) => {
            // エラー（ステータスコードが200系以外）の場合
            throw e;
        });
    return response.data;
}

/**
 * フロアマップ情報削除
 * @param {string} userId ユーザーID
 * @param {number} floorId
 * @returns レスポンスデータ
 */
export async function deleteFloor(userId, floorId) {
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.DELETE_FLOOR, {
            userId: userId,
            floorId: floorId,
        })
        .catch((e) => {
            // エラー（ステータスコードが200系以外）の場合
            throw e;
        });

    return response.data;
}

/**
 * 拠点情報変更チェックAPI
 * @param {string} userId ユーザーID
 * @returns レスポンスデータ
 */
export async function confirmChangedBaseInfo(userId) {
    // API呼び出し
    const response = await axios
        .get(
            API_DOMAIN +
                API_URL.CONFIRM_CHANGED_BASE_INFO +
                REQUEST_QUERY.CONFIRM_CHANGED_BASE_INFO.format(userId)
        )
        .catch((e) => {
            throw e;
        });
    return response.data;
}

/**
 * 拠点情報CSV反映API
 * @param {object} data  リクエスト用Json
 * @returns レスポンスデータ
 */
export async function reflectBaseCsv(data) {
    // API呼び出し
    const response = await axios
        .post(API_DOMAIN + API_URL.REFLECT_BASE_CSV, data)
        .catch((e) => {
            throw e;
        });
    return response.data;
}

/**
 * 編集・設定モードの切り替えAPI
 * @param {object} data  リクエスト用Json
 * @returns レスポンスデータ
 */
export async function changeFloorMode(data) {
    // API呼び出し
    const response = await axios.post(
        API_DOMAIN + API_URL.CHANGE_FLOOR_MODE,
        data
    );
    return response.data;
}
