import React from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    NavItem,
    Label,
    ModalFooter,
    FormFeedback,
} from 'reactstrap';
import { FORM_MAX_LENGTH, GROUP_MODAL_TYPE } from '../../../common/constants';
import DISP_RESOURCE from '../../../common/dispResource';

/**
 * グループ追加・編集モーダル
 */
class ModalGroup extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * モーダル表示有無
             * @type {boolean}
             */
            modal: false,
            /**
             * モーダル内のグループ名
             * @type {string}
             */
            groupName: '',
            /**
             * グループ名入力フォームのエラーメッセージ
             * @type {string}
             */
            errorGroupName: '',
        };
    }

    // state更新
    /**
     * グループ名設定
     * @param {string} groupName - グループ名
     */
    setGroupName = (groupName) => {
        this.setState({ groupName: groupName });
    };
    /**
     * モーダル表示設定
     * @param {boolean} flag - モーダル表示有無
     */
    setModal = (flag) => {
        this.setState({ modal: flag });
    };

    /**
     * モーダル初期化
     */
    init = () => {
        if (this.props.type === GROUP_MODAL_TYPE.EDIT) {
            // グループ名編集の場合グループ名設定
            this.setGroupName(this.props.group.name);
            this.setState({ errorGroupName: '' });
        } else {
            this.setGroupName('');
            this.setState({ errorGroupName: '' });
        }
        this.setModal(true);
    };

    /**
     * モーダル閉じ
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * モーダルの変更反映
     */
    save = () => {
        if (!this.state.groupName || this.state.groupName.trim() === '') {
            // 空文字や半角スペースのみの場合エラーメッセージを設定
            this.setState({ errorGroupName: DISP_RESOURCE.INPUT_REQUIRED });
            return;
        } else {
            // グループ名が設定されている場合エラーメッセージを消す
            this.setState({ errorGroupName: '' });
        }
        let data = { name: this.state.groupName };
        if (this.props.type === GROUP_MODAL_TYPE.EDIT) {
            data = { id: this.props.group.id, ...data };
        }
        this.props.saveGroups(data);
        this.close();
    };

    render() {
        const { type } = this.props;
        return (
            <>
                {type === GROUP_MODAL_TYPE.ADD && (
                    <NavItem className="sidebar_group_header">
                        <span>{DISP_RESOURCE.GROUP_LIST}</span>
                        <img
                            alt="add"
                            src={require('../../../img/round_plus.svg').default}
                            onClick={this.init}
                        />
                    </NavItem>
                )}
                <Modal
                    className="modal_add_group"
                    isOpen={this.state.modal}
                    centered
                >
                    {type === GROUP_MODAL_TYPE.ADD && (
                        <ModalHeader toggle={this.close}>
                            {DISP_RESOURCE.GROUP_ADD}
                        </ModalHeader>
                    )}
                    {type === GROUP_MODAL_TYPE.EDIT && (
                        <ModalHeader toggle={this.close}>
                            {DISP_RESOURCE.EDIT_GROUP_TITLE}
                        </ModalHeader>
                    )}
                    <ModalBody>
                        <Label for="groupName" sm={2}>
                            {DISP_RESOURCE.GROUP_NAME}
                        </Label>
                        <div className="form_with_valid col-sm-10">
                            <Input
                                id="groupName"
                                type="text"
                                value={this.state.groupName}
                                maxLength={FORM_MAX_LENGTH.GROUP_NAME}
                                onChange={(evt) =>
                                    this.setGroupName(evt.target.value)
                                }
                                invalid={this.state.errorGroupName !== ''}
                            />
                            <FormFeedback>
                                {this.state.errorGroupName}
                            </FormFeedback>
                        </div>
                    </ModalBody>
                    <ModalFooter className="with_cancel">
                        <Button outline color="success" onClick={this.close}>
                            {DISP_RESOURCE.CANCEL}
                        </Button>
                        <Button color="success" onClick={this.save}>
                            {DISP_RESOURCE.OK}
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ModalGroup;
