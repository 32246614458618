import ToaLogo from '../img/logo.svg';

import React, { useEffect, useState, useRef } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import parser from 'html-react-parser';
import axios from 'axios';

import DISP_RESOURCE from '../common/dispResource';
import { getUser } from '../api/ossLoginApi';
import ModalMessage from './area_setting/modal/ModalMessage';
import { SESSION_STORAGE_KEY } from '../common/constants';
import {
    API_DOMAIN,
    API_URL,
    RESPONSE_STATUS,
} from '../api/apiContants';

/**
 * ログイン画面
 * ユーザーIDおよびパスワードを入力して外部APIによる認証を行う。
 * @returns {html} - 画面UI
 */
function Login() {
    /**
     * ユーザーID
     * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
     */
    const [userId, setUserId] = useState('');
    /**
     * パスワード
     * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
     */
    const [password, setPassword] = useState('');
    /**
     * エラーメッセージ
     * @type {[string, React.Dispatch<React.SetStateAction<string>>]}
     */
    const [errorMessage, setErrorMessage] = useState(null);
    /**
     * モーダル表示用メッセージ
     */
    const [contractEndCautionMessage, setContractEndCautionMessage] =
        useState('');
    /**
     * クライアントバージョン
     */
    const [clientVersion, setClientVersion] = useState('');
    /**
     * サーバーバージョン
     */
    const [serverVersion, setServerVersion] = useState('');
    /**
     * モーダル表示用
     */
    const modalMessageInstance = useRef();
    /**
     * url遷移用のインスタンス
     * @type {NavigateFunction}
     */
    const navigate = useNavigate();

    useEffect(() =>{
        // バージョン設定
        setClientVersion(process.env.REACT_APP_VERSION);
        setServerVersion('-.-.-');

        // サーバーバージョン取得
        axios.get(
            API_DOMAIN + API_URL.GET_VERSION
        ).then(response => {
            if (response.status === RESPONSE_STATUS.OK) {
                setServerVersion(response.data.version);
            } 
        });
    }, []);

    /**
     * フォーム上でキーを押下したときのイベント
     * @param {object} event event
     */
    function keyPress(event) {
        // エンターキーの場合、認証処理を実施する
        if (event.keyCode === 13) {
            executeLogin();
        }
    }

    /**
     * ログイン実行。認証のAPIを呼び出す
     */
    async function executeLogin() {
        setError(null);

        // ユーザー情報取得API呼び出し
        const result = await getUser(userId, password);

        if (
            !result ||
            !result.userId ||
            !result.baseId ||
            !result.contractEndDt
        ) {
            // サーバー側からユーザー情報が取得できなかった場合ログイン失敗のエラーを表示
            setError(DISP_RESOURCE.LOGIN_ERROR_FAIL_AUTH);
            return;
        }

        // // 日付比較用
        const nowDate = new Date();
        nowDate.setHours(0, 0, 0, 0);
        const afterOneWeek = new Date();
        afterOneWeek.setHours(0, 0, 0, 0);
        afterOneWeek.setDate(afterOneWeek.getDate() + 7);
        const contractEndDate = new Date(result.contractEndDt);

        if (nowDate > contractEndDate) {
            // 契約期限切れの場合、期限切れのエラーを表示
            setError(DISP_RESOURCE.LOGIN_ERROR_CONTRACT_LIMIT);
            return;
        }

        if (nowDate <= contractEndDate && contractEndDate <= afterOneWeek) {
            // 契約期限切れ1週間前の場合、その旨を通知するモーダルを表示
            sessionStorage.setItem(SESSION_STORAGE_KEY.USER_ID, result.userId);
            sessionStorage.setItem(SESSION_STORAGE_KEY.BASE_ID, result.baseId);

            // モーダルに表示するメッセージの作成
            const year = contractEndDate.getFullYear();
            const month = contractEndDate.getMonth() + 1;
            const day = contractEndDate.getDate();
            setContractEndCautionMessage(
                DISP_RESOURCE.CONTRACT_LIMIT_CONTENT.format(year, month, day)
            );

            modalMessageInstance.current.init();
        } else {
            // 契約期限が1週間以上残っている場合、そのままフロア設定画面に遷移
            sessionStorage.setItem(SESSION_STORAGE_KEY.USER_ID, result.userId);
            sessionStorage.setItem(SESSION_STORAGE_KEY.BASE_ID, result.baseId);
            navigate('/area');
        }
    }

    /**
     *  エラーメッセージと表示有無の設定
     * @param {String} message - エラーメッセージ
     */
    function setError(message) {
        if (message !== null) {
            // エラーメッセージ設定(メッセージのhtml形式をReact要素に変換して設定)
            setErrorMessage(parser(message));
        } else {
            setErrorMessage(null);
        }
    }

    return (
        <div className="login mx-auto">
            {/* ロゴ、システム名 */}
            <img src={ToaLogo} className="App-logo p-4" alt="logo" />

            {/* ユーザーID、パスワード入力フォーム */}
            <Form className="mx-auto">
                <FormGroup>
                    <Input
                        id="userId"
                        name="userId"
                        placeholder={DISP_RESOURCE.LOGIN_FORM_USER_ID}
                        type="text"
                        value={userId}
                        onChange={(event) => {
                            setUserId(event.target.value);
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        id="password"
                        name="password"
                        placeholder={DISP_RESOURCE.LOGIN_FORM_PASSWORD}
                        type="password"
                        value={password}
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        onKeyUp={keyPress}
                    />
                </FormGroup>
            </Form>

            {/* エラーメッセージ表示領域 */}
            <div
                className="text-start errorMessage"
                visible={errorMessage !== null ? '' : 'hidden'}
            >
                {errorMessage}
            </div>

            {/* ログインボタン */}
            <Button
                type="button"
                className="w-100"
                color="success"
                onClick={executeLogin}
            >
                {DISP_RESOURCE.LOGIN_BUTTON}
            </Button>

            <div className='version-text'>
                <div>{DISP_RESOURCE.CLIENT_VERSION_TEXT}{clientVersion}</div>
                <div>{DISP_RESOURCE.SERVER_VERSION_TEXT}{serverVersion}</div>
            </div>

            {/* メッセージ確認MODAL (契約期限切れ通知用) */}
            <ModalMessage
                ref={modalMessageInstance}
                title={DISP_RESOURCE.CONTRACT_LIMIT_TITLE}
                content={contractEndCautionMessage}
                okString={DISP_RESOURCE.OK}
                okProc={() => {
                    navigate('/area');
                }}
                cancelProc={() => {
                    navigate('/area');
                }}
                isCancel={false}
                isExclamation={true}
            ></ModalMessage>
        </div>
    );
}

export default Login;
