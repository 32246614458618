import React, { createRef } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    ModalFooter,
    Label,
    FormFeedback,
} from 'reactstrap';
import classNames from 'classnames';

import DISP_RESOURCE from '../../../common/dispResource';
import {
    MODAL,
    MODE,
    REAL_WIDTH,
    FORM_MAX_LENGTH,
} from '../../../common/constants';
import ModalMessage from './ModalMessage';

/**
 * フロア保存モーダル
 */
class ModalSaveFloor extends React.Component {
    /**
     * @constructor
     * @param {object} props - コンポーネント呼び出し側から渡される値
     */
    constructor(props) {
        super(props);
        this.state = {
            /**
             * モーダル表示有無（フロア保存モーダル）
             * @type {boolean}
             */
            modal: false,
            /**
             * フロア名（フロア保存モーダル内の）
             * @type {string}
             */
            floorName: '',
            /**
             * メッセージ表示モーダルのボディ部分に設定する文字列
             * @type {string}
             */
            message: '',
            /**
             * 画面保存後に差し替えるフロア情報
             * 新規フロアマップ読込、既存フロアマップ読込モーダルから呼び出された場合のみ使用
             * @type {object}
             */
            nextFloorData: null,
            /**
             * 入力フォームのエラーメッセージ
             * @type {String}
             */
            errorFloorName: null,
        };

        this.saved = false;

        // モーダル定義
        this[MODAL.SAVE_FLOOR_CONFIRM] = createRef();
        this[MODAL.SAVE_FLOOR_MSG] = createRef();
        this[MODAL.LOAD_DELETE_FLOOR_MSG] = createRef();
    }

    /**
     * state更新
     */
    setFloorName = (floorName) => {
        this.setState({ floorName: floorName });
    };
    setModal = (flag) => {
        this.setState({ modal: flag });
    };
    setMessage = (msg) => {
        this.setState({ message: msg });
    };
    setNextFloorData = (floorData) => {
        this.setState({ nextFloorData: floorData });
    };
    setErrorFloorMessage = (msg) => {
        this.setState({ errorFloorName: msg });
    };

    /**
     * モーダル初期化
     * @param {string | null} floorData - 画面保存後に差し替える画像
     */
    init = (floorData = null) => {
        // 入力フォームのエラーメッセージを初期化
        this.setErrorFloorMessage(null);

        this.saved = false;

        if (floorData) {
            // 差し替え画像がある場合は設定
            this.setNextFloorData(floorData);
        } else {
            this.setNextFloorData(null);
        }

        this.setFloorName(this.props.floorName);

        if (this.props.areaImage === null) {
            // フロア画像がない場合エラーメッセージ表示
            this.displayMessage(DISP_RESOURCE.SAVE_FLOOR_ERR_NO_AREA_IMAGE);
        } else if (
            this.props.realWidth > REAL_WIDTH.MAX ||
            this.props.realWidth < REAL_WIDTH.MIN
        ) {
            // マップの横方向の実サイズが設定範囲外の場合エラーメッセージ
            this.displayMessage(
                DISP_RESOURCE.SAVE_FLOOR_ERR_WIDTH_RANGE.format(
                    REAL_WIDTH.MIN,
                    REAL_WIDTH.MAX
                )
            );
        } else if (this.props.floorName === '') {
            // フロア名が設定されていない場合、フロア名設定モーダルを表示
            this.setModal(true);
        } else {
            // フロア画像とエリア画像が既に設定されてある場合、上書き保存を行うか確認する
            this.setMessage(DISP_RESOURCE.SAVE_FLOOR_CONFIRM_OVERWRITE);
            this.cmpModalOpen(MODAL.SAVE_FLOOR_CONFIRM);
        }
    };

    /**
     * フロア名設定モーダル閉じる
     */
    close = () => {
        this.setModal(false);
    };

    /**
     * 保存処理
     */
    save = async () => {
        // 多重保存防止処理
        if (this.saved === true) return;

        if (!this.state.floorName || this.state.floorName === '') {
            // フロア名未設定の場合フロア名入力フォームにエラー表示
            this.setErrorFloorMessage(DISP_RESOURCE.INPUT_REQUIRED);
            return;
        }

        this.saved = true;
        this.setErrorFloorMessage(null);

        try {
            // フロア情報保存
            const saveFloorResponse = await this.props.saveFloor(
                this.state.floorName
            );

            this.setModal(false);

            if (this.state.nextFloorData) {
                // 差し替えデータがある場合は差し替えを行う
                this.props.settingAreaInfo(this.state.nextFloorData);

                // 読み込み成功ダイアログ表示 (保存成功ダイアログと分けて表示)
                this.displayLoadFloorMessage();
                this.saved = false;
            } else {
                // フロア保存後、保存したフロア情報を読み込む(拠点スピーカー情報含む)
                await this.props.reloadFloor(
                    MODE.SET_SPEAKERS,
                    false,
                    saveFloorResponse.floorId
                );
            }
        } catch (e) {
            // エラー発生時、保存失敗ダイアログ表示
            this.displayMessage(DISP_RESOURCE.SAVE_FAILED);
            this.saved = false;
            return;
        }
        // 保存成功ダイアログ表示
        this.displayMessage(DISP_RESOURCE.SAVE_SUCCESS);
        this.saved = false;
    };

    /**
     * 上書き保存処理
     */
    overwriteSave = async () => {
        try {
            await this.props.saveFloor();
            await this.props.reloadFloor(this.props.mode, false);
        } catch (e) {
            // エラー発生時、保存失敗ダイアログ表示
            this.displayMessage(DISP_RESOURCE.SAVE_FAILED);
            return;
        }
        // 保存成功ダイアログ表示
        this.displayMessage(DISP_RESOURCE.SAVE_SUCCESS);
    };

    /**
     * メッセージ設定とメッセージモーダル表示
     */
    displayMessage = (message) => {
        this.setMessage(message);
        this.cmpModalOpen(MODAL.SAVE_FLOOR_MSG);
    };

    displayLoadFloorMessage = () => {
        this.cmpModalOpen(MODAL.LOAD_DELETE_FLOOR_MSG);
    };

    /**
     * このコンポーネントから呼び出すモーダルの表示処理
     * @param {string} modalName - モーダル名
     * @param {[string, number] | null} param - パラメータ
     */
    cmpModalOpen = (modalName) => {
        this[modalName].current.init();
    };

    render() {
        return (
            <>
                {/* 保存ボタン押下 */}
                <Button
                    className="save_floor_btn"
                    color="primary"
                    onClick={() => {
                        this.init();
                    }}
                >
                    {DISP_RESOURCE.SAVE}
                </Button>
                {/* フロア名設定モーダル */}
                <Modal
                    className="modal_save_floor"
                    isOpen={this.state.modal}
                    toggle={this.close}
                    centered
                    backdrop="static"
                >
                    <ModalHeader toggle={this.close}></ModalHeader>
                    <ModalBody>
                        <Label for="floorName">
                            {DISP_RESOURCE.SAVE_FLOOR_NAME}
                        </Label>
                        <div className="form_with_valid">
                            <Input
                                id="floorName"
                                type="text"
                                value={this.state.floorName}
                                onChange={(evt) =>
                                    this.setFloorName(evt.target.value)
                                }
                                invalid={this.state.errorFloorName !== null}
                                maxLength={FORM_MAX_LENGTH.FLOOR_NAME}
                            ></Input>
                            <FormFeedback
                                className={classNames(
                                    'form_message',
                                    this.state.errorFloorName !== null
                                        ? 'text-danger'
                                        : 'invisible'
                                )}
                            >
                                {this.state.errorFloorName ?? ''}
                            </FormFeedback>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="success"
                            className="w-100"
                            onClick={this.save}
                        >
                            {DISP_RESOURCE.SAVE}
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* 確認モーダル(上書き保存確認用) */}
                <ModalMessage
                    ref={this[MODAL.SAVE_FLOOR_CONFIRM]}
                    content={this.state.message}
                    okString={DISP_RESOURCE.OK}
                    okProc={this.overwriteSave}
                    isCancel={true}
                ></ModalMessage>
                {/* メッセージモーダル */}
                <ModalMessage
                    ref={this[MODAL.SAVE_FLOOR_MSG]}
                    content={this.state.message}
                    okString={DISP_RESOURCE.OK}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
                <ModalMessage
                    ref={this[MODAL.LOAD_DELETE_FLOOR_MSG]}
                    content={DISP_RESOURCE.LOAD_SUCCESS}
                    okString={DISP_RESOURCE.OK}
                    okProc={() => {}}
                    isCancel={false}
                ></ModalMessage>
            </>
        );
    }
}

export default ModalSaveFloor;
