import React from 'react';
import classNames from 'classnames';
import { Collapse, Input, NavItem } from 'reactstrap';

class SideBarScheduler extends React.Component {
    /**
     * スケジューラーサイドバー
     * @param {object}   props                       - コンポーネント呼び出し側から渡される値
     * @param {object}   props.group                 - グループ
     * @param {object[]} props.speakers              - グループ所属スピーカー情報
     * @param {number[]} props.schedulTargets        - スケジュール対象スピーカ
     * @param {function} props.putScheduleTargets    - スケジュール対象追加処理
     * @param {function} props.removeScheduleTargets - スケジュール対象削除処理
     */
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }

    /**
     * グループの全所属スピーカーのチェック済みを確認
     */
    checkedGroup = () => {
        const { speakers, schedulTargets } = this.props;
        if (speakers.length === 0) return false;

        let checked = true;
        for (let i = 0; i < speakers.length; i++) {
            if (schedulTargets.indexOf(speakers[i].id) < 0) {
                checked = false;
                break;
            }
        }
        return checked;
    };

    /**
     * スケジュールスピーカーチェック（選択）変更
     * @param {number} speakerId
     */
    changeSchedulSpeaker = (speakerId) => {
        const { schedulTargets, putScheduleTargets, removeScheduleTargets } =
            this.props;

        if (schedulTargets.includes(speakerId)) {
            // 選択済みなら選択解除
            removeScheduleTargets([speakerId]);
        } else {
            putScheduleTargets([speakerId]);
        }
    };

    /**
     * スケジュールスピーカーグループチェック（選択）変更
     */
    changeSchedulGroup = () => {
        const {
            speakers,
            schedulTargets,
            putScheduleTargets,
            removeScheduleTargets,
        } = this.props;

        let ids = [];
        if (this.checkedGroup()) {
            // グループ選択済みならグループ選択解除
            ids = speakers.map((speaker) => {
                return speaker.id;
            }, ids);

            removeScheduleTargets(ids);
        } else {
            for (let speaker of speakers) {
                const idx = schedulTargets.indexOf(speaker.id);
                if (idx < 0) {
                    ids.push(speaker.id);
                }
            }

            putScheduleTargets(ids);
        }
    };

    render() {
        const { group, speakers, schedulTargets } = this.props;

        const arrowImg = this.state.collapsed
            ? require('../../../img/down_arrow_d.svg').default
            : require('../../../img/right_arrow_d.svg').default;
        return (
            <div>
                <NavItem
                    className={classNames({
                        'menu-open': this.state.collapsed,
                    })}
                    onClick={() => {
                        this.setState({ collapsed: !this.state.collapsed });
                    }}
                >
                    <span className="group_label">
                        <img alt="arrow" src={arrowImg} />
                        <span title={group.name}>{group.name}</span>
                    </span>
                    <span className="sidebar_scheduler_btns">
                        <Input
                            type="checkbox"
                            checked={this.checkedGroup()}
                            onChange={() => {
                                this.changeSchedulGroup();
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                    </span>
                </NavItem>
                <Collapse
                    isOpen={this.state.collapsed}
                    navbar
                    className={classNames({ 'mb-1': this.state.collapsed })}
                >
                    {speakers.map((item, index) => (
                        <NavItem
                            key={index}
                            className="items_scheduler_speaker"
                        >
                            <span
                                title={item.name}
                                className="group_speaker_label"
                            >
                                {item.name}
                            </span>
                            <span className="sidebar_scheduler_btns">
                                <Input
                                    type="checkbox"
                                    checked={
                                        schedulTargets.indexOf(item.id) >= 0
                                    }
                                    onChange={() => {
                                        this.changeSchedulSpeaker(item.id);
                                    }}
                                />
                            </span>
                        </NavItem>
                    ))}
                </Collapse>
            </div>
        );
    }
}

export default SideBarScheduler;
